import React from 'react';
import {View, Text} from 'react-native';
import { Ionicons, Entypo } from '@expo/vector-icons';
import { FixedTouchable, TopArrow, isWeb } from './basics';
import { leaveConversationAsync, global_currentUser, ejectFromMeetingAsync, unFollowConversationAsync } from '../data/data';
import { signOut } from '../data/fbutil';
import { PopupMenu } from './shim';

class WithHover extends React.Component {
  state = {}
  render() {
    const {content, style} = this.props;
    const {hover} = this.state;
    return (
      <View style={style}
        onMouseOver={()=>this.setState({hover:true})}
        onMouseLeave={()=>this.setState({hover:false})}
      >
        {content(hover)}
      </View>
    )
  }
}

function MenuItem({label, onPress}) {
  return (
    <WithHover style={{alignSelf: 'stretch'}} content={hover => 
      <FixedTouchable part={label} onPress={onPress}>
        <View style={{alignSelf: 'stretch', padding: 8, backgroundColor: hover ? '#444' : 'black'}}>
          <Text numberOfLines={1} style={{color: 'white'}}>{label}</Text>
        </View>      
      </FixedTouchable>
    } />
  )
}

export class UserPopupMenu extends React.Component {
  state = {}

  async onSelect(id) {
    const {meeting, user, onGotoUser, navigation} = this.props;
    switch(id) {
      case 'nameroom': 
        console.log('nameRoom', meeting, user);
        if (isWeb) {
          console.log('web');
          return await onGotoUser({user, mode: 'nameroom'});
        } else {
          return await navigation.push('nameroom', {meeting, user});
        }
      case 'nameuser': 
        return await navigation.push('nameuser', {meeting, user});
      case 'signout':
        await navigation.popToTop();
        return await signOut();
      case 'eject' : 
        await navigation.goBack();
        return await ejectFromMeetingAsync({meeting, user});
      case 'report' :
        if (isWeb) {
          return await onGotoUser({user, mode: 'report'});
        } else {
          return await navigation.push('report', {meeting, user});
        }
      default: 
        return;
    }
  }

  render() {
    const {size = 20, meeting, iAmHost, user, conInfo} = this.props;

    if (!conInfo && !user == global_currentUser && !iAmHost) {
      return null;
    }

    var items = [];
    // if (user == global_currentUser) {
    //   items.push({id: 'nameroom', label: 'Set Room Name'});
    // }
    // if (user == global_currentUser) {
    //   items.push({id: 'nameme', label: 'Set My Name'});
    // }
    if (user == global_currentUser) {
      items.push({id: 'signout', label: 'Sign Out'});
    }
    if (!conInfo && user != global_currentUser && iAmHost) {
      items.push({id: 'eject', label: 'Eject'});
    }
    if (user != global_currentUser) {
      items.push({id: 'report', label: 'Report Abuse'});
    }

    return (
      <PopupMenu items={items} hasArrow inverted part='actions'
          textStyle={{marginVertical: 8}}
          popStyle={{top: 28, right: 14}} onSelect={id => this.onSelect(id)}>
        <Entypo name='dots-three-vertical' size={size} color='#666' style={{paddingHorizontal: 8}} />        
      </PopupMenu>
    )
  }
}

