import React from 'react';
import {ScrollView, Text, TextInput, Clipboard, View, StyleSheet, TouchableOpacity, FlatList} from 'react-native';
import {Audio} from 'expo-av';
import {findDOMNode} from 'react-dom';
import WebDatePicker from 'react-datepicker';
// import EXIF from 'exif-js';
import 'react-datepicker/dist/react-datepicker.css';
import AddToCalendar from 'react-add-to-calendar-recurring';
import './addtocalendar.css';
import { Entypo, Feather } from '@expo/vector-icons';
import { FixedTouchable, HoverView, TopArrow, HeaderSpaceView, hourMillis } from './basics';
import { Catcher } from './catcher';
import { getFocusDate } from './dates';
export const DatePicker = WebDatePicker;
const fixOrientation = require('fix-orientation');

var webNavigation = null;
export function setWebNavigator(navigation) {
  webNavigation = navigation;
}

/* eslint-disable react/display-name */
export function withNav(Component) { 
  return props => <Component {...props} navigation={webNavigation} />   
}

export function vibrate() {};
export function showAlert() {};

export function setBadgeCount(count) {}

export function navigateToGathering() {}
export function navigateToChat() {}

export function requestFullScreen() {
  
}

export function setCurrentUrl(url) {
  window.location.href = url;
}

export function getCurrentDomain() {
  const host = window.location.host;
  return 'https://chillchat.org'
  // if (host == 'localhost:19006') {
  //   return 'https://localhost:5000';
  // } else if (host == '192.168.86.31') {
  //   return 'https://chillchat.org';
  // }
  return window.location.origin;
}

export function ModalMenu() {return null}

export const fakeWebNavigation = {
  goBack: () => {},
  push: () => {},
  popToTop: () => {}
}

export function getIsMobileWeb() {
  return (/iphone|ipod|ipad|android/.test(navigator.userAgent.toLowerCase()));
}

export function getPathParts() {
  return window.location.pathname.slice(1).split('/');
}

export class BottomScroller extends React.Component {
  state = {rendered: false, atBottom: true}
  safariScrollToEnd() {
    const {atBottom} = this.state;
    if (atBottom) {
      const scroller = findDOMNode(this.scrollView);
      scroller.id = 'scroller';
      setTimeout(() => {
        scroller.scrollTop = scroller.scrollHeight;
        // scroller.scrollTo(0, scroller.scrollHeight)
        },100);
    }
  }

  maybeScrollToEnd(animated) {
    const {atBottom} = this.state;
    if (atBottom) {
      this.scrollView.scrollToEnd({animated: false});
    }
  }

  onScroll() {
    const {atBottom} = this.state;
    const scroller = findDOMNode(this.scrollView);
    if (!scroller) return null;
    const scrollBottom = scroller.scrollTop + scroller.parentNode.scrollHeight;
    const scrollGap = scroller.scrollHeight - scrollBottom;

    const newAtBottom = scrollGap < 40;
    if (atBottom != newAtBottom) {
      this.setState({atBottom: newAtBottom});
    }
  }

  render() {
    const {children, style} = this.props;
    const {rendered} = this.state;
    return (
    <ScrollView 
      style={{...style, opacity: rendered ? 1 : 0}}
      ref={ref => this.scrollView = ref}
      onLayout={() => {
        // this.scrollView.scrollToEnd({animated: false}); 
        this.maybeScrollToEnd(false);
        this.setState({rendered: true});
      }}
      onScroll={() =>
        this.onScroll()
      }
      scrollEventThrottle={1}
      onContentSizeChange={(contentWidth, contentHeight)=>{        
          this.maybeScrollToEnd(true);
          // this.scrollView.scrollToEnd({animated: true});
      }}>
      {children}
    </ScrollView>
    )
  }
}

function basicRenderItem ({item: {key, item, value}}) {
  return <Catcher>{item || value()}</Catcher>;
}

export class BottomFlatScroller extends React.Component {
  safariScrollToEnd() {
    this.scroller && this.scroller.safariScrollToEnd();
  }
  
  render() {
    const {data, style} = this.props;
    
    return (
      <BottomScroller ref={r => this.scroller = r} style={style}>
        {data.map(({key, item, value}) => 
          <Catcher key={key}>
            {item || value()}
          </Catcher> 
        )}
      </BottomScroller>
    )
  }
}


// HACK: Temporarily disabled, since it seems this is annoying if doing chat while zooming
// FUTURE: Support desktop notifs
export async function playAlertSound() {
  return;
  // const soundObject = new Audio.Sound();
  // try {
  //   await soundObject.loadAsync(require('../assets/me-too.mp3'));
  //   await soundObject.playAsync();
  // } catch (error) {
  // }  
}

export var global_ringer;
var global_ringing_session;

export async function playRingSound(sessionId) {
  if (!global_ringer) {
    console.log('start ringing', sessionId);
    try {
      global_ringer = new Audio.Sound();    
      global_ringing_session = sessionId;
      await global_ringer.loadAsync(require('../assets/ring-ring.mp3'));
      // await global_ringer.setIsLoopingAsync(true);
      await global_ringer.playAsync();  
      setTimeout(() => {
        if (global_ringer && global_ringing_session == sessionId) {
          global_ringer.stopAsync();
          global_ringer = null;
          console.log('ring ended');
        }
      }, 13000);
      // console.log('started ringing');
    } catch (error) {
      // console.log('sound rejected');
      global_ringer = null;
    }
  } else {
    // console.log('already ringing');
  }
}

export async function stopRingSound() {
  // console.log('stopped ringing');
  if (global_ringer) {
    console.log('ring stopped');
    await global_ringer.stopAsync();
    global_ringer = null;
  }
}


// TODO: Support params, on screens without items
export function navigateToScreen({navigation, screen, item = '', props={}}) {
  const path = '/' + screen + '/' + item;
  window.open(path, path);
  // window.location.pathname = '/' + screen + '/' + item;
}

export function setTitle(title) {
  document.title = title;
}
export function setFavicon(iconUrl) {
  const favicon = document.getElementById('favicon');
  favicon.href = iconUrl;
}

export function watchAppFocus(callback) {
  return window.addEventListener('focus', callback);
}
export function watchAppBlur(callback) {
  return window.addEventListener('blur', callback);
}

export class TitleBlinker extends React.Component {
  componentDidMount() {
    // this.favicon = document.getElementById('favicon');
    this.maybeBlinkOn();
  }
  maybeBlinkOn(){
    const {count, focussed} = this.props;
    setTimeout(() => this.maybeBlinkOff(), 1000);
    if (count && !focussed) {
      setTitle('New Message');
      setFavicon('https://chillchat.org/favicon_alert.ico');
    }
  }
  maybeBlinkOff(){
    const {title, count, focussed} = this.props;
    setTimeout(() => this.maybeBlinkOn(), 1000);
    if (count && !focussed) {
      setTitle('(' + count + ') ' + title);
    } else {
      setTitle(title);
    }
    setFavicon('https://chillchat.org/favicon.ico');
  }
  render() {
    return null;
  }
}

function limitSize({width, height, maxSize}) {
  if (width > height) {
    const bigSize = Math.min(maxSize, width);
    return {width: bigSize, height: bigSize * (height / width)}
  } else {
    const bigSize = Math.min(maxSize, height);
    return {height: bigSize, width: bigSize * (width / height)}
  }
}

// export function getExifOrientationAsync({url}) {
//   return new Promise((resolve, reject) => {
//     console.log('getting data for url', url);
//     const img = new Image;
//     img.onload = () => {
//       console.log('image loaded');
//       EXIF.getData(img, () => {
//         console.log('exif got');
//         console.log(EXIF.pretty(img));
//         const orientation = EXIF.getTag(img, 'Orientation');
//         resolve(orientation);
//       })
//     }
//     img.src = url;
// })
// }

export function resizeImageAsync({url, rotate=false, maxSize = 400}) {
  // console.log('resizeImage', rotate);
  return new Promise((resolve, reject) => {
    // fixOrientation(url, {}, fixedUrl => {
      // console.log('originalUrl', url.slice(0,100));
      // console.log('fixedUrl', fixedUrl.slice(0,100));      
      var img = new Image();
      img.onload = () => {
        // console.log('image loaded', img.width, img.height);
        const {width, height} = limitSize({width: img.width, height: img.height, maxSize});
        var c = document.createElement('canvas');
        c.width = width;
        c.height = height;
        var ctx = c.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);
        const dataUrl = c.toDataURL('image/jpeg', 0.9);
        // console.log('dataUrl', dataUrl.slice(0,100), dataUrl.length);
        resolve(dataUrl);
      }
      img.src = url;
      // img.src = rotate ? fixedUrl : url;
    // })
  })
}

export function getClipboardImageAsync(event) {
  return new Promise((resolve, reject) => {
    const items = (event.clipboardData || event.originalEvent.clipboardData).items;
    var blob = null;
  
    for (var i = 0; i < items.length; i++) {
      if (items[i].type.indexOf("image") === 0) {
        blob = items[i].getAsFile();
      }
    }
    if(blob){
      event.preventDefault();
      event.stopPropagation();

      var reader = new FileReader();
      reader.onload = function(event) {
        const bigUrl = event.target.result;
        resolve(bigUrl);
      }
      reader.readAsDataURL(blob);
    } else {
      resolve(null);
    }  
  })
}

var global_timeDiff = 0;
export function getRawTimeNow() {return Date.now()}
export function getTimeNow() {return Date.now() + global_timeDiff};
export function setTimeDiff(timeDiff) {global_timeDiff = timeDiff};

export function getIsDebug() {
  const host = window.location.host;
  return !host.startsWith('localhost');
}

var global_beaconUrl;
export function sendFinalBeacon() {
  const host = window.location.host;
  // const host = 'foo';
  if (global_beaconUrl && !host.startsWith('localhost')) {
    navigator.sendBeacon(global_beaconUrl);
  }
}

export function setBeaconUrl(beaconUrl) {
  console.log('setBeaconUrl', beaconUrl);
  global_beaconUrl = beaconUrl;
}

function findCurrentLabel(items, id) {
  const item = _.find(items, i => i.id == id);
  return _.get(item,'label', '');
}

export function PopupSelector({value, items, style, onSelect}) {
  const currentLabel = findCurrentLabel(items, value);

  return (
    <PopupMenu items={items} onSelect={onSelect} popStyle={{top: 34}} textStyle={{fontSize: 20}}>
      <View style={{borderColor: '#ddd', paddingHorizontal: 8, paddingVertical: 4, borderRadius: 8, borderWidth: StyleSheet.hairlineWidth, ...style}}>
        <Text style={{fontSize: 20}}>
          {currentLabel}
        </Text>    
      </View>
    </PopupMenu>
  )
}


export class PopupMenu extends React.Component {
  state = {}
  render() {
    const {children, items, inverted, popStyle, textStyle, hasArrow, arrowStyle, onSelect} = this.props;
    const {shown} = this.state;
    return (
      <View>
        <FixedTouchable onPress={(() => this.setState({shown: !shown}))}>
          {children}
        </FixedTouchable>
        {shown ? 
          <View style={{position: 'absolute', borderColor: '#ddd', borderWidth: StyleSheet.hairlineWidth,
              backgroundColor: inverted ? 'black' : 'white',
              ...popStyle, flexShrink: 0}}>
            {hasArrow ? 
              <TopArrow style={{position: 'absolute', top: -10, right: 6, ...arrowStyle}} />
            : null}
            {items.map(i =>
              <FixedTouchable key={i.id} onPress={() => {onSelect(i.id); this.setState({shown: false})}} > 
                <HoverView 
                  style={{paddingHorizontal: 8, paddingVertical: 4, flexShrink: 0, 
                      backgroundColor: inverted ? 'black' : 'white', borderBottomColor: '#ddd', 
                      borderBottomWidth: StyleSheet.hairlineWidth}}
                  hoverStyle={{backgroundColor: inverted ? '#222' :'#f5f5f5'}}
                  >
                  <Text numberOfLines={1} style={{flexShrink: 0, 
                        color: inverted ? 'white' : '#666', ...textStyle}}>{i.label}</Text> 
                </HoverView>
              </FixedTouchable>
            )}
          </View>
        : null }
      </View>
    )
  }
}


export function addGlobalErrorCatcher(callback){
  window.addEventListener('error', e => {
    if (!getIsDebug()) {
      callback(e)
    }
  })  
}

export function webInit() {
  // const appPromo = document.getElementById('app-promo');
  // if (appPromo) {
  //   appPromo.parentElement.removeChild(appPromo);
  // }

  const style = document.createElement('link');
  style.setAttribute('href', 'https://talkbeat.com/app.css');
  // style.setAttribute('href', 'http://localhost:5000/app.css');

  style.setAttribute('rel', 'stylesheet');
  document.head.appendChild(style);  
  document.body.style.setProperty('height','100%');
  document.body.style.setProperty('overflow-y', 'hidden');
  document.body.style.setProperty('width','100%');
  document.body.style.setProperty('overflow-x', 'hidden');

  window.addEventListener('dragover', e => {
    e.preventDefault();
  })
  window.addEventListener('drop', e => {
    e.preventDefault();
  })
  window.addEventListener('beforeunload', e => {
    sendFinalBeacon();
  })
}

export function getUrlPath(url) {
  return new URL(url).pathname;
}

export const isTest = false;

export function ShareScreen({meeting}) {
  const url = 'https://talkbeat.com/gather/'+meeting;
  return (
    <HeaderSpaceView>
      <View style={{padding: 4, flexDirection: 'row', borderBottomColor: '#ddd', borderBottomWidth: StyleSheet.hairlineWidth}}>
        <View style={{marginLeft: 8}}>
          <Text numberOfLines={1} style={{flexShrink: 1, fontSize: 20}}>Invite People</Text>
        </View>
      </View>

      <View style={{backgroundColor: 'white', padding: 10, marginVertical: 8,
        alignItems: 'center', alignSelf: 'center',
        }}>
        <Text style={{marginBottom: 4, fontWeight: 'bold'}}>
          Invite someone to the gathering by sending this link
        </Text>
        <View style={{flexDirection: 'row', alignSelf: 'center', alignItems: 'center'}}>
          <View style={{backgroundColor: 'white', borderColor: '#bbb', borderWidth: 1, padding:8, marginRight: 10}}>
            <TextInput editable={false} value={url} style={{width: 350}} />
            {/* <Text selectable>{url}</Text> */}
          </View>
          <TouchableOpacity onPress={() => Clipboard.setString(url)}>
            <Feather name='copy' size={20} color='#222'/>
          </TouchableOpacity>
        </View>
      </View>
    </HeaderSpaceView>
  )

}

export function ShareBox({onSelect}) {
  return (
    <TouchableOpacity onPress={onSelect}>
      <View style={{flexDirection: 'row', alignItems: 'center', 
          borderColor: '#ddd', borderWidth: StyleSheet.hairlineWidth,
          paddingHorizontal: 12, paddingVertical: 6, backgroundColor: 'white', 
          alignSelf: 'center', borderRadius: 16, marginVertical: 16
        }}>
        <Entypo name='share-alternative' size={20} color='#222' />
        <Text style={{color: '#666', marginLeft: 4, fontSize: 16}}>Invite</Text>
      </View>
    </TouchableOpacity>  
  )
}

function getDateDay(date){
  return ['SU','MO','TU','WE','TH','FR','SA','SU'][new Date(date).getDay()];
}

function getDayMonthStep(date) {
  const anchorDate = new Date(date);
  const weekOffset = Math.floor((anchorDate.getDate() - 1) / 7);
  return weekOffset + 1;
}


function repeatToRecurring(meetingInfo) {
  switch(meetingInfo.repeat) {
    case 'once': return null;
    case 'daily': return {repeat: 'daily'};
    case 'weekly': return {repeat: 'weekly', byDay: getDateDay(meetingInfo.date)};
    case 'weekdays': return {repeat: 'weekly', byDay: 'MO,TU,WE,TH,FR'};
    case 'monthly': return {repeat: 'monthly', byDay: getDayMonthStep(meetingInfo.date) + getDateDay(meetingInfo.date)};
    case 'quarterly': return {repeat: 'monthly', interval: 3, byDay: getDayMonthStep(meetingInfo.date) + getDateDay(meetingInfo.date)};
    default: return null;
  }
}



export function CalendarButton({meeting, meetingInfo}) {
  const date = getFocusDate(meetingInfo);
  const event = {
    title: meetingInfo.name,
    description: 'Join the gathering at https://talkbeat.com/gather/' + meeting,
    location: 'Talkbeat',
    startTime: new Date(date).toUTCString(),
    endTime: new Date(date + hourMillis).toUTCString(),
    recurring: repeatToRecurring(meetingInfo)
  }
  return <AddToCalendar event={event} buttonTemplate={{'calendar-plus-o': 'left'}}/>
}

