import {firebaseApp, firebaseRoot} from './config'
import _ from 'lodash';
/* global fetch */


export async function getDataAsync(path, fallback = {}) {
	return await firebaseApp.database().ref(_.join(path,'/')).once('value').then(snap => snap.val() || fallback);
}

export async function setDataAsync(path, value) {
	// console.log('setDataAsync', path, value);
	try {
		return await firebaseApp.database().ref(_.join(path, '/')).set(value);
	} catch (e) {
		console.error('data set failed: ', path, value);
		console.log(e);
	}
}

export async function updateDataAsync(path, value) {
	// console.log('setDataAsync', path, value);
  return await firebaseApp.database().ref(_.join(path, '/')).update(value);
}


export function newKey() {
	return firebaseApp.database().ref().push().key;
}



export function onAuthStateChanged(callback) {
  return firebaseApp.auth().onAuthStateChanged(callback);
}

export function signOut() {
	return firebaseApp.auth().signOut();
}


export function watchData(obj, path, callback, fallback = {}) {
	try {
		// console.log('watchData', path);
		if (typeof(callback) != 'function') {
			console.error('bad watch', {obj, path, callback, cbtype: typeof(callback)});
			throw new Error('bad watch');
		}

		const func = snap => {
			// console.log('received', path, snap.val());
			callback(snap.val() || fallback);
		}
		const ref = firebaseApp.database().ref(_.join(path,'/'));
		if (!obj.watchers) {obj.watchers = []}

		ref.on('value', () => {});

		obj.watchers.push({ref, func});
		return ref.on('value', func, error => {
			console.error(error)
			throw new Error(error);
		});
	} catch (e) {
		console.error('error in watch:', path);
		console.log(e);
	}

	return null;
}


export async function sendDataAsync(path, value) {
	const key = newKey();
	await setDataAsync([...path, key], {time: SERVER_TIMESTAMP, value});
}

export function receiveData(obj, path, callback) {
	watchData(obj, path, async data => {
		if (data) {
			const keys = Object.keys(data);
			keys.forEach(key => 
				callback(data[key].value || null)
			)
			const toDelete = _.mapValues(data, () => null);
			await updateDataAsync(path, toDelete);
		}
	}, null)
}


export function internalReleaseWatchers(obj) {
	if (obj.watchers) {
		obj.watchers.forEach(({ref, func}) => {
			ref.off('value', func);
		})
	}
}

export function getUrlForFile(path) {
	const pathAsParam = encodeURIComponent(path);
	return 'https://firebasestorage.googleapis.com/v0/b/chillchat.appspot.com/o/' + pathAsParam + '?alt=media';
}

export function getUrlForImage(photo) {
	return getUrlForFile('image/' + photo);
}

export const SERVER_TIMESTAMP = firebaseRoot.database.ServerValue.TIMESTAMP; 
