import firebase from '@firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';

export const appName = 'Talkbeat';
// export const appIcon = 'https://chillchat.org/icon_web.png'
export const appIcon = 'https://talkbeat.org/talkbeat_web_icon.png';
export const appDomain = 'https://chillchat.org'

export const baseColor = '#0084ff';
export const baseBackgroundColor = '#f5f5f5';

const config = {
  apiKey: "AIzaSyCTBkvM8ErKXefMLXEUZne2sY0jzvdsdBE",
  authDomain: "chillchat-ff04c.firebaseapp.com",
  databaseURL: "https://chillchat-ff04c.firebaseio.com",
  projectId: "chillchat",
  storageBucket: "chillchat.appspot.com",
  messagingSenderId: "370438014649",
  appId: "1:370438014649:web:948768bac4fd82b5d13d68"
};

export const firebaseApp = firebase.initializeApp(config);

export const firebaseRoot = firebase;

