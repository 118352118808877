import { SERVER_TIMESTAMP, watchData, setDataAsync, newKey, getDataAsync, updateDataAsync, receiveData, sendDataAsync} from './fbutil';
import _ from 'lodash';
import { getTimeNow, setTimeDiff, getRawTimeNow } from '../components/shim';
import { Platform } from 'react-native';

const isWeb = Platform.OS == 'web';

export var global_currentUser = null;
export var global_currentUserName = 'Someone';

export function setCurrentUser(user) {
  global_currentUser = user;
}

export function setCurrentUserName(name) {
  global_currentUserName = name;
}

export function watchMyName(obj, callback) {
  return watchData(obj, ['private', global_currentUser, 'name'], callback);
}

export async function setMyName(name) {
  await setDataAsync(['private', global_currentUser, 'name'], name);
}

export function watchMeetingAttendees(obj, meeting, callback) {
  return watchData(obj, ['meeting', meeting, 'attendees'], callback);
}

export function watchConversations(obj, meeting, callback) {
  return watchData(obj, ['meeting', meeting, 'conversation'], callback);
}

// export async function getConversationsAsync(meeting) {
//   return getDataAsync(['meeting', meeting, 'conversation']);
// }


export function watchMyConversations(obj, meeting, callback) {
  return watchData(obj, ['private', global_currentUser, 'meeting', meeting, 'conversation'], callback);
}

// export async function getMeetingUserInfoAsyc({meeting, user}) {
//   return await getDataAsync(['meeting', meeting, 'attendees', user]);
// }

export function watchMeetingUserInfo(obj, {meeting, user}, callback) {
  return watchData(obj, ['meeting', meeting, 'attendees', user], callback);
}

// export function watchMeetingConversationInfo(obj, {meeting, conversation}, callback) {
//   return watchData(obj, ['meeting', meeting, 'conversation', conversation], callback, null);
// }

export function watchMessages(obj, {meeting, user}, callback) {
  return watchData(obj, ['private', global_currentUser, 'meeting', meeting, 'messages', user], callback);
}

export function watchAllPrivateMessages(obj, meeting, callback) {
  return watchData(obj, ['private', global_currentUser, 'meeting', meeting, 'messages'], callback);
}


export function watchBoardMessages(obj, {meeting, user}, callback) {
  return watchData(obj, ['meeting', meeting, 'board', user], callback)
}

export function watchAllBoardMessages(obj, meeting, callback) {
  return watchData(obj, ['meeting', meeting, 'board'], callback)
}


export function watchBoardPeeks(obj, meeting, callback) {
  return watchData(obj, ['meeting', meeting, 'boardpeek'], callback)
}

// export function watchConPeeks(obj, meeting, callback) {
//   return watchData(obj, ['meeting', meeting, 'conpeek'], callback);
// }

export function watchReadTimes(obj, meeting, callback) {
  return watchData(obj, ['private', global_currentUser, 'meeting', meeting, 'readTimes'], callback);
}

export async function markUserReadAsync({meeting, user}) {
  // console.log('markUserReadAsync', meeting, user);
  if (user) {
    await setDataAsync(['private', global_currentUser, 'meeting', meeting, 'readTimes', user], SERVER_TIMESTAMP);
    await setDataAsync(['private', global_currentUser, 'mymeeting', meeting, 'lastRead'], SERVER_TIMESTAMP);
  }
}

export async function markMeetingReadAsync(meeting) {
  await setDataAsync(['private', global_currentUser, 'mymeeting', meeting, 'lastRead'], SERVER_TIMESTAMP);
}


// export async function setVideoCallState({meeting, user}) {
  
// }

export async function setTypingIndicatorAsync({meeting, user, userInfo, toName, isTyping}) {
  // if (userInfo.host) {
    // console.log('room typing indicator', user);
    await setDataAsync(['meeting', meeting, 'roomTyping', user, global_currentUser], isTyping && SERVER_TIMESTAMP)
  // } else {
    // console.log('private typing indicator', user);
    await setDataAsync(['private', global_currentUser, 'meeting', meeting, 'typing', user, 'out'], isTyping && SERVER_TIMESTAMP);
  // }

  // console.log('setTypingIndicator', {global_currentUser, meeting, user, isTyping});
  // if (user == 'broadcast') return;

  await setDataAsync(['meeting', meeting, 'typing', global_currentUser], isTyping && {
    time: SERVER_TIMESTAMP, to: user, toName
  });
  // await setDataAsync(['private', global_currentUser, 'meeting', meeting, 'typing', user, 'out'], isTyping && SERVER_TIMESTAMP);
}

export function watchRoomTyping(obj, {meeting, user}, callback) {
  watchData(obj, ['meeting', meeting, 'roomTyping', user], callback, 0);
}

export function watchTypingIndicator(obj, {meeting, user}, callback) {
  watchData(obj, ['meeting', meeting, 'typing', user], callback);
  // return watchData(obj, ['private', global_currentUser, 'meeting', meeting, 'typing', user, 'in'], callback, false);
}

export function watchPrivateTypingIndicator(obj, {meeting, user}, callback) {
  return watchData(obj, ['private', global_currentUser, 'meeting', meeting, 'typing', user, 'in'], callback, false);
}


export function watchAllTyping(obj, meeting, callback) {
  watchData(obj, ['meeting', meeting, 'typing'], callback);
}

// export async function newConversationAsync({meeting, name, isPublic = true}) {
//   const key = newKey('conversation');
//   const conInfo = {
//     name, host: global_currentUser, isPublic, 
//     members: {[global_currentUser]: {time: SERVER_TIMESTAMP}},
//     followers: {[global_currentUser]: {time: SERVER_TIMESTAMP}}
//   }
//   const pCon = setDataAsync(['meeting', meeting, 'conversation', key], conInfo);
//   const pPeek = setDataAsync(['meeting', meeting, 'boardpeek', key], {time: SERVER_TIMESTAMP, name, host: global_currentUser, text: 'New Group Conversation', from: global_currentUser});
//   await pCon; await pPeek;
//   await joinConversationAsync({meeting, conInfo, conversation: key});
//   // const pMine = setDataAsync(['private', global_currentUser, 'meeting', meeting, 'conversation', key], {time: SERVER_TIMESTAMP});
//   // await pCon; await pMine; await pPeek;
//   return {conversation:key, conInfo};
// }

export async function newRoomAsync({meeting, members, name, isModerated = true, isPublic = false}) {
  const userInfo = {name, host: global_currentUser, 
    time: SERVER_TIMESTAMP, isPublic, isModerated,
    members: {...members, [global_currentUser]: true},
    room: true, admitted: true, present: SERVER_TIMESTAMP, lastPresent: SERVER_TIMESTAMP,
  }
  const key = newKey('room');
  await setDataAsync(['meeting', meeting, 'attendees', key], userInfo);
  await setDataAsync(['meeting', meeting, 'present', key], SERVER_TIMESTAMP);
  return key;
}

export async function editRoomAsync({room, host, meeting, members, name, isModerated = true, isPublic = false}) {
  const userInfo = {name, host, 
    time: SERVER_TIMESTAMP, isPublic, isModerated,
    members: {...members, [global_currentUser]: true},
    room: true, admitted: true, present: SERVER_TIMESTAMP, lastPresent: SERVER_TIMESTAMP,
  }
  const key = newKey('room');
  await setDataAsync(['meeting', meeting, 'attendees', room], userInfo);
  await setDataAsync(['meeting', meeting, 'present', room], SERVER_TIMESTAMP);
  return key;
}


// export async function joinConversationAsync({meeting, conInfo, conversation}) {
//   // console.log('joinConversationAsync', meeting, conversation, global_currentUser);
//   const pMember = setDataAsync(['meeting', meeting, 'conversation', conversation, 'members', global_currentUser], {time: SERVER_TIMESTAMP});
//   const pHello = sendMessageAsync({meeting, conInfo, user: conversation, text: 'joined ' + conInfo.name, type: 'join'});

//   // const oldGroup = await getDataAsync(['private', global_currentUser, 'meeting', meeting,'whereat'], null);
//   // var pLeave;
//   // if (oldGroup && oldGroup != conversation) {
//   //   const oldConInfo = await getDataAsync(['meeting', meeting, 'conversation', oldGroup]);
//   //   pLeave = leaveForOtherConversationAsync({meeting, conInfo: oldConInfo, conversation: oldGroup, newConInfo: conInfo});
//   // }
//   // const pMine = setDataAsync(['private', global_currentUser, 'meeting', meeting, 'whereat'], conversation);
//   // const pMine = setDataAsync(['private', global_currentUser, 'meeting', meeting, 'conversation', conversation], {time: SERVER_TIMESTAMP});
//   const pActive = setActiveConversationAsync({meeting, conversation});
//   await pMember; await pHello; await pActive;
//   // await pLeave; await pMine;
// }

// export async function followConversationAsync({meeting, conversation}) {
//   const pFollow = setDataAsync(['meeting', meeting, 'conversation', conversation, 'followers', global_currentUser], {time: SERVER_TIMESTAMP});
//   const pActive = setActiveConversationAsync({meeting, conversation});
//   await pFollow; await pActive;
// }

// export async function addConversationFollowerAsync({meeting, conversation, user}) {
//   console.log('addConversationFollowerAsync', meeting, conversation, user);
//   await setDataAsync(['meeting', meeting, 'conversation', conversation, 'followers', user], {time: SERVER_TIMESTAMP});
// }

// export async function unFollowConversationAsync({meeting, conversation}) {
//   await setDataAsync(['meeting', meeting, 'conversation', conversation, 'followers', global_currentUser], null);
// }


// export async function setActiveConversationAsync({meeting, conversation}) {
//   const oldGroup = await getDataAsync(['private', global_currentUser, 'meeting', meeting,'whereat'], null);
//   var pLeave; var pGoodbye; 
//   if (oldGroup && oldGroup != conversation) {
//     const pNewConInfo = getDataAsync(['meeting', meeting, 'conversation', conversation])
//     // if (oldConInfo.host != global_currentUser) {
//     pLeave = setDataAsync(['meeting', meeting, 'conversation', oldGroup, 'active', global_currentUser], null);
//     // pGoodbye = sendMessageAsync({meeting, conInfo:oldConInfo, user: oldGroup, text: global_currentUserName + ' went to ' + newConInfo.name, type: 'leave', quiet: true}); 
//     // }
//   }
//   const pJoin = setDataAsync(['meeting', meeting, 'conversation', conversation, 'active', global_currentUser], SERVER_TIMESTAMP);
//   const pNow = setDataAsync(['private', global_currentUser, 'meeting', meeting, 'whereat'], conversation);
//   await pLeave; await pJoin; await pNow; 
//   // await pGoodbye;
// }

// export async function leaveActiveConversationForVideoAsync({meeting, user}) {
//   const userInfo = await getMeetingUserInfoAsyc({meeting, user});
//   const oldGroup = await getDataAsync(['private', global_currentUser, 'meeting', meeting,'whereat'], null);
//   var pLeave;
//   if (oldGroup) {
//     const oldConInfo = await getDataAsync(['meeting', meeting, 'conversation', oldGroup]);
//     const text = global_currentUser + ' left to video chat with ' + userInfo.name;
//     pLeave = leaveConversationAsync({meeting, conInfo: oldConInfo, conversation: oldGroup, text});
//   }
//   await pLeave;
// }

// export async function leaveForOtherConversationAsync({meeting, conInfo, newConInfo, conversation}) {
//   const text = global_currentUserName + ' moved to ' + newConInfo.name;
//   await leaveConversationAsync({meeting, conInfo, conversation, text});
// }

// export async function leaveConversationAsync({meeting, conInfo, conversation, text}) {
//   var pActive;
//   const pMember = setDataAsync(['meeting', meeting, 'conversation', conversation, 'members', global_currentUser], null);
//   if (_.get(conInfo, ['active', global_currentUser])) {
//     pActive = setDataAsync(['meeting', meeting, 'conversation', conversation, 'active', global_currentUser], null);
//   }
//   const pHello = sendMessageAsync({meeting, conInfo, user: conversation, text: text || (global_currentUserName + ' left ' + conInfo.name), type: 'leave', quiet: true});
//   await pMember; await pHello; await pActive;
// }

// export async function addConversationMemberAsync({meeting, user, conversation}) {
//   await setDataAsync(['meeting', meeting, 'conversation', conversation, 'members', user], {time: SERVER_TIMESTAMP});  
// }

// export async function shareConversationAsync({meeting, user, userInfo, conInfo, sharedCon, sharedConName}) {
//   // console.log('shareConversation', {meeting, user, conInfo, sharedCon, sharedConName});
//   await setDataAsync(['meeting', meeting, 'conversation', sharedCon, 'members', user], {time: SERVER_TIMESTAMP});
//   const pUserMsg = await sendMessageAsync({meeting, user, conInfo, text: sharedConName, isBoard: user == global_currentUser,
//       extra: {sharedConName, sharedCon}, type: 'conversation'});  
//   const pGroupMsg = await sendMessageAsync({meeting, user: sharedCon, conInfo: true, text: 'invited ' + userInfo.name,
//       type: 'action', quiet: true});

//   // console.log('conversation sent', message);
//   await pUserMsg; await pGroupMsg;
// }

export async function editMessageAsync({meeting, message, messageInfo, text, requestPublic = null}) {
  // console.log('editMessage', text);
  const newMessageInfo = {...messageInfo, text, requestPublic, edited: true}
  // var pTo;

  const pToBoard = setDataAsync(['meeting', meeting, 'board', messageInfo.to, message], newMessageInfo);
  const pFromBoard = setDataAsync(['meeting', meeting, 'board', messageInfo.from, message], newMessageInfo);


  // if (messageInfo.to != global_currentUser) {
  //   pTo = setDataAsync(['private', global_currentUser, 'meeting', meeting, 'messages', messageInfo.to, 'out', message], 
  //     {...newMessageInfo, isBoard: false});
  // }
  // if (messageInfo.isBoard) {
  //   const pMyBoard = setDataAsync(['meeting', meeting, 'board', messageInfo.conversation || global_currentUser, message], 
  //     {...newMessageInfo, isBoard: true});
  //   // if (messageInfo.conversation) {
  //   //   const pCon = setDataAsync(['private', global_currentUser, 'meeting', meeting, 'messages', messageInfo.conversation, 'out', message], newMessageInfo);
  //   //   const pConBoard = setDataAsync(['meeting', meeting, 'board', messageInfo.conversation, 'out', message], newMessageInfo);
  //   //   await pCon; await pConBoard;
  //   // }  
  //   await pMyBoard;
  // }
  // await pTo;  

  await pToBoard;
  await pFromBoard;
}

export async function acceptPublication({meeting, message, messageInfo}) {
  // console.log('acceptPublication', meeting, message, messageInfo);
  const newMessageInfo = {...messageInfo, 
    approved: true,
    isBoard: true, requestPublic: null,
  }
  const pBoard = setDataAsync(['meeting', meeting, 'board', messageInfo.to, message], newMessageInfo);
  const pOtherBoard = setDataAsync(['meeting', meeting, 'board', messageInfo.from, message], newMessageInfo);
  const pPeek = setDataAsync(['meeting', meeting, 'boardpeek', messageInfo.to], newMessageInfo);
  await pBoard; await pPeek; await pOtherBoard;
}

// export async function requestPublication({meeting, user, message, messageInfo, publish}) {
//   console.log('requestPublication', meeting, user, message, messageInfo, publish);
//   await sendResponseAsync({meeting, user, type: 'publish', message, messageInfo, data: publish});  

//   if (!publish) {
//     var pTo;
//     if (messageInfo.from == global_currentUser) {
//       pTo = setDataAsync(['private', global_currentUser, 'meeting', meeting, 'messages', messageInfo.to, 'out', message, 'requestPublic'], null);
//     }
//     const pBoard = setDataAsync(['meeting', meeting, 'board', messageInfo.from, message], null);
//     await pBoard; await pTo;
//   }
// }

export async function markSelfActiveAsync(meeting) {
  if (isWeb) {
    await setDataAsync(['private', global_currentUser, 'mymeeting', meeting, 'webActiveTime'], SERVER_TIMESTAMP)
  }
}

export async function sendMessageAsync({meeting, isModerated = true, roomHost = null, isRoom = false, isPublic = true, privateTo = null, requestPublic = null, photo=null, isBoard=null, isBroadcast=null, user, text='', replyToMessage=null, replyToMessageInfo=null, type=null, quiet=null, extra=null}) {
  const key = newKey('message');
  // console.log('sendMessageAsync', meeting, user, text, privateTo, key);
  const messageInfo={text, quiet,
      approved: ((user == global_currentUser) || (isRoom && (!isModerated || roomHost == global_currentUser))) ? true : null,
      from: global_currentUser, photo, to: user, isBroadcast, type, extra, 
      requestPublic, isBoard: isPublic, isPublic, privateTo,
      replyToMessage, 
      replyToMessageInfo: replyToMessageInfo ? {...replyToMessageInfo, replyToMessageInfo: null} : null, 
      time: SERVER_TIMESTAMP};
  var pPeek;
  var pMessage; var pNotePrivate; var pActive; var pPresent; var pBoard; var pBoardPeek; var pOtherBoard;
  // if (user != global_currentUser) {
  //   // if (conInfo) {
  //   //   pMessage = setDataAsync(['meeting', meeting, 'messages', user, key], messageInfo)
  //   //   if (!quiet) {
  //   //     pPeek = setDataAsync(['meeting', meeting, 'peek', user], messageInfo);
  //   //   } e
  //   // } else {
  //   pMessage = setDataAsync(['private', global_currentUser, 'meeting', meeting, 'messages', user, 'out', key], messageInfo)
  //   // if (!quiet) {
  //   //   pPeek = setDataAsync(['private', global_currentUser, 'meeting', meeting, 'chatpeek', user, 'out'], messageInfo);
  //   // } 
  //   // }
  // }
  // if (isBoard) {
  //   pBoard = setDataAsync(['meeting', meeting, 'board', global_currentUser, key], {...messageInfo, isBoard: true});
  //   pBoardPeek = setDataAsync(['meeting', meeting, 'boardpeek', global_currentUser], {...messageInfo, isBoard: true});
  // }

  // if (isRoom || user == global_currentUser) {
    // console.log('room message to', user);
    pBoard = setDataAsync(['meeting', meeting, 'board', user, key], {...messageInfo});
    // if (messageInfo.isBoard) {
    pOtherBoard = setDataAsync(['meeting', meeting, 'board', global_currentUser, key], {...messageInfo});
  // } else {
    // console.log('1-to-1 message to', user);
    // pMessage = setDataAsync(['private', global_currentUser, 'meeting', meeting, 'messages', user, 'out', key], messageInfo)
  // }
  // }
  if ((user == global_currentUser || roomHost == global_currentUser) && isPublic) {
    pBoardPeek = setDataAsync(['meeting', meeting, 'boardpeek', user], {...messageInfo});
  }

  if (isWeb) {
    pPresent = markSelfActiveAsync(meeting);
  }

  // if (!type && !isBoard && !isBroadcast) {
  //   const echoKey = newKey('message');
  //   const echoMessageInfo = {
  //     from: global_currentUser, type: 'echo', talkTo: user, time: SERVER_TIMESTAMP,
  //     toName: conInfo ? conInfo.name : null
  //   }
  //   pMessage = setDataAsync(['meeting', meeting, 'board', global_currentUser, echoKey], echoMessageInfo);
  // }
  // if (conInfo && !isBoard && !type && !isBroadcast) {
  //   pGroupEcho = setDataAsync(['meeting', meeting, 'privatePeek', user], {
  //     from: global_currentUser, time: SERVER_TIMESTAMP
  //   })    
  // }
  // if (!type && conInfo) {
  //   pActive = setActiveConversationAsync({meeting, conversation: user});
  // }

  if (replyToMessageInfo && !isPublic) {
    if (
      (replyToMessageInfo.conversation && user != replyToMessageInfo.conversation) ||
      (!replyToMessageInfo.conversation && replyToMessageInfo.isBoard)
    ) {
      pNotePrivate = notePrivateReplyAsync({meeting, user: replyToMessageInfo.conversation || user, message: replyToMessage, messageInfo: replyToMessageInfo});
    }
  }

  await pMessage; await pPeek; await pNotePrivate; await pActive; await pPresent;
  await pBoard; await pBoardPeek; await pOtherBoard;
  return {message:key, messageInfo};
}

export async function sendResponseAsync({meeting, user, isCon, type, message, messageInfo, data = null}) {
  const key = newKey('react');
  // console.log('sendResponseAsync', {key, meeting, user, message, type, data});

  // if (messageInfo.isBoard && type != 'publish') {
  await setDataAsync(['meeting', meeting, 'boardresponses', user, key], {
    from: global_currentUser, about: message, type, conversation: isCon ? user : null, data
  })
  // } else {
  //   await setDataAsync(['private', global_currentUser, 'meeting', meeting, 'responses', user, 'out', key], {
  //     from: global_currentUser, about: message, type, conversation: isCon ? user : null, data    
  //   })
  // }
  // if (isCon) {
  //   await setActiveConversationAsync({meeting, conversation: user});
  // }
}

// export async function setRoomName({meeting, user, name}) {
//   console.log('setRoomName', {meeting, user, name});
//   await setDataAsync(['meeting', meeting, 'attendees', user, 'roomName'], name);
// }

// export function watchRoomName(obj, {meeting, user}, callback) {
//   watchData(obj, ['meeting', meeting, 'attendees', user, 'roomName'], callback, null);  
// }

export async function likeMessageAsync({meeting, user, conInfo, message, messageInfo, unlike = false}){ 
  await sendResponseAsync({type: 'like', meeting, user, isCon: conInfo, message, messageInfo});
}

export async function clearInviteAsync({meeting, user, message, messageInfo}) {
  await sendResponseAsync({type: 'responded', meeting, user, message, messageInfo, isCon: messageInfo.conversation});
}

export function watchPrivatePeek(obj, {meeting, user}, callback) {
  watchData(obj, ['meeting', meeting, 'privatePeek', user], callback);
}

// export async function OLD_likeMessageAsync({meeting, user, conInfo, message, messageInfo}) {
//   const key = newKey('react');  
//   if (messageInfo.isBoard) {
//     await setDataAsync(['meeting', meeting, 'boardresponses', user, key], {
//       from: global_currentUser, about: message, type: 'like', conversation: conInfo ? user : null    
//     })
//   } else {
//     await setDataAsync(['private', global_currentUser, 'meeting', meeting, 'responses', user, 'out', key], {
//       from: global_currentUser, about: message, type: 'like', conversation: conInfo ? user : null    
//     })
//   }
// }

export async function notePrivateReplyAsync({meeting, user, message, messageInfo}) {
  const key = newKey('react');  
  if (messageInfo.isBoard) {
    await setDataAsync(['meeting', meeting, 'boardresponses', user, key], {
      from: global_currentUser, about: message, type: 'reply', conversation: messageInfo.conversation || null   
    })
  } else {
    await setDataAsync(['private', global_currentUser, 'meeting', meeting, 'responses', user, 'out', key], {
      from: global_currentUser, about: message, type: 'reply', conversation: messageInfo.conversation || null   
    })
  }
}


export async function watchResponses(obj, {meeting, user}, callback) {
  return watchData(obj, ['private', global_currentUser, 'meeting', meeting, 'responses', user], callback);
}

export async function watchBoardResponses(obj, {meeting, user}, callback) {
  return watchData(obj, ['meeting', meeting, 'boardresponses', user], callback);
}

// export async function sendMessageCopiedToConversationAsync({meeting, user, text, photo=null, replyToMessage=null, replyToMessageInfo=null, conversation, origCon, origName, conName, newCon}) {
//   // console.log('copyToConversation', {conversation, conName});
//   const message = newKey('message');
//   const messageInfo={from: global_currentUser, to: user, text, photo, 
//         replyToMessage, replyToMessageInfo, 
//         conversation, conName, origName, origCon, time: SERVER_TIMESTAMP};
//   const pLocal = setDataAsync(['private', global_currentUser, 'meeting', meeting, 'messages', user, 'out', message], messageInfo);
//   const pCon = setDataAsync(['private', global_currentUser, 'meeting', meeting, 'messages', conversation, 'out', message], messageInfo);
//   // const pBoard = setDataAsync(['meeting', meeting, 'board', global_currentUser, message], messageInfo);
//   // var pPeek;
//   // if (newCon) {
//   //   pPeek = setDataAsync(['meeting', meeting, 'boardpeek', global_currentUser], messageInfo);
//   // }
//   // // const pConBoard = setDataAsync(['meeting', meeting, 'board', conversation, message], messageInfo);
//   // const pConPeek = setDataAsync(['meeting', meeting, 'boardpeek', conversation], messageInfo);
//   // // await pConBoard; 
//   // await pConPeek;
//   // await pLocal; await pBoard; await pPeek;
//   await pLocal; await pCon;
//   return {messageInfo}
// }

// export async function shareMessageToConversationAsync({meeting, message, messageInfo, conversation, conName, origConInfo}) {
//   // console.log('shareMessage', {message, messageInfo, conversation, conInfo});
//   const newMessageInfo = {...messageInfo, 
//     origCon: messageInfo.conversation || null, 
//     origName: origConInfo ? origConInfo.name : 'User',
//     conversation, conName};
//   var pLocal = setDataAsync(['private', global_currentUser, 'meeting', meeting, 'messages', messageInfo.to, 'out', message], newMessageInfo);
//   const pCon = setDataAsync(['private', global_currentUser, 'meeting', meeting, 'messages', conversation, 'out', message], newMessageInfo);
//   await pLocal; await pCon; 
// }

// export async function askToMoveMessageAsync({meeting, user, userInfo, conInfo, toConInfo, toCon, message, messageInfo}) {
//   // console.log('askToMoveMessageAsync', {meeting, user, conInfo, toConInfo});
//   if ((conInfo && conInfo.isPublic) || !messageInfo.replyToMessage) {
//     await shareMessageToConversationAsync({meeting, message, messageInfo, 
//       conversation: toCon, conName: toConInfo.name, origConInfo: conInfo, userInfo})
//   } else {
//     const key = newKey('message');
//     const newMessageInfo = {
//       type: 'moverequest', from: global_currentUser, to: user, conName: toConInfo.name, toCon,
//       conversation: conInfo ? user : null, origName: conInfo ? conInfo.name : 'User',
//       onlyTo: user, aboutMessage: message, aboutMessageInfo: messageInfo,
//       text: 'asked to move a reply',
//       time: SERVER_TIMESTAMP
//     };

//     await setDataAsync(['private', global_currentUser, 'meeting', meeting, 'messages', user, 'out', key], newMessageInfo)
//   }
// }

// export async function acceptMoveRequest({meeting, message, messageInfo}) {
//   // CHALLENGE: How do they move my message, when message isn't theirs
//   // const aboutMessageInfo = messageInfo.aboutMessageInfo;
//   // const pShare = shareMessageToConversationAsync({meeting, message: messageInfo.aboutMessage,
//   //   messageInfo: messageInfo.aboutMessageInfo,
//   //   conversation: messageInfo.toCon, conName: aboutMessageInfo.conName
//   // });
// // 
//   // const key = newKey('approve');
//   // const pApprove = setDataAsync(['private', global_currentUser, 'action', key], {
//   //   aboutMessage: messageInfo.aboutMessage,
//   //   aboutUser: messageInfo.aboutMessage.to,
//   //   type: 'approvemove'    
//   // })

//   const pRespond = sendResponseAsync({meeting, user: messageInfo.from, 
//         isCon: messageInfo.conversation, message, messageInfo, type: 'accept'})
//   await pRespond;
//   // await pApprove;
// }

// export async function rejectMoveRequest({meeting, message, messageInfo}) {
//   const pRespond = sendResponseAsync({meeting, user: messageInfo.from, 
//         isCon: messageInfo.conversation, message, messageInfo, type: 'reject'})
//   await pRespond;
//  }


// export async function askToCopyMessageToBoardAsync({meeting, user, conInfo, message, messageInfo}) {
//   if (messageInfo.from == global_currentUser && conInfo.host == global_currentUser) {
//     await copyMessageToBoardAsync({meeting, user, conInfo, message, messageInfo});
//   } else {
//     const isHost = conInfo.host == global_currentUser;
//     const key = newKey('message');
//     const approver = isHost ? messageInfo.from : conInfo.host;
//     const newMessageInfo = {
//       type: 'boardrequest', from: global_currentUser, to: user, conversation: user, 
//       onlyTo: approver, aboutMessage: message, aboutMessageInfo: messageInfo,
//       text: 'asked to highlight a message',
//       time: SERVER_TIMESTAMP
//     };

//     await setDataAsync(['private', global_currentUser, 'meeting', meeting, 'messages', user, 'out', key], newMessageInfo);
//   }
// }

// export async function acceptBoardRequest({meeting, message, messageInfo}) {
//   const newMessageInfo = {...messageInfo.aboutMessageInfo, isBoard: true};
//   const conversation = messageInfo.conversation;
//   const pConBoard = setDataAsync(['meeting', meeting, 'board', conversation, messageInfo.aboutMessage], newMessageInfo);
//   const pConPeek = setDataAsync(['meeting', meeting, 'boardpeek', conversation], newMessageInfo);

//   const acceptedMessageInfo = {...messageInfo, onlyTo: messageInfo.from, isAccepted: true};
//   const pAccept = setDataAsync(['private', global_currentUser, 'meeting', meeting, 'messages', conversation, 'out', message], acceptedMessageInfo)

//   const key = newKey('message');
//   const pNotify = setDataAsync(['private', global_currentUser, 'meeting', meeting, 'messages', conversation, 'out', key], {
//     type: 'boardaccept', from: global_currentUser, to: conversation, conversation,
//     onlyTo: messageInfo.from, aboutMessage: message, hiddenFromSender: true,
//     text: global_currentUserName + ' accepted your publication request', time: SERVER_TIMESTAMP,    
//   });

//   await pConBoard; await pConPeek; await pAccept; await pNotify;
// }

// export async function rejectBoardRequest({meeting, message, messageInfo}) {
//   console.log('reject', {meeting, messageInfo})
//   const conversation = messageInfo.conversation;
//   const rejectedMessageInfo = {...messageInfo, onlyTo: messageInfo.from, isRejected: true};
//   const pReject = setDataAsync(['private', global_currentUser, 'meeting', meeting, 'messages', conversation, 'out', message], rejectedMessageInfo);

//   await pReject;
// }

// export async function copyMessageToBoardAsync({meeting, user, conInfo, message, messageInfo}) {
//   const newMessageInfo = {...messageInfo, isBoard: true, conversation: conInfo ? user : null, conName: _.get(conInfo,'name',null)};
//   const pLocal = setDataAsync(['private', global_currentUser, 'meeting', meeting, 'messages', user, 'out', message], newMessageInfo);
//   if (conInfo) {
//     const pConBoard = setDataAsync(['meeting', meeting, 'board', user, message], newMessageInfo);
//     const pConPeek = setDataAsync(['meeting', meeting, 'boardpeek', user], newMessageInfo);
//     await pConBoard; await pConPeek;
//   } else {
//     const pBoard = setDataAsync(['meeting', meeting, 'board', global_currentUser, message], newMessageInfo);
//     await pBoard;
//   }
//   await pLocal;  
//   return {newMessageInfo}
// }

// export async function removeMessageFromBoardAsync({meeting, message, messageInfo}) {
//   const newMessageInfo = {...messageInfo, isBoard: false};
//   // console.log('removeMessageFromBoard', meeting, message, messageInfo);
//   const pLocal = setDataAsync(['private', global_currentUser, 'meeting', meeting, 'messages', messageInfo.conversation || messageInfo.to, 'out', message], newMessageInfo)
//   const pBoard = setDataAsync(['meeting', meeting, 'board', messageInfo.conversation || messageInfo.to, message], null);
//   await pLocal; await pBoard;  
// }

export async function deleteMessage({meeting, user, message, messageInfo}) {
  // console.log('deleteMessage', {meeting, user, message, messageInfo});
  const newMessageInfo = {...messageInfo, isDeleted: true};
  // await setDataAsync(['private', global_currentUser, 'meeting', meeting, 'messages', user, 'out', message], newMessageInfo);
  // if (messageInfo.isBoard) {
  await setDataAsync(['meeting', meeting, 'board', messageInfo.to, message], newMessageInfo);
  await setDataAsync(['meeting', meeting, 'board', global_currentUser, message], newMessageInfo);

  // }
  // if (messageInfo.isBroadcast) {
  //   await setDataAsync(['meeting', meeting, 'board', 'broadcast', message], newMessageInfo);
  // }
}


export function watchMeetingInfo(obj, meeting, callback) {
  return watchData(obj, ['meeting', meeting, 'info'], callback);
}

export function watchUserIntroduction(obj, {meeting, user}, callback) {
  return watchData(obj, ['meeting', meeting, 'intro', user], callback);
}

export async function setUserIntroductionAsync({meeting, user, text}) {
  // console.log('setUserIntroduction', text);
  await setDataAsync(['meeting', meeting, 'intro', user], {
    text, time: SERVER_TIMESTAMP, from: global_currentUser
  })
}

export function watchMyMeetingChatPeeks(obj, meeting, callback) {
  return watchData(obj, ['private', global_currentUser, 'meeting', meeting, 'chatpeek'], callback);
}

// export function watchLiveChat(obj, {meeting, user}, callback) {
//   return watchData(obj, ['private', global_currentUser, 'meeting', meeting, 'livechat', user], callback);
// }

export async function updateMyMeetingAsync({meeting, meetingInfo}) {
  await setDataAsync(['private', global_currentUser, 'mymeeting', meeting, 'meetingInfo'], meetingInfo);
}

export async function addMeetingAsync({meeting, meetingInfo}) {
  const secret = Math.floor(Math.random() * 1000000000);
  await setDataAsync(['private', global_currentUser, 'mymeeting', meeting], {
    follow: true,
    joinTime: SERVER_TIMESTAMP, secret, meetingInfo});
}

export async function removeMeetingAsync({meeting}) {
  console.log('removeMeeting', meeting);
  await setDataAsync(['private', global_currentUser, 'mymeeting', meeting], null);
}

export async function joinMeeting({meeting, meetingInfo, myName, photo = null, thumb = null, isHost = false}) {
  const pStdPhoto = getDataAsync(['private',global_currentUser, 'photo'], null);
  const pStdThumb = getDataAsync(['private',global_currentUser, 'thumb'], null);
  const pGetEveryone = getDataAsync(['meeting', meeting, 'conversation', 'everyone'], null);

  const pAttend = updateDataAsync(['meeting', meeting, 'attendees', global_currentUser], {
    lastPresent: SERVER_TIMESTAMP, 
    name: myName, present: SERVER_TIMESTAMP, 
    time: SERVER_TIMESTAMP})  

  const secret = Math.floor(Math.random() * 1000000000);
  const pJoinTime = setDataAsync(['private', global_currentUser, 'meeting', meeting, 'joinTime'], SERVER_TIMESTAMP);
  const pSecret = setDataAsync(['private', global_currentUser, 'meeting', meeting, 'secret'], secret);

  const pMine = setDataAsync(['private', global_currentUser, 'mymeeting', meeting], {
    present: SERVER_TIMESTAMP,
    joinTime: SERVER_TIMESTAMP, secret, meetingInfo});

  await pAttend; await pJoinTime; await pSecret; 
  const stdPhoto = await pStdPhoto;
  const stdThumb = await pStdThumb;
  const everyoneInfo = await pGetEveryone;
  var pPhoto; var pThumb; var pEveryone;
  if (photo && thumb) {
    pPhoto = setDataAsync(['meeting', meeting, 'attendees', global_currentUser, 'photo'], photo);
    pThumb = setDataAsync(['meeting', meeting, 'attendees', global_currentUser, 'thumb'], thumb);
  } else if (stdPhoto && stdThumb) {
    pPhoto = setDataAsync(['meeting', meeting, 'attendees', global_currentUser, 'photo'], stdPhoto);
    pThumb = setDataAsync(['meeting', meeting, 'attendees', global_currentUser, 'thumb'], stdThumb);
  }
  if (isHost) {
    await setDataAsync(['meeting', meeting, 'attendees', global_currentUser, 'admitted'], true);
  }
  if (!everyoneInfo) {
    pEveryone = setDataAsync(['meeting', meeting, 'conversation', 'everyone'], {
      name: 'Everyone', host: meetingInfo.host, isPublic: true
    })
  }
  const pFollow = followMeetingAsync({meeting, follow: true});

  await markSelfPresent({meeting});
  await pMine;
  await pPhoto; await pThumb; await pEveryone; await pFollow;
}

export async function getMyMeetingSecretAsync(meeting) {
  return await getDataAsync(['private', global_currentUser, 'meeting', meeting, 'secret']);
}

export async function watchMyMeetingSecret(obj, meeting, callback) {
  watchData(obj, ['private', global_currentUser, 'meeting', meeting, 'secret'], callback);
}


export async function markSelfPresent({meeting}) {
  // console.log('markSelfPresent', meeting);
  const localTime = getRawTimeNow();
  await setDataAsync(['meeting', meeting, 'present', global_currentUser], SERVER_TIMESTAMP);
  const pWebHere = setDataAsync(['meeting', meeting, 'webPresent', global_currentUser], SERVER_TIMESTAMP);

  const serverTime = await getDataAsync(['meeting', meeting, 'present', global_currentUser]);
  setTimeDiff(serverTime - localTime);
  await pWebHere;
}

export async function markSelfAbsent({meeting}) {
  // console.log('markSelfPresent', meeting);
  // const pAbsent = setDataAsync(['meeting', meeting, 'present', global_currentUser], getTimeNow() - minuteMillis);
  const pLeave = setDataAsync(['meeting', meeting, 'attendees', global_currentUser, 'present'], false);
  const pMineLeave = setDataAsync(['private', global_currentUser, 'mymeeting', meeting, 'present'], false);
  const pPresent = setDataAsync(['meeting', meeting, 'present', global_currentUser], null);

  // await pAbsent; 
  await pLeave; await pMineLeave; await pPresent;
}

export function watchPresence(obj, meeting, callback) {
  watchData(obj, ['meeting', meeting, 'present'], callback);
}


export async function createMeetingAsync({name, summary, date, repeat='once'}) {
  const key = newKey('meeting');
  const meetingInfo = {name, repeat, summary, date, host: global_currentUser, createTime: SERVER_TIMESTAMP};
  const pMeeting = setDataAsync(['meeting', key, 'info'], meetingInfo);
  const pEveryone = setDataAsync(['meeting', key, 'conversation', 'everyone'], {
    name: 'Everyone', host: global_currentUser, isPublic: true
  })
  // await setDataAsync(['private', global_currentUser, 'meeting', key, 'createTime'], SERVER_TIMESTAMP);
  const pMine = await setDataAsync(['private', global_currentUser, 'mymeeting', key], {
    follow: true,
    createTime: SERVER_TIMESTAMP, meetingInfo});
  // const pIndex = await setDataAsync(['special', 'meetingIndex', key], {lastPurge: SERVER_TIMESTAMP, name});

  await pMeeting; await pMine; await pEveryone; 
  // await pIndex;
  return key;
}

export async function updateMeetingAsync({meeting, name, summary='', date, repeat='once'}) {
  await setDataAsync(['meeting', meeting, 'info'], {name, summary, date, host: global_currentUser, repeat, createTime: SERVER_TIMESTAMP});
}

export function watchMeFollow(obj, {meeting, user}, callback) {
  watchData(obj, ['meeting', meeting, 'follow', user, global_currentUser], callback, false);
}

export function watchFollows(obj, meeting, callback) {
  watchData(obj, ['meeting', meeting, 'follow'], callback);

}

export async function followUserAsync({meeting, user}) {
  await setDataAsync(['meeting', meeting, 'follow', user, global_currentUser], SERVER_TIMESTAMP);
}

export async function unFollowUserAsync({meeting, user}) {
  await setDataAsync(['meeting', meeting, 'follow', user, global_currentUser], null);
}

export async function followMeetingAsync({meeting, follow}) {
  await setDataAsync(['private', global_currentUser, 'mymeeting', meeting, 'follow'], follow);
}

export function watchMyMeetings(obj, callback) {
  return watchData(obj, ['private', global_currentUser, 'mymeeting'], callback);
}

export function watchMyMeeting(obj, meeting, callback) {
  return watchData(obj, ['private', global_currentUser, 'mymeeting', meeting], callback);
}


export function watchMeetingMembers(obj, meeting, callback) {
  return watchData(obj, ['meeting', meeting, 'members'], callback);
}

export async function addNewMemberAsync(meeting) {
  const key = newKey('member');
  await setDataAsync(['meeting', meeting, 'members', key], {name: '', time: SERVER_TIMESTAMP});
  return key;
}

export async function saveMemberAsync({meeting, member, name, intro = null, photo = null, thumb = null, email = null}) {
  await setDataAsync(['meeting', meeting, 'members', member], {name, intro, photo, thumb, email});  
}

export function watchMyEmail(obj, callback) {
  return watchData(obj, ['special', 'userEmail', global_currentUser], callback);
}

export function watchVanityName(obj, name, callback) {
  return watchData(obj, ['special', 'vanity', name], callback, null);
}

export async function setMyPhoto({meeting, photo, thumb}) {
  const pMeetingPhoto = setDataAsync(['meeting', meeting, 'attendees', global_currentUser, 'photo'], photo);
  const pMeetingThumb = setDataAsync(['meeting', meeting, 'attendees', global_currentUser, 'thumb'], thumb);
  const pGlobalPhoto = setDataAsync(['private', global_currentUser, 'photo'], photo);
  const pGlobalThumb = setDataAsync(['private', global_currentUser, 'thumb'], thumb);
  await pMeetingPhoto; await pMeetingThumb; await pGlobalPhoto; await pGlobalThumb;
}

export async function setUserPhoto({meeting, user, photo, thumb}) {
  const pMeetingPhoto = setDataAsync(['meeting', meeting, 'attendees', user, 'photo'], photo);
  const pMeetingThumb = setDataAsync(['meeting', meeting, 'attendees', user, 'thumb'], thumb);
  await pMeetingPhoto; await pMeetingThumb;
}

export async function clearHelpMessage(id) {
  await setDataAsync(['private', global_currentUser, 'helpcleared', id], SERVER_TIMESTAMP);
}

export async function restoreHelpMessage(id) {
  await setDataAsync(['private', global_currentUser, 'helpcleared', id], null);
}


export async function watchHelpClearings(obj, callback) {
  watchData(obj, ['private', global_currentUser, 'helpcleared'], callback);
}

export async function leaveMeetingAsync(meeting) {
  console.log('leaveMeetingAsync', meeting);
  // const conversations = await getConversationsAsync(meeting);
  var pLeaves = [];
  const pWhere = setDataAsync(['private', global_currentUser, 'meeting', meeting,'whereat'], null);

  // Object.keys(conversations).forEach(c => {
  //   console.log('conversation', c, conversations[c]);
  //   if (_.get(conversations,[c,'members',global_currentUser])){
  //     pLeaves.push(leaveConversationAsync({meeting, conInfo: conversations[c], conversation:c}));
  //   }
  // })
  await markSelfAbsent({meeting});
  // await Promise.all(pLeaves);
  await pWhere;
  console.log('done');
}

export async function ejectFromMeetingAsync({meeting, user}) {
  console.log('ejectFromMeetingAsync', meeting, user);
  // const conversations = await getConversationsAsync(meeting);
  var pLeaves = [];
  // const pWhere = setDataAsync(['private', global_currentUser, 'meeting', meeting,'whereat'], null);

  // Object.keys(conversations).forEach(c => {
  //   console.log('conversation', c, conversations[c]);
  //   if (_.get(conversations,[c,'members',user])){
  //     const pMember = setDataAsync(['meeting', meeting, 'conversation', c, 'members', user], null);
  //     const pActive = setDataAsync(['meeting', meeting, 'conversation', c, 'active', user], null);
  //     pLeaves.push(pMember);
  //     pLeaves.push(pActive);
  //   }
  // })
  const pLeave = setDataAsync(['meeting', meeting, 'attendees', user, 'present'], false);
  const pNotAdmitted = setDataAsync(['meeting', meeting, 'attendees', user, 'admitted'], false);
  await Promise.all(pLeaves);
  await pLeave; await pNotAdmitted;
  console.log('done');
}

export async function admitPerson({meeting, user}) {
  await setDataAsync(['meeting', meeting, 'attendees', user, 'admitted'], true);
}

export async function sendOfferAsync({meeting, user, sessionId, offer}) {
  await sendDataAsync(['video', user, global_currentUser, meeting, sessionId, 'offer'], offer);
}

export async function sendAnswerAsync({meeting, user, sessionId, answer}) {
  await sendDataAsync(['video', user, global_currentUser, meeting, sessionId, 'answer'], answer);
}

export async function sendCandidateAsync({meeting, user, sessionId, endpoint, candidate}) {
  await sendDataAsync(['video', user, global_currentUser, meeting, sessionId, 'candidate', endpoint], candidate);
}

export async function sendEndCall({meeting, user, sessionId, reason='declined'}) {
  console.log('sendEndCall', meeting, user, sessionId, reason);
  await sendMessageAsync({user, meeting, text: reason + ' the video call', type: 'action', quiet: true});
  await sendDataAsync(['video', user, global_currentUser, meeting, sessionId, 'endcall'], {reason, time: SERVER_TIMESTAMP});
}

// export async function sendCallStatus({meeting, user, sessionId, status}) {
//   await setDataAsync(['video', user, global_currentUser, meeting, sessionId, 'callstatus'], {status, time: SERVER_TIMESTAMP});
// }

// export async function clearIncomingCallStatus({meeting, user}) {
//   await setDataAsync(['video', global_currentUser, user, meeting, 'callstatus'], null);
// }

// HACK: Receive code could be risky. Do this better
export function receiveOffer(obj, {meeting, user, sessionId}, callback) {
  receiveData(obj, ['video', global_currentUser, user, meeting, sessionId, 'offer'], callback);
}

export function receiveAnswer(obj, {meeting, user, sessionId}, callback) {
  receiveData(obj, ['video', global_currentUser, user, meeting, sessionId, 'answer'], callback);
}

export function receiveCandidate(obj, {meeting, user, sessionId, endpoint}, callback) {
  receiveData(obj, ['video', global_currentUser, user, meeting, sessionId, 'candidate', endpoint], callback);
}

export function receiveCallEnd(obj, {meeting, user, sessionId}, callback) {
  receiveData(obj, ['video', global_currentUser, user, meeting, sessionId, 'endcall'], callback);
}

// export function receiveCallStatus(obj, {meeting, user}, callback) {
//   receiveData(obj, ['video', global_currentUser, user, meeting, 'callstatus'], callback);
// }

export async function requestVideoCallWithUserAsync({meeting, user, sessionId}) {
  // await sendMessageAsync({user, meeting, text: 'started a video call', type: 'video-request', quiet: true});
  await setDataAsync(['video2', user, meeting, 'request', global_currentUser], {sessionId, time: SERVER_TIMESTAMP});
} 

export async function clearOutboundVideoCallRequestAsync({meeting, user}) {
  await setDataAsync(['video2', user, meeting, 'request', global_currentUser], null);
} 

export function watchVideoCallRequest(obj, {meeting}, callback) {
  watchData(obj, ['video2', global_currentUser, meeting, 'request'], callback, null);
}

export async function clearVideoCallRequestAsync({meeting, user}) {
  await setDataAsync(['video2', global_currentUser, meeting, 'request', user], null);
}

export async function saveErrorAsync(errorMsg, errorDetails = null) {
  const key = newKey('error');
  await setDataAsync(['private', global_currentUser, 'error', key], {msg: errorMsg, details: errorDetails, time: SERVER_TIMESTAMP});
}

export async function setCurrentVideoCall({meeting, user}) {
  console.log('setCurrentVideoCall', meeting, user);
  if (user) {
    await setDataAsync(['meeting', meeting, 'invideochat', global_currentUser], {time: SERVER_TIMESTAMP, user});
  } else {
    await setDataAsync(['meeting', meeting, 'invideochat', global_currentUser], null);
  }
}

export function watchCurrentVideoCalls(obj, meeting, callback) { 
  watchData(obj, ['meeting', meeting, 'invideochat'], callback)
}

export function watchUserCurrentVideoCall(obj, {meeting, user}, callback) { 
  watchData(obj, ['meeting', meeting, 'invideochat', user], callback, null)
}

export async function watchNotifToken(obj, callback) {
  watchData(obj, ['private', global_currentUser, 'notifToken'], callback, null);
}

export async function getNotifTokenAsync() {
  return await getDataAsync(['private',global_currentUser, 'notifToken']);
}

export async function setNotifTokenAsync(token) {
  await setDataAsync(['private', global_currentUser, 'notifToken'], token);
}

export async function markNotifsSkippedAsync() {
  await setDataAsync(['private', global_currentUser, 'notifSkipped'], true);
}

export async function submitAbuseReportAsync({meeting, user, authority, reason, details}) {
  if (authority == 'host') {
    const meetingInfo = await getDataAsync(['meeting', meeting, 'info']);
    const userInfo = await getDataAsync(['meeting', meeting, 'attendees', user]);
    await sendMessageAsync({meeting, user: meetingInfo.host, 
      text: 'Abuse Report: ' + reason + ' by ' + userInfo.name + ' - ' + details, type: 'abuse',
      extra: {abuseReport: {reason, details, user}}
    });
  } else {
    const key = newKey();
    await setDataAsync(['abuseReport', global_currentUser, key], {
      meeting, user, reason, details, time: SERVER_TIMESTAMP
    })
  }
}


var global_chatUser = null;
var global_chatMeeting = null;
