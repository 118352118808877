import React from 'react';
import { Linking } from 'expo';
import { StyleSheet, FlatList, Platform, SafeAreaView, Image, TouchableOpacity, Text, TextInput, View, ScrollView, InteractionManager } from 'react-native';
import { baseBackgroundColor, baseColor, appIcon, appName } from '../data/config';
import Svg, { Circle, Rect, Polygon, Polyline } from 'react-native-svg';
import { FontAwesome, Entypo, MaterialIcons, Ionicons, Feather } from '@expo/vector-icons';
import { global_currentUser } from '../data/data';
import _ from 'lodash';
import { getUrlForImage } from '../data/fbutil';
import { getTimeNow } from './shim';
import Constants from 'expo-constants';
import { Catcher } from './catcher';


export const secondMillis = 1000;
export const minuteMillis = secondMillis * 60;
export const hourMillis = minuteMillis * 60;
export const dayMillis = hourMillis * 24;

function twoDigit(num) {
  if (num < 10) {
    return '0' + num;
  } else {
    return num;
  }
}

function isToday({date, nowDate}) {
  return (
    (date.getMonth() == nowDate.getMonth()) && 
    (date.getDate() == nowDate.getDate()) &&
    (date.getFullYear() == nowDate.getFullYear()))
}

export function formatTime(time, options = {}) {
  const now = Date.now();
  const nowDate = new Date(now);
  const date = new Date(time);
  const minutes = twoDigit(date.getMinutes());
  if (!time) {
    return '';
  } else if ((now - time) < minuteMillis) {
    return 'just now';
  } else if (!isToday({date, nowDate})) {
    if (options.short) {
      return date.toLocaleDateString(undefined, {month: 'short', day: 'numeric'}) 
    } else {
      return (
        date.toLocaleDateString(undefined, {weekday: 'short', month: 'short', day: 'numeric'}) 
        + ', ' + 
        date.toLocaleTimeString(undefined, {hour: 'numeric', minute: 'numeric'})
      )
    }
  } else if (date.getHours() == 12) {
    return '12:'+minutes+'pm';
  } else if (date.getHours() == 0) {
    return '12:'+minutes+'am';
  } else if (date.getHours() > 12) {
    return (date.getHours()-12)+':'+minutes+'pm';
  } else {
    return date.getHours()+':'+minutes+'am';
  }
}

export function formatDate(time) {
  return new Date(time).toLocaleDateString(undefined, {weekday: 'short', month: 'short', day: 'numeric'});
}

export function formatTimeAgo(time) {
  const timeDiff = getTimeNow() - time;
  const minutes = timeDiff / minuteMillis;
  if (minutes <= 1) {
    return 'just now';
  } else if (minutes < 10) {
    return Math.floor(minutes) + ' minutes ago'
  } else {
    return ''
  }
}

export class FixedWebTouchable extends React.Component {
  state = {pressed: false}

  render() {
    const {title, onPress, children, inline = false} = this.props;

    const basicStyle = {cursor: 'pointer', display: inline ? 'inline' : 'flex', flexDirection: 'column'};
    const pressedStyle = {opacity: 0.5, cursor: 'pointer', display: inline ? 'inline' : 'flex', flexDirection: 'column'}

    return (
      <div style={this.state.pressed ? pressedStyle : basicStyle}
        title={title}
        onClick={e => {e.stopPropagation(); onPress()}}  
        onMouseDown={e => {e.stopPropagation(); this.setState({pressed: true})}}
        onMouseUp={() => {this.setState({pressed: false})}}
        onTouchStart={e => {e.stopPropagation(); this.setState({pressed: true})}}
        onTouchEnd={() => this.setState({pressed: false})}
        onTouchMove={() => this.setState({pressed: false})}
        >
        {children}
      </div>
    )
  }
}

var waiting = false;
function singleClick(callback) {
  return async () => {
    if (waiting) {
      console.log('ignoring duplicate click');
    } else {
      waiting = true;
      setTimeout(() => {
        waiting = false;
      }, 300)
      await callback()
      InteractionManager.runAfterInteractions(() => {
        waiting = false;  
      })
    }  
  }
}


export function FixedTouchable({onPress, onLongPress, activeOpacity, title, inline, style, children, dummy=false}) {
  if (Platform.OS == 'web') {
    if (onPress && !dummy) {
      return <FixedWebTouchable title={title} onPress={singleClick(onPress)} inline style={style}>{children}</FixedWebTouchable>
    } else {
      return <View style={style}>{children}</View>
    }
  } else {
    return <TouchableOpacity activeOpacity={activeOpacity} inline onLongPress={onLongPress} onPress={singleClick(onPress)}>{children}</TouchableOpacity>
  }
}

export function Link({title, url, children, inverted}) {
  if (Platform.OS == 'web') {
    return <a target='_blank' style={{color: inverted ? 'white' : 'rgb(29,161,242)'}} rel='noopener noreferrer' href={url}>{children}</a>
  } else {
    return (
      <Text style={{color: inverted ? 'white' : 'rgb(29,161,242)', textDecorationLine: inverted ? 'underline' : undefined}} onPress={()=>Linking.openURL(url)}>{children}</Text>
    )
  }
}

export function FormInput({autoFocus, onFocus, value, maxLength, textContentType, multiline=false, autoCompleteType, textAlign, placeholder, keyboardType, defaultValue, onChangeText, style}) {
  const textBoxStyle = {
    backgroundColor: 'white',
    padding: 8,
    borderColor: '#ddd',
    borderRadius: 8,
    borderWidth: 1,
    margin: 4,
    flex: 1,
    marginHorizontal: 16
  }
  if (Platform.OS == 'web') {
    return <TextInput placeholder={placeholder} style={style || textBoxStyle}
      defaultValue = {defaultValue} multiline={multiline}
      // textAlign = {textAlign}
      value = {value}
      maxLength = {maxLength}
      autoFocus={autoFocus}
      onChangeText={onChangeText}/>
  } else {
    return <TextInput placeholder={placeholder} style={style || textBoxStyle}
      textContentType={textContentType} multiline={multiline}
      keyboardType={keyboardType}
      autoCompleteType={autoCompleteType}
      underlineColorAndroid='transparent'
      textAlign = {textAlign}
      defaultValue = {defaultValue}
      value = {value}
      onFocus = {onFocus}
      onChangeText={onChangeText}/>
  }
}

export function ScreenContentScroll({children}) {
  return (
    <HeaderSpaceView>
      <View style={{flex: 1, backgroundColor: baseBackgroundColor}}>
        <ScrollView>        
          <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-around'}}>
            <View style={{maxWidth: 500, paddingBottom: 200, flex: 1, alignItems: 'stretch'}}>
              {children}
            </View>
          </View>
        </ScrollView>
      </View>
    </HeaderSpaceView>
  )
}

export function ToggleCheck({value, onValueChange, label, style}) {
  return (
    <FixedTouchable onPress={() => onValueChange(!value)}>
      <View style={{flexDirection: 'row', alignItems: 'center', marginLeft: 10, ...style}}>
        <FontAwesome name={value ? 'check-circle' : 'circle-thin'} size={20} color={value ? baseColor : '#666'} />
        <Text style={{marginLeft: 4, color: '#666'}}>{label}</Text>
      </View>
    </FixedTouchable>
  )
}

export function SmallButton({onPress, style, children}) {
  return (
    <View style={{flexDirection: 'row'}}>
      <FixedTouchable onPress={onPress}>
        <View style={{marginTop: 4, borderRadius: 8, backgroundColor: baseColor, paddingHorizontal: 8, paddingVertical: 4, ...style}}>
          <Text style={{color: 'white', textAlign: 'center', fontSize: 13}}>
            {children}
          </Text>
        </View>
      </FixedTouchable>
    </View>
  )
}

export function AlignedButton({onPress, children, disabled, style, align}) {
  return (
    <View style={{alignItems: align}}>
      <WideButton onPress={onPress} disabled={disabled} style={style}>
        {children}
      </WideButton>
    </View>
  )
}

export function WideButton({onPress, children, disabled, style}){
  if (disabled) {
    return (
      <View style={[{padding: 8, borderRadius: 8, backgroundColor: '#aaa', margin: 16, 
            alignItems: 'center'},style]}>
        <Text style={{color: 'white', fontWeight: 'bold', marginHorizontal: 12}}>
          {children}
        </Text>
      </View>
    )
  } else {
    return (
      <FixedTouchable onPress={onPress}>
        <View style={[{padding: 8, borderRadius: 8, backgroundColor: baseColor, margin: 16, 
              alignItems: 'center'},style]}>
          <Text style={{color: 'white', fontWeight: 'bold', marginHorizontal: 12}}>
            {children}
          </Text>
        </View>
      </FixedTouchable>
    )
  }
}

function hash(str) {
  var hash = 5381,
      i    = str.length;
  while(i) {
    hash = (hash * 33) ^ str.charCodeAt(--i);
  }
  return hash >>> 0;
}

export function nameColor({name}) {
  if (!name) {
    return 'grey'
  }
  const nameFloat = (hash(name) % 64) / 64.0 
  const hue = 360 * nameFloat
  const color = 'hsl('+Math.floor(hue)+',100%, 30%)';
  // console.log('nameColor', hue)

  return color;
}

export function DefaultImage({name, colorKey, size, style, marginRight=null, radiusFactor = 2}) {
  const firstLetter = name ? name[0] : '?';
  const color = nameColor({name: colorKey});
  // const nameFloat = (hash(colorKey) % 64) / 64.0 
  // const hue = 360 * nameFloat
  // const color = 'hsl('+hue+',100%, 32%)';
  return (
    <View style={[{width: size, height: size, borderRadius: size/radiusFactor, marginRight, backgroundColor: color, alignItems: 'center', justifyContent: 'center'},style]}>
      <Text style={{fontSize: size*0.6, color: 'white'}}>{firstLetter}</Text>
    </View>
  )
}

export function LogoHeader() {
  return (
    <View>
      <View style={{flexDirection: 'row', alignItems: 'flex-start', padding: 16, borderBottomColor: '#eee', borderBottomWidth: 1}}>
        <Image style={{resizeMode: 'contain', width: 60, height: 50, marginRight: 10, marginLeft: 8}} source={{uri:appIcon}} />
        <View style={{flex: 1}}>
          <Text style={{fontWeight: 'bold', fontSize: 24}}>
            {appName}
          </Text>
          <Text style={{fontSize: 15, color: '#666'}}>
            Scheduled Small-Group Chat
          </Text>
        </View>
      </View>
    </View>
  )
}

export function CenterScreenBox({children, padding=10}) {
  return (
    <ScreenContentScroll>
      <View style={{flexDirection: 'row'}}>
        <View style={{flex: 1, maxWidth: 500, padding, backgroundColor: 'white', 
              borderColor: '#eee', borderWidth: StyleSheet.hairlineWidth,
              borderRadius: 16,
              // borderWidth: StyleSheet.hairlineWidth,
              marginTop: 32, marginHorizontal: 8}} >
          {children}
        </View>
      </View>
    </ScreenContentScroll>
  )
}

const urlExpr = /((http:\/\/)|(https:\/\/))?[-a-zA-Z0-9.-]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/gi;
export const urlRegex = new RegExp(urlExpr);

function LinkTextLink({url, children, style}) {
  if (!isWeb) {
    return <Text style={{color: style.color, textDecorationLine: 'underline'}} onPress={()=>Linking.openURL(url)}>{children}</Text>
  } else {
    return <a target='_blank' style={{color: style.color, textDecoration: 'underline'}} href={url}>{children}</a>
  }
}

function trimUrl(url) {
  if (url.length > 40) {
    return url.slice(0,40)+'...'
  } else {
    return url;
  }
}

function addPrefixToUrl(url) {
  if (!_.startsWith(url,'http')) {
    return 'http://' + url;
  } else {
    return url;
  }
}

function removeTrailingPeriod(url) {
  if (url[url.length - 1] == '.') {
    return url.slice(0,-1);
  } else {
    return url;
  }
}

export function LinkText({text, style}) {
  if (!text) return null;
  const m = text.match(urlRegex);
  if (m && m.length > 0) {
    const url = m[0];
    const linkUrl = removeTrailingPeriod(addPrefixToUrl(url));
    const start = text.lastIndexOf(url);
    const before = text.slice(0,start);
    const after = text.slice(start + url.length);
    return (
      <Text style={style}>
        {before}
        <LinkTextLink style={style} url={linkUrl}>{trimUrl(url)}</LinkTextLink>
        <LinkText style={style} text={after} />
        {/* {after} */}
      </Text>
    )
  } else {
    return <Text style={style}>{text}</Text>
  }
}

export function Loading() {
  return <View style={{flex: 1, justifyContent: 'center'}}><Text style={{textAlign: 'center'}}>loading...</Text></View>
}

export function BottomArrow({style, color}){
  return (
    <Svg height={10} width={20} viewBox="0 0 20 10" style={style}>
      <Polygon
        points="0,0 5,5 10,0"
        fill={color}
      />
    </Svg>
  )
}

export function TopArrow({style, color}){
  return (
    <Svg height={10} width={10} viewBox="0 0 10 10" style={style}>
      <Polygon
        points="0,10 5,5 10,10"
        fill={color}
      />
    </Svg>
  )
}


export function BottomArrowLine({style, color, lineColor}){
  return (
    <Svg height={20} width={40} viewBox="0 0 40 20" style={style}>
      <Polygon
        points="0,0 10,10 20,0"
        fill={color}
      />
      <Polyline
        points="0,0 10,10 20,0"
        fill='white'
        stroke='#999'
        strokeWidth="1"
      />      
    </Svg>
  )
}

export function TopArrowLine({style, color, lineColor}){
  return (
    <Svg height={10} width={20} viewBox="0 0 20 10" style={style}>
      <Polygon
        points="0,10 10,0 20,10"
        fill={color}
      />
      <Polyline
        points="0,10 10,0 20,10"
        fill='white'
        stroke='#ccc'
        strokeWidth="1"
      />      
    </Svg>
  )
}


export function doesTextMatchPrefix(name, prefix) {
  if (!name) return false;
  return (' ' + name.toLowerCase()).indexOf(' ' + prefix.toLowerCase()) != -1;
}

const dataUrlRegex = /data:([a-zA-Z\-/]+);base64,(.*)/;
export function parseDataUrl(url) {
  // ANDROID hack - because Android aborts base64 on first line
  if (url.startsWith('data:image/jpeg;base64,')) {
    return {
      contentType: 'data:image/jpeg;base64,',
      base64data: url.slice(23)
    };
  }
  const m = url.match(dataUrlRegex);
  if (m) {
    return {
      contentType: m[1],
      base64data: m[2]
    }
  } else {
    return null;
  }
}

export function getFileExtension(contentType) {
  if (contentType == 'image/jpeg') {
    return '.jpg'
  } else if (contentType == 'image/png') {
    return '.png'
  } else if (contentType.slice(0,6) == 'image/'){
    return '.' + contentType.slice(6);
  } else {
    return '';
  }
}

const sizeFactor = 24.0/40;
export function ConIcon({size, conInfo, style}) {
  const isMember = _.get(conInfo, ['members', global_currentUser], false);
  const memberCount = Object.keys(_.get(conInfo,'members',{})).length;
  // const isMember = false;
  return (
    <View style={{...style, backgroundColor: isMember ? '#0084ff' : '#ddd', borderRadius: 8, width: size, height: size, alignItems: 'center', justifyContent: 'center'}}>
      <FontAwesome name='group' size={size * sizeFactor} color='white'/>
      <View style={{width: size * 0.51, height: size * 0.51, position: 'absolute', bottom: 1, right: size/4,
            justifyContent: 'center', alignItems: 'center',
            backgroundColor: 'white', borderColor: isMember ? '#0084ff' : '#ddd', borderWidth: 1,
            borderRadius: size/2}}>
        <Text style={{color: isMember ? '#0084ff' : '#999', fontSize: 12}}>{memberCount}</Text>
      </View>

    </View>
  )
}

export function MyBoardIcon({userInfo, size = 40}) {
  return (
    <View style={{width: size, height: size}}>
      <UserIcon userInfo={userInfo} size={size} />
      {/* <DefaultImage name={global_currentUserName} colorKey={global_currentUserName} size={size*0.9} /> */}
      {Platform.OS == 'web' ?
        <View style={{position: 'absolute', bottom: -(size/10), right: -(size/10), height: size * 0.4, 
            width: size*0.5,
            padding: size / 20, backgroundColor: 'white', borderRadius: size/8, 
            alignItems: 'center', justifyContent: 'center',
            borderColor: '#999', borderWidth: StyleSheet.hairlineWidth}} >
          <Ionicons name='ios-radio' size={size*0.4} color='#666' />
        </View>  
      :
        <View style={{position: 'absolute', bottom: -(size/10), right: -(size/10), height: size * 0.38, 
            width: size*0.5,
            padding: size / 20, backgroundColor: 'white', borderRadius: size/8, 
            // alignItems: 'center', justifyContent: 'center',
            borderColor: '#999', borderWidth: StyleSheet.hairlineWidth}} >
          <Ionicons name='ios-radio' size={size*0.4} color='#666'
            // style={{marginTop: -(size/10)}} 
            style={{position: 'absolute', top: -(size/25), right: (size/20), 
            // padding: 1, backgroundColor: 'white', borderRadius: size/4,
            // borderColor: '#999', borderWidth: StyleSheet.hairlineWidth
          }}
          />
          </View>
      }
    </View>
  )
}

export function VideoCallIcon({userInfo, size = 40}) {
  return (
    <View style={{width: size, height: size}}>
      <UserIcon userInfo={userInfo} size={size} />
      {/* <DefaultImage name={global_currentUserName} colorKey={global_currentUserName} size={size*0.9} /> */}
      <FontAwesome name='video-camera' size={size*0.5} color='#6AE91A' 
        style={{position: 'absolute', bottom: -(size/10), right: -(size/10), 
          paddingHorizontal: 4, backgroundColor: 'white', borderRadius: size/8, 
          borderColor: '#ddd', borderWidth: StyleSheet.hairlineWidth
        }}
      />
    </View>
  )
}

export var isWeb = Platform.OS == 'web';
export function testSetIsWeb(newIsWeb){
  isWeb = newIsWeb;
}

export function UserIcon({userInfo, thumb=true, style, size = 40, marginRight = null}) {
  const uri = getUrlForImage(thumb ? userInfo.thumb : userInfo.photo);
  // Image.prefetch(uri);
  if (userInfo.thumb) {
    return (
      <Image source={{uri, cache: 'force-cache'}} 
          style={[{width: size, height: size, borderRadius: size / 2, marginRight},style]} />
    )
  } else {
    return (
      <DefaultImage name={userInfo.name} style={style} colorKey={userInfo.name} 
        marginRight={marginRight} size={size} 
        radiusFactor={userInfo.host ? 8 : 2} />
    )
  }
}

export function DateTimeText({date, boldToday = false, repeat, style}) {
  const dDate = new Date(date);
  const dNow = new Date();
  var dateString;
  if (dDate.getFullYear() == dDate.getFullYear() && dDate.getDate() == dNow.getDate() && dDate.getMonth() == dNow.getMonth()) {
    dateString = <Text style={{fontWeight: boldToday ? 'bold' : undefined}}>Today</Text>;
  } else {
    dateString = dDate.toLocaleDateString(undefined, {weekday: 'short', month: 'short', day: 'numeric'});
  }
  const timeString = dDate.toLocaleTimeString(undefined, {hour: 'numeric', minute: 'numeric'});
  const repeatString = getRepeatString(repeat);
  return (
    <Text style={style}>
      {repeatString ? 
        repeatString
      : null}
      {repeatString ?
        <Entypo name='dot-single' />
      : null}
      {dateString}<Entypo name='dot-single' />{timeString}
    </Text>
  )
}

export function findMember({members = {}, email}) {
  return _.find(Object.keys(members), m => _.get(members,[m,'email'],'').toLowerCase().trim() == email.toLowerCase().trim());
}

export class HoverLink extends React.Component {
  state = {};

  render() {
    const {onPress, text, style} = this.props;
    const {hover} = this.state;

    return (
      <FixedTouchable onPress={onPress} inline>
        <Text style={[{textDecorationLine: hover ? 'underline' : undefined}, style]}
          onMouseOver={() => this.setState({hover: true})}
          onMouseLeave={() => this.setState({hover: false})}
        > 
          {text}
        </Text>
      </FixedTouchable>
    )
  }
}

export class HoverView extends React.Component {
  state = {};

  render() {
    const {children, style, hoverStyle} = this.props;
    const {hover} = this.state;

    return (
      <View style={[style, hover ? hoverStyle : null]}
        onMouseOver={() => this.setState({hover: true})}
        onMouseLeave={() => this.setState({hover: false})}
      >
        {children}
      </View>
    )
  }
}


export function IconForUser({user, conInfo=null, userInfo, size=40, showSelf=false}) {
  if (user == 'broadcast') {
    return <Ionicons name='ios-radio' size={size} style={{width: size, textAlign: 'center'}} color='black' />
  // } else if (user == global_currentUser && !showSelf) {
  //   return <MyBoardIcon userInfo={userInfo} size={size} />
  } else if (conInfo) {
    return <ConIcon size={size} conInfo={conInfo} />
    // return <Entypo name='chat' size={size * (0.75)} style={{width: size, textAlign: 'center'}} color='#999' />
  } else {
    const name = _.get(userInfo,'name','User');
    return <UserIcon userInfo={userInfo} size={size} />
    // return <DefaultImage colorKey={name} name={name} size={size} />
  }
}

export function objToJSON(obj) {
  if (obj.toJSON) {
    return obj.toJSON(); 
  } else if (typeof obj == 'object') {
    return obj;
  } else {
    throw new Error('object cannot be converted to JSON');
  }
}

export function getRepeatString(repeat) {
  if (repeat) {
    return repeat[0].toUpperCase() + repeat.slice(1);
  } else {
    return null
  }
}

export function HeaderSpaceView({children, style={}}) {
  if (Platform.OS == 'web') {
    return <View style={[style, {flex: 1}]}>{children}</View>
  } else {
    return (
      <SafeAreaView style={{backgroundColor: 'white', ...style, flex: 1, paddingTop: Constants.statusBarHeight}}>
        {children}
      </SafeAreaView>
    )
  }
}

export function commaSepStrings(strings) {
  const length = strings.length;
  return strings.map((str,i) => 
    (i == 0 ? '' : (i == length - 1 ? (length == 2 ? ' and ' : ', and ') : ', '))
    + str
  )  
}

 
export function BackButton({navigation}) {
  if (Platform.OS == 'web') {
    return null;
  } else {
    return (
      <FixedTouchable onPress={() => navigation.goBack()}>
        {/* <FontAwesome name='angle-left' size={30} color={baseColor} /> */}
        <Entypo name='chevron-thin-left' size={24} color={baseColor} style={{marginTop: 2, marginLeft: 4, marginRight: 4}} />       
      </FixedTouchable>
    )
  }
}

export function getNameOrYou({user, attendees}) {
  if (user == global_currentUser) {
    return 'You'
  } else {
    return _.get(attendees, [user, 'name'], 'Someone');
  }
}

export function getNameOrYour({user, attendees}) {
  if (user == global_currentUser) {
    return 'Your'
  } else {
    return _.get(attendees, [user, 'name'], 'Someone') + "'s";
  }
}

export function getRoomName({user, attendees}) {
  if (user == global_currentUser) {
    return 'Your Room'
  } else if (_.get(attendees, [user, 'room'])) {
    return _.get(attendees, [user, 'name'], 'Someone');
  } else {
    return _.get(attendees, [user, 'name'], 'Someone') + "'s Room";
  }
}

export function FlatScroller({data}) {
  return (
    <FlatList data={data} renderItem={({item: {key, item, value}}) =>
      <Catcher key={key}>{item || value()}</Catcher> 
    } />
  )
}

export function getDiffs(oldObj, newObj) {
  var diff = [];
  Object.keys({...oldObj, ...newObj}).map(k => {
    if (oldObj[k] != newObj[k]) {
      diff.push(k)
    }
  })
  return diff;
}

export function ClockIcon({size, follow}) {
  return (
    <View style={{width: size, height: size, alignItems: 'center', justifyContent: 'center',
          backgroundColor: '#f5f5f5', borderRadius: size / 6}}>
      {follow ? 
        <FontAwesome name='bell' size={size*0.6} color='#666' /> 
      : 
        <Feather name='clock' size={size*0.6} color='#666' />    
      }
      {/* {follow ? 
        <FontAwesome name='bell' size={size*0.3} color='#666' 
          style={{position: 'absolute', right:size*0.02, bottom: size*0.05}} />
      : null} */}
    </View>
  )
}

export function GroupIcon({size}) {
  return (
    <View style={{width: size, height: size, alignItems: 'center', justifyContent: 'center',
          backgroundColor: '#f5f5f5', borderRadius: size / 6}}>
      <FontAwesome name='group' size={size*0.6} color='#666' />    
    </View>
  )
}

export function PeopleList({users, attendees, style}) {
  const length = users.length;
  return (
    <Text style={style}>
      {users.map((u,i) => 
        <Text key={'like-' + u}>
          {(i == 0 ? '' : (i == length - 1 ? (length == 2 ? ' and ' : ', and ') : ', '))
          + getNameOrYou({attendees, user: u})}
        </Text>
      )} 
    </Text>
  )
}

export function getMessagePrivateTo({user, userInfo, replyToMessageInfo, isPublic}) {
  if (isPublic) {
    return null; 
  } else if (replyToMessageInfo) {
    if (replyToMessageInfo.from == global_currentUser) {
      return replyToMessageInfo.privateTo;
    } else {
      return replyToMessageInfo.from;
    }
  } else if (userInfo && userInfo.host) {
    return userInfo.host;
  } else {
    return user;
  }
}

export function isMessageVisible({messageInfo, attendees, expireTime}) {
  const roomInfo = _.get(attendees, messageInfo.to, {});
  if (messageInfo.time < expireTime) {
    return false;
  } else if (messageInfo.isDeleted) {
    return false;
  } else if (messageInfo.from == global_currentUser) {
    return true;
  } else if (roomInfo.room && !roomInfo.isPublic && !_.get(roomInfo, ['members', global_currentUser])) {
    return false;
  } else if (messageInfo.isBoard) {
    if (messageInfo.from == messageInfo.to) {
      return true;
    } else if (messageInfo.from == _.get(attendees, [messageInfo.to, 'host'])) {
      return true;
    } else if (messageInfo.to == global_currentUser) {
      return true;
    } else if (_.get(attendees, [messageInfo.to,'host']) == global_currentUser) {
      return true;
    } else if (messageInfo.approved) {
      return true;
    } else {
      return false;
    }
  } else if (messageInfo.from == global_currentUser) {
    return true;
  } else if (messageInfo.privateTo == global_currentUser) {
    return true;   
  } else {
    return false;
  }
}

export function shouldMessageReplyBePublic({messageInfo, user}) {
  if (!messageInfo.isPublic) {
    return false;
  } else if (messageInfo.approved) {
    return true;
  } else if (messageInfo.from == global_currentUser) {
    return true;
  } else if (messageInfo.from == user) {
    return true;
  } else {
    return false;
  }
}
