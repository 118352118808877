import React from 'react';
import {View, Text, StyleSheet} from 'react-native';
import { WideButton, FixedTouchable } from './basics';
import { watchHelpClearings, clearHelpMessage, restoreHelpMessage } from '../data/data';
import _ from 'lodash';
import { Entypo } from '@expo/vector-icons';
import { internalReleaseWatchers } from '../data/fbutil';


export class HelpBox extends React.Component {
  state = {};

  componentDidMount() {
    watchHelpClearings(this, helpClearings => this.setState({helpClearings}));
  }
  componentWillUnmount() {
    internalReleaseWatchers(this);
  }

  render() {
    const {children, title, id} = this.props;
    const {helpClearings} = this.state;

    if (!helpClearings || helpClearings[id]) {
      return (
        // <FixedTouchable onPress={()=>restoreHelpMessage(id)}>
          <View style={{
              backgroundColor: 'white', maxWidth: 500, alignSelf: 'center', 
              paddingHorizontal: 16, paddingVertical: 12, margin: 16, 
              borderColor: '#ddd', borderWidth: StyleSheet.hairlineWidth, borderRadius: 16}}>
            <FixedTouchable onPress={()=>restoreHelpMessage(id)}>
              <View style={{flexDirection: 'row'}}>
                <Text style={{fontSize: 14, fontWeight: '700', marginRight: 8}}>{title}</Text>
                <Entypo name='chevron-down' size={16} color='#666' />
              </View>
            </FixedTouchable>
          </View>
        // </FixedTouchable>
      )
    } else {
      return (
        <View style={{backgroundColor: 'white', maxWidth: 500, alignSelf: 'center', 
            paddingHorizontal: 16, paddingVertical: 12, margin: 16, 
            shadowOpacity: 0.5, shadowRadius: 4, shadowColor: '#555', shadowOffset: {width: 0, height: 2},
            borderColor: '#ddd', borderWidth: StyleSheet.hairlineWidth, borderRadius: 16}}>
          <Text style={{fontSize: 16, fontWeight: '600', marginBottom: 8}}>{title}</Text>
          {children}

          <View style={{flexDirection: 'row', justifyContent: 'flex-end'}}>
            <WideButton style={{margin: 0}}
              onPress={()=>clearHelpMessage(id)}
            >
              Got it
            </WideButton>
          </View>
        </View>
      )
    }
  }
}
