import React from 'react';
import {View, ScrollView, Text, Image, StyleSheet, Linking} from 'react-native';
import { LogoHeader, FixedTouchable } from '../components/basics';
import { appName, appIcon, baseColor } from '../data/config';
import { setTitle } from '../components/shim';

function Header() {
  return (
    <View>
      <View style={{flexDirection: 'row', alignItems: 'center', padding: 16, borderBottomColor: '#eee', borderBottomWidth: 1}}>
        <Image style={{width: 40, height: 40, marginRight: 10, marginLeft: 8}} source={{uri:appIcon}} />
        <View>
          <Text style={{fontWeight: '500', fontSize: 30}}>
            {appName}
          </Text>
        </View>
      </View>
    </View>
  )
}

function NarrowColumn({children}) {
  return (
    <View style={{flexDirection: 'row', justifyContent: 'center', paddingHorizontal: 16}}>
      <View style={{flex: 1, maxWidth: 500, marginTop: 16}}>
        {children}
      </View>
    </View>
  )
}


function Footer() {
  return null;
}

function BodyChunk({children}) {
  return (
    <View style={{marginTop: 16}}>
      <Text style={{color: '#666', fontSize: 16, lineHeight: 20}}>
        {children}
      </Text>
    </View>
  )
}

export function IntroScreen() {
  setTitle('ChillChat: Scheduled 1:1 Group Chat');
  return (
    <View style={{flex: 1}}>
      <Header /> 
      <ScrollView>    
        <NarrowColumn>
          <Text style={styles.topHead}>Bring People Together</Text>
          <BodyChunk>
            Building relationships is easy when people are in the same space.
            You can see who is around, see who is free to talk, overhear conversations you
            might want to join, and easily start private conversations. 
          </BodyChunk>
          <BodyChunk>
            Chillchat does all that at a distance.
          </BodyChunk>
          <BodyChunk>
            To find out more, and get early access, email <a target='_blank' rel='noreferrer' href='mailto:info@chillchat.org'>info@chillchat.org</a>.
          </BodyChunk>
        </NarrowColumn>
        <Footer />
      </ScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  topHead: {
    fontSize: 40,
    marginTop: 32
  }
})
