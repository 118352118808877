import React from 'react';
import {Text, View, ScrollView, StyleSheet, Platform} from 'react-native';
import { ScreenContentScroll, WideButton, Loading, findMember, Link, HeaderSpaceView, isWeb, BackButton, ToggleCheck, hourMillis, ClockIcon, GroupIcon, DateTimeText, AlignedButton, FixedTouchable } from '../components/basics';
import { joinMeeting, watchMeetingInfo, watchMeetingMembers, watchMyEmail, setUserPhoto, setUserIntroductionAsync, global_currentUser, watchMyMeetings, watchMyMeeting, followMeetingAsync, removeMeetingAsync, addMeetingAsync } from '../data/data';
import { HelpBox } from '../components/help';
import { ShareBox, getTimeNow, CalendarButton } from '../components/shim';
import { getFocusDate } from '../components/dates';
import { internalReleaseWatchers } from '../data/fbutil';
import { Entypo } from '@expo/vector-icons';


export class JoinChat extends React.Component {
  state = {meetingInfo: null}

  componentDidMount() {
    const {meeting} = this.props;
    watchMeetingInfo(this, meeting, meetingInfo => this.setState({meetingInfo}));
    watchMeetingMembers(this, meeting, members => this.setState({members}));
    watchMyMeeting(this, meeting, myMeetingInfo => this.setState({myMeetingInfo}));
    watchMyEmail(this, email => this.setState({email}));
  }

  componentWillUnmount() {
    internalReleaseWatchers(this);
  }

  async joinGathering() {
    const {myName, meeting, navigation} = this.props;
    const {email, members, meetingInfo, myMeetings} = this.state;
    const meMember = findMember({members, email});
    const meMemberInfo = members[meMember];

    var photo = null;
    var thumb = null;
    var intro = null;

    // var pPhoto = null; 
    var pIntro = null;

    if (meMember && meMemberInfo.photo && meMemberInfo.thumb) {
      photo = meMemberInfo.photo;
      thumb = meMemberInfo.thumb;
      // pPhoto = setUserPhoto({meeting, user: global_currentUser, photo: meMemberInfo.photo, thumb: meMemberInfo.thumb});
    }
    if (meMember && meMemberInfo.intro) {
      pIntro = setUserIntroductionAsync({meeting, user: global_currentUser, text: meMemberInfo.intro});
    }

    await pIntro; 
    await joinMeeting({meeting, myName, photo, thumb, meetingInfo, isHost: meetingInfo.host == global_currentUser});
  }

  render() {
    const {myName, meeting, members, navigation} = this.props;
    const {meetingInfo, myMeetingInfo} = this.state;
    var active = false;

    if (!meetingInfo || !myMeetingInfo) {
      return <Loading />
    }

    const focusDate = getFocusDate(meetingInfo);
    if (focusDate < getTimeNow() && focusDate > (getTimeNow() - (4 * hourMillis))) {
      active = true;
    }

    // const myMeeting = myMeetings[meeting];

    const url = 'https://talkbeat.com/gather/' + meeting;

    return (
      <HeaderSpaceView style={{backgroundColor: '#fafafa'}}>        
        <View style={{flexDirection: 'row', backgroundColor: 'white', justifyContent: isWeb ? 'space-between' : 'flex-start',
            borderBottomColor: '#ddd', borderBottomWidth: StyleSheet.hairlineWidth}}>
          <BackButton navigation={navigation} />
          <View style={{flex: 1, paddingBottom: 8, paddingTop: isWeb ? 8 : 2, alignItems: isWeb ? 'center' : null}}>
            <Text style={{fontSize: 20, fontWeight: '700', marginHorizontal: 10}}>Join Gathering</Text>
          </View>
          <View />
        </View>

        <ScrollView>
          {/* {meetingInfo.host == global_currentUser ?
            <HelpBox id='invite-join' title='Invite People by Sharing the Link'>
              <Text style={{fontSize: 15, color: '#666', marginBottom: 8}}>
                You can invite new people to join your gathering by sharing it's unique link with them:
              </Text>
              <Text style={{fontSize: 15, color: '#666', marginBottom: 8}}>
                <Link url={url}>{url}</Link>
              </Text>
            </HelpBox>
          : null } */}
          <View style={{flexDirection: 'row', justifyContent: 'center', alignSelf: 'center', marginBottom: 16}}>        
            <View style={{flex: 1, maxWidth: 400, padding: 10, borderRadius: 16, 
                  shadowOpacity: 0.5, shadowRadius: 4, shadowColor: '#555', shadowOffset: {width: 0, height: 2},
                  backgroundColor: 'white', borderColor: '#eee', 
                  borderWidth: StyleSheet.hairlineWidth, marginVertical: 16, marginHorizontal: 8}}>
              <View style={{flexDirection: 'row', marginLeft: 16, marginTop: 8}}>
                {active ? <GroupIcon size={60}/> : <ClockIcon size={60} follow={myMeetingInfo.follow} />}
                <View style={{marginHorizontal: 10, flex: 1}}>
                  <Text style={{fontSize: 24}}>{meetingInfo.name || 'Meeting'}</Text>
                  <DateTimeText date={focusDate} repeat={meetingInfo.repeat} style={{marginVertical: 4}} />
                  <Text style={{marginTop: 4, color: '#222'}}>
                    {meetingInfo.summary}
                  </Text>
                </View>
              </View>
              <WideButton part='join' onPress={() => this.joinGathering()}>Join Gathering</WideButton>
            </View>

            {/* <View style={{flexDirection: 'row', justifyContent: 'center'}}>
              <ToggleCheck style={{alignSelf: 'center', marginLeft: 0, marginBottom: 32}} 
                value={myMeetingInfo.follow} onValueChange={follow => followMeetingAsync({meeting, follow})} 
                label='Notify Me When Gathering Starts' />
            </View> */}
            {/* <WideButton>Remove from my Meetings</WideButton> */}
          </View>

          <CalendarButton meeting={meeting} meetingInfo={meetingInfo} />

          {Platform.OS != 'web' ? 
            <View style={{marginTop: 32}}>
              <ShareBox meeting={meeting} meetingInfo={meetingInfo} />
            </View>
          : null}


          <View style={{flexDirection: 'row', justifyContent: 'center', marginTop: 32}}>
            {myMeetingInfo.meetingInfo ?
              (meetingInfo.host != global_currentUser ? 
                <RemoveButton meeting={meeting} />
              : null)
            :
              <AddButton meeting={meeting} meetingInfo={meetingInfo} />
            }
          </View>


        </ScrollView>
      </HeaderSpaceView>
    )
  }
}

function AddButton({meeting, meetingInfo, navigation}) {
  return (
    <FixedTouchable onPress={()=>{
        addMeetingAsync({meeting, meetingInfo});
      }}>
      <View style={{margin: 0, paddingVertical: 8, paddingHorizontal: 12,
          backgroundColor: 'white',
          // borderRadius: 4,
          flexDirection: 'row', alignItems: 'center',
          borderColor: '#ddd', borderWidth: StyleSheet.hairlineWidth
          }}>
        <Entypo name='plus' size={20} style={{marginRight: 8}} />
        <Text style={{fontSize: 16}}>Add to My Gatherings</Text>
      </View>
    </FixedTouchable>
  )
}

function RemoveButton({meeting, meetingInfo, navigation}) {
  return (
    <View style={{marginTop: 32}}>
      <FixedTouchable onPress={()=>{
          removeMeetingAsync({meeting, meetingInfo});
        }}>
        <View style={{margin: 0, paddingVertical: 8, paddingHorizontal: 12,
            // backgroundColor: '#f5f5f5',
            // borderRadius: 4,
            backgroundColor: 'white',
            flexDirection: 'row', alignItems: 'center',
            borderColor: '#ddd', borderWidth: StyleSheet.hairlineWidth
            }}>
          <Entypo name='cross' size={20} style={{marginRight: 8}} />
          <Text style={{fontSize: 16}}>Remove From My Gatherings</Text>
        </View>
      </FixedTouchable>
    </View>
  )
}

