import { firebaseApp} from './config';
// import { Updates } from 'expo';
import * as Updates from 'expo-updates';
import { Platform } from 'react-native';

/* global window */

const thisVersion = 131;
var latestVersion;
var forceReloadVersion;

firebaseApp.database().ref('/special/versioncheck/sdk_37').on('value', (snap) => {
  latestVersion = snap.val();
  console.log('version: ' + latestVersion);
})

firebaseApp.database().ref('/special/versioncheck/force_sdk_37').on('value', (snap) => {
  forceReloadVersion = snap.val();
  if (forceReloadVersion && forceReloadVersion > thisVersion) {
    forceReload();
  }
})

export function forceReload() {
  if (Platform.OS != 'web') {
    console.log('reloading');
    expoReload();
  } else {
    console.log('reloading web');
    window.location.reload(true);
  }
}


export async function expoReload() {
  console.log('checking for new expo update');
  const newUpdate = await Updates.checkForUpdateAsync();
  if (newUpdate) {
    console.log('new update available');
    const {isNew} = await Updates.fetchUpdateAsync();
    if (isNew) {
      console.log('reloading');
      await Updates.reloadAsync();
    } else {
      console.log('loaded update is not new');
    }
  } else {
    console.log('no new update available');
  }
}



export async function reloadIfVersionChanged() {
  console.log('reloadIfVersionChanged');
  // console.log('reloadIfVersionChaned', {latestVersion, thisVersion});
  if (latestVersion && latestVersion > thisVersion) {
    console.log('version did change', latestVersion, thisVersion);
    forceReload();
  } else {
    console.log('version did not change');
  }
}
