import React from 'react';
import {Text, View, Switch, StyleSheet, ScrollView} from 'react-native';
import { FormInput, WideButton, HeaderSpaceView, BackButton, Loading, getNameOrYour, dayMillis, getNameOrYou, UserIcon, FixedTouchable, isWeb } from '../components/basics';
import { newConversationAsync, sendMessageAsync, copyMessageToBoardAsync, newRoomAsync, watchMeetingAttendees, watchPresence, global_currentUser, editRoomAsync, watchMeetingInfo } from '../data/data';
import { PublicToggle, ModeratedToggle } from '../components/boardtoggle';
import { KeyboardSafeView } from '../components/keyboardsafeview.';
import { getTimeNow } from '../components/shim';
import { FontAwesome } from '@expo/vector-icons';
import { baseColor } from '../data/config';
import { MeetingInfo } from './MeetingInfo';
import _ from 'lodash';
import { internalReleaseWatchers } from '../data/fbutil';
import { HelpBox } from '../components/help';

function TogglePerson({attendees, user, toggled, self=false, onToggle}) {
  return (
    <FixedTouchable onPress={onToggle}>
      <View style={{opacity: self ? 0.5 : null, marginHorizontal: 16, marginVertical: 4, flexDirection: 'row', alignItems: 'center'}}>
        {toggled ? 
          <FontAwesome name='check-circle' size={40} color={baseColor} 
            style={{textAlign: 'center', width: 40, height: 40}} />
        : 
          <UserIcon userInfo={attendees[user]} size={40} />
        }
        <Text style={{fontSize: 16, fontWeight: toggled ? '700' : '300', marginLeft: 8, 
            color: 'black'}}>{getNameOrYou({user, attendees})}</Text> 
      </View>
    </FixedTouchable>
  )
}

class MemberSelector extends React.Component {
  state = {};
  async componentDidMount() {
    const {meeting} = this.props;
    watchMeetingAttendees(this, meeting, attendees => this.setState({attendees}));
    watchPresence(this, meeting, presence => this.setState({presence}));
  }
  async componentWillUnmount() {
    internalReleaseWatchers(this);
  }
  render() {
    const {members, onSetMembers} = this.props;
    const {attendees, presence} = this.state;
    if (!attendees || !presence) { 
      return <Loading />;
    }
    const dayAgo = getTimeNow() - dayMillis;

    const people = _.filter(Object.keys(attendees), u =>
      u != global_currentUser 
      // && presence[u] > dayAgo
      && !attendees[u].room && attendees[u].present
      && _.get(attendees, [u, 'present']));

    const sortedPeople = _.sortBy(people, u => _.get(attendees,[u,'name'],'').toLowerCase());
    return (
      <ScrollView>
        <TogglePerson attendees={attendees} user={global_currentUser} toggled self />
        {sortedPeople.map(u =>  
          <TogglePerson key={u} part={u} attendees={attendees} user={u} toggled={members[u]} 
            onToggle={() => onSetMembers({...members, [u]: !members[u]})} />
        )}
      </ScrollView>
    )
  }
}

function ConversationInfo({name, isPublic, members, attendees}) {
  const sortedMembers = _.sortBy(Object.keys(members || {}), m => attendees[m].name);
  return (
    <View style={{flex: 1}}>
      <Text style={{fontSize: 20, margin: 16}}>{name}</Text>
      {isPublic ? 
        <Text style={{color: '#666', margin: 16}}>Everyone is Invited</Text>
      :
        <ScrollView>
          <Text style={{color: '#666', margin: 16}}>Members:</Text>
          {sortedMembers.map(m => 
            <TogglePerson key={m} attendees={attendees} user={m} toggled />
          )}
        </ScrollView>
      }
    </View>
  ) 
}

export class NewConversation extends React.Component {
  state = {name: null, isPublic: false, isModerated: true, members: {}}
  componentDidMount() {
    const {meeting, room} = this.props;
    if (room) {
      watchMeetingInfo(this, meeting, meetingInfo => this.setState({meetingInfo}));      
      watchMeetingAttendees(this, meeting, attendees => {
        const roomInfo = _.get(attendees, room);
        this.setState({attendees, roomInfo, isModerated: roomInfo.isModerated, name: roomInfo.name, isPublic: roomInfo.isPublic, members: roomInfo.members});
      });
    }
  }
  componentWillUnmount() {
    internalReleaseWatchers(this);
  }
  async createConversation() {
    const {meeting, room, onGotoUser, navigation} = this.props;
    const {name, intro, isPublic, isModerated, members, roomInfo} = this.state;
    var newRoom;
    if (room) { 
      newRoom = room;
      await editRoomAsync({room, meeting, name, host: roomInfo.host, members, isPublic, isModerated});
      if (isWeb) {
        onGotoUser({user:newRoom});
      } else {
        navigation.goBack();
      }
    } else {
      newRoom = await newRoomAsync({meeting, name, members, isModerated, isPublic});
      if (isWeb) {
        onGotoUser({user:newRoom});
      } else {
        navigation.goBack();
      }
    }
    // const {message, messageInfo} = await sendMessageAsync({meeting, conInfo, user: conversation, text: intro});
    // await copyMessageToBoardAsync({meeting, user:conversation, conInfo, message, messageInfo});
  }
  render() {
    const {meeting, room, navigation} = this.props;
    const {name, intro, members, isPublic, isModerated, roomInfo, attendees, meetingInfo} = this.state;

    if (room && (!attendees || !meetingInfo)) {
      return null;
    }

    if (room && meetingInfo.host != global_currentUser && roomInfo.host != global_currentUser) {
      return <ConversationInfo attendees={attendees} isPublic={isPublic} name={name} members={members} />
    }

    const realMembers = Object.keys({...members, [global_currentUser]: true}).filter(u => members[u] || u == global_currentUser);
    const justYou = !isPublic && realMembers.length <= 1; 
    
    // console.log('members', {attendees, realMembers, justYou, members, isPublic});

    return (
      <View style={{flex: 1, flexDirection: 'column'}}>
        <View style={{flexDirection: 'row', padding: 4,
           borderBottomColor: '#ddd', borderBottomWidth: StyleSheet.hairlineWidth
          }}>
          <BackButton navigation={navigation} />
          <Text style={{fontSize: 20, marginLeft: 4}}>
            {room ? 'Edit Room' : 'Create a New Room'}
          </Text>
        </View>

        <FormInput part='name' placeholder='What is your room called?'
          maxLength={40} value={name || ''}
          style={styles.nameBox} autoFocus
          onChangeText={name=>this.setState({name})} /> 
        {/* <View style={{marginHorizontal: 16, marginTop: 16, flexDirection: 'row', alignItems: 'center'}}>
          <Switch value={isPublic} onValueChange={isPublic => this.setState({isPublic})} />
          <Text style={{marginLeft: 8, fontSize: 16, color: isPublic ? '#222' : '#666'}}>Visible to Everyone</Text>
        </View> */}
        <ModeratedToggle part='moderated' style={{marginHorizontal: 16, marginTop: 16}} toggled={isModerated} onToggle={isModerated => this.setState({isModerated})} />
        <PublicToggle part='public' style={{margin: 16}} toggled={isPublic} onToggle={isPublic => this.setState({isPublic})} />
        {!isPublic ? 
          <MemberSelector members={members} meeting={meeting} onSetMembers={members => this.setState({members})} />
        : null}

        {justYou ?
          <MustHaveMembers />
        : null}

        <WideButton part='start' disabled={!name || justYou} onPress={()=>this.createConversation()}>
          {room ? 'Update Room' : 'Create Room'}
        </WideButton>
      </View>
    )
  }
}

function MustHaveMembers() {
  return (
    <HelpBox id='nomembers' title='Invite Someone to This Room' >
      <Text style={{fontSize: 15, color: '#666'}}>
        This room is currently only visible to you, which isn't very useful.
      </Text>
      <Text style={{fontSize: 15, color: '#666', marginVertical: 8}}>
        Either invite other people to this room, or make it visible to everyone.
      </Text>
    </HelpBox>

  )
}

const styles = StyleSheet.create({
  introBox: {
    backgroundColor: 'white',
    padding: 8,
    borderColor: '#ddd',
    borderRadius: 8,
    borderWidth: 1,
    // marginHorizontal: 4,
    marginTop: 16,
    marginHorizontal: 16,
    height: 100
  },
  nameBox: {
    backgroundColor: 'white',
    padding: 8,
    borderColor: '#ddd',
    borderRadius: 8,
    borderWidth: 1,
    // marginHorizontal: 4,
    marginTop: 16,
    marginHorizontal: 16,
    fontSize: 20
  }

})
