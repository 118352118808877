import React from 'react';
import {View, Text, ScrollView, StyleSheet} from 'react-native';
import { watchMeetingInfo, global_currentUser, leaveMeetingAsync, updateMeetingAsync } from '../data/data';
import { FormInput, FixedTouchable, WideButton, DateTimeText, dayMillis, HeaderSpaceView, BackButton, isWeb } from '../components/basics';
import { Entypo, Ionicons } from '@expo/vector-icons';
import { baseColor } from '../data/config';
import { DatePicker, getTimeNow, PopupSelector, CalendarButton, ShareBox} from '../components/shim';
import { getNextDates, getFocusDate } from '../components/dates';
import { Catcher } from '../components/catcher';
import { internalReleaseWatchers } from '../data/fbutil';


function MeetingInfoHeader({meeting,meetingInfo, isHost, onEdit, navigation}) {
  return (
    <View style={{zIndex: 10, flexDirection: 'row', 
    // borderBottomColor: '#ddd', borderBottomWidth: StyleSheet.hairlineWidth, 
        alignItems: 'center',
        justifyContent: 'space-between', paddingHorizontal: 8}} >
      <View style={{flexDirection: 'row', alignItems: 'center'}}>
        <Ionicons name='ios-settings' size={30} color='#999' style={{marginRight: 8}} />
        <Text style={{fontSize:20}}>{meetingInfo.name}</Text>
      </View>
      {/* <Text style={{fontSize: 20}}>{meetingInfo.name}</Text> */}
    </View>
  )
}

function EditMeetingHeader() {
  return (
    <View style={{zIndex: 10, flexDirection: 'row', borderBottomColor: '#ddd', 
        // borderBottomWidth: StyleSheet.hairlineWidth, 
        alignItems: 'center', paddingHorizontal: 10,
        justifyContent: 'space-between'}} >
      <View style={{flexDirection: 'row', alignItems: 'center'}}>
        {/* <Entypo name='edit' size={20} color='#999' style={{marginRight: 8}} /> */}
        <Text style={{fontSize:20}}>Edit Meeting</Text>
      </View>
    </View>
  )
}

function NextDates({meetingInfo}) {
  const nextDates = getNextDates({anchorDate: new Date(meetingInfo.date || 0), startDate: new Date(), repeat: meetingInfo.repeat, count: 10});
  return (
    <View style={{marginHorizontal: 32, marginVertical: 16}}>
      <Text style={{fontWeight: '700', color: '#222', fontSize: 16, marginBottom: 4}}>Future Gatherings</Text>
      {nextDates.map(d => 
        <View key={d.valueOf()} style={{}}>
          <Text style={{color: '#666', marginBottom: 4}}>
            <DateTimeText date={d} />
          </Text>
        </View>
      )}
    </View>
  )
}


export class EditMeetingInfo extends React.Component {
  state = {}

  componentDidMount() {
    const {meeting} = this.props;
    watchMeetingInfo(this, meeting, meetingInfo => this.setState({meetingInfo}));
  }
  componentWillUnmount() {
    internalReleaseWatchers(this);
  }

  async updateGathering() {
    const {meeting, onDone} = this.props;
    const {meetingInfo, name, date, time, summary, repeat} = this.state;
    var dateVal;
    if (date || time) {
      const curDate = date || new Date(meetingInfo.date);
      // const curTime = time || new Date(meetingInfo.date);
      // var comboDate = new Date(curDate.getFullYear(), curDate.getMonth(), curDate.getDate(), 
      // curTime.getHours(), curTime.getMinutes());
      dateVal = curDate.valueOf();
    }

    await updateMeetingAsync({meeting, 
      name: name || meetingInfo.name,
      summary: summary || meetingInfo.summary,
      date: dateVal || meetingInfo.date,
      repeat: repeat || meetingInfo.repeat});
    await onDone();
  }

  render(){
    const {navigation} = this.props;
    const {meetingInfo, name, date, time, summary, repeat, focusTime} = this.state;

    if (!meetingInfo) return null;
    const focusDate = getFocusDate(meetingInfo);

    return (
      <HeaderSpaceView>
        <Catcher style={{zIndex: 10}}>
          <View style={{padding: 4, flexDirection: 'row', borderBottomColor: '#ddd', borderBottomWidth: StyleSheet.hairlineWidth}}>
            <BackButton navigation={navigation} />
            <EditMeetingHeader />
          </View>
        </Catcher>
        <ScrollView>
          <FormInput part='name' 
              placeholder='Title' style={styles.nameBox}
              defaultValue={name || meetingInfo.name}
              onFocus={() => this.setState({focusTime: getTimeNow()})}
              // textAlign = 'center'
              onChangeText={name => this.setState({name})}/>
          {/* <View style={{zIndex: 10, marginTop: 16, marginHorizontal: 16, 
                flexDirection: 'row', alignItems: 'center'}}> */}
            <View style={{borderColor: '#ddd', borderWidth: StyleSheet.hairlineWidth, 
                zIndex: 10,
                alignSelf: 'stretch', marginTop: 10, marginHorizontal: 16,
                borderRadius: 8, paddingVertical: 4, paddingHorizontal: 8}}>
              <DatePicker part='date'
                  className='datepicker'
                  style={{marginLeft: 16, fontSize: 30}}
                  placeholderText='Date'
                  focusTime={focusTime}
                  showTimeSelect
                  disabledKeyboardNavigation
                  selected={date || focusDate}
                  onChange={date => this.setState({date})}
                  // showTimeSelect
                  // timeFormat="HH:mm"
                  timeIntervals={15}
                  dateFormat="MMM d, yyyy   h:mm aa"
                />
            </View>
            {/* <Text style={{fontSize: 20, color: '#666', marginHorizontal: 8}}>at</Text>
            <View style={{borderColor: '#ddd', borderWidth: StyleSheet.hairlineWidth, borderRadius: 8, paddingVertical: 4, paddingHorizontal: 8}}>
              <DatePicker part='time'
                  className='datepicker'
                  style={{marginLeft: 16, fontSize: 30}}
                  placeholderText='Time'
                  selected={time || meetingInfo.date}
                  onChange={time => this.setState({time})}
                  // showTimeSelect
                  timeFormat="h:mm aa"
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
              />
            </View> */}
          {/* </View> */}
          <View style={{marginTop: 8, zIndex: 5, flexDirection: 'row', alignItems: 'center'}}>
            <Text style={{fontSize: 20, marginLeft: 24, color: '#666', marginHorizontal: 8}}>repeating</Text>
            <PopupSelector part='repeat' width={200} value={repeat || meetingInfo.repeat || 'once'} 
              onSelect={repeat => this.setState({repeat})}
              items={[
                {label: 'Does not repeat', id:'once'}, 
                {label: 'Daily', id:'daily'},
                {label: 'Weekdays', id:'weekdays'},
                {label: 'Weekly', id:'weekly'},
                {label: 'Monthly', id:'monthly'},
                {label: 'Quarterly', id:'quarterly'},
              ]}/>
          </View>

          <FormInput part='summary' multiline
              placeholder='Description' style={styles.summaryBox}
              defaultValue={summary || meetingInfo.summary}
              onFocus={() => this.setState({focusTime: getTimeNow()})}
              // textAlign = 'center'
              onChangeText={summary => this.setState({summary})}/>

          <WideButton part='submit' onPress={() => this.updateGathering()} disabled={!date && !time && !name && !summary && !repeat}>
            Save
          </WideButton>
        </ScrollView>
      </HeaderSpaceView>  
    )
  }
}

const styles = StyleSheet.create({
  nameBox: {
    backgroundColor: 'white',
    padding: 8,
    // width: 150,
    borderColor: '#ddd',
    borderRadius: 8,
    borderWidth: 1,
    // marginHorizontal: 4,
    marginTop: 16,
    // textAlign: 'center',
    // flex: 1,
    marginHorizontal: 16,
    fontSize: 20
  },
  summaryBox: {
    backgroundColor: 'white',
    padding: 8,
    // width: 150,
    height: 100,
    borderColor: '#ddd',
    borderRadius: 8,
    borderWidth: 1,
    marginVertical: 16,
    // marginHorizontal: 4,
    // textAlign: 'center',
    // flex: 1,
    marginHorizontal: 16,
    fontSize: 16
  }

})


function getStringForRepeat(repeat) {
  switch (repeat) {
    case 'once': return 'Does not repeat';
    case 'daily': return 'Daily';
    case 'weekly': return 'Every Week';
    case 'weekdays': return 'Every Week Day (Mon-Fri)';
    case 'monthly': return 'Once a Month';
    case 'quarterly': return 'Once a Quarter';
    default: return 'Does not repeat'
  }
}


export class MeetingInfo extends React.Component {
  state = {}

  componentDidMount() {
    const {meeting} = this.props;
    watchMeetingInfo(this, meeting, meetingInfo => this.setState({meetingInfo}));
  }
  componentWillUnmount() {
    internalReleaseWatchers(this);
  }

  render() {
    const {meeting, onGotoUser, navigation} = this.props;
    const {meetingInfo, editing} = this.state;
    if (!meetingInfo) {return null}

    const focusDate = getFocusDate(meetingInfo);
    const isHost = meetingInfo.host == global_currentUser;
    if (editing) {
      return <EditMeetingInfo navigation={navigation} meeting={meeting} meetingInfo={meetingInfo} onDone={()=>this.setState({editing:false})} />
    } else {
      return (
        <HeaderSpaceView>
          <Catcher style={{zIndex: 10}}>
            <View style={{paddingHorizontal: 4, paddingBottom: 4, 
                  flexDirection: 'row', alignItems: 'center', 
                  borderBottomColor: '#ddd', borderBottomWidth: StyleSheet.hairlineWidth}}>
              <BackButton navigation={navigation} />
              <MeetingInfoHeader isHost={isHost} meeting={meeting} meetingInfo={meetingInfo} onEdit={()=>this.setState({editing: true})} navigation={navigation} />
            </View>
          </Catcher>
          <ScrollView>
            <View style={{flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', alignSelf: 'stretch', marginHorizontal: 32, marginTop: 8}}>
          {/* <WideButton>Leave</WideButton> */}
              <LeaveButton meeting={meeting} navigation={navigation} />
              <ShareBox meeting={meeting} meetingInfo={meetingInfo} onSelect={()=>onGotoUser({user:'invite'})} />

              {isHost ?
                <WideButton part='edit' style={{margin: 0, marginRight: 4}} onPress={()=>this.setState({editing: true})}>
                  Edit
                </WideButton>
              : null}
              {/* <WideButton style={{margin: 0}} onPress={async ()=>{
                await leaveMeetingAsync(meeting);
                if (!isWeb) {
                  navigation.goBack();
                }
              }}>Leave Gathering</WideButton>  */}
            </View>          
            <View style={{marginHorizontal: 32, marginVertical: 16}}>
              <Text style={{fontWeight: '700', color: '#222', fontSize: 16, marginBottom: 2}}>When</Text>
              <Text style={{color: '#666'}}><DateTimeText date={focusDate} repeat={meetingInfo.repeat} /></Text>
            </View>
            <View style={{margin: 16, alignSelf: 'center', zIndex: 100}}>
              <CalendarButton meetingInfo={meetingInfo} meeting={meeting} />
            </View>
            <View style={{marginHorizontal: 32, marginVertical: 16}}>
              <Text style={{fontWeight: '700', color: '#222', fontSize: 16, marginBottom: 2}}>About this gathering</Text>
              <Text style={{color: '#666'}}>{meetingInfo.summary}</Text>
            </View>
            <View style={{marginHorizontal: 32, marginVertical: 16}}>
              <Text style={{fontWeight: '700', color: '#222', fontSize: 16, marginBottom: 2}}>Who can attend</Text>
              <Text style={{color: '#666'}}>Anyone with the link</Text>
            </View>
            <Catcher>
              <NextDates meetingInfo={meetingInfo} />
            </Catcher>
          </ScrollView>
        </HeaderSpaceView>
      )
    }
  }
}

function LeaveButton({meeting, navigation}) {
  return (
    <FixedTouchable onPress={()=>{
        leaveMeetingAsync(meeting);
        if (!isWeb) {
          navigation.goBack();
        }
      }}>
      <View style={{margin: 0, paddingVertical: 8, paddingHorizontal: 12,
          // backgroundColor: '#f5f5f5',
          // borderRadius: 4,
          flexDirection: 'row', alignItems: 'center',
          borderColor: '#ddd', borderWidth: StyleSheet.hairlineWidth
          }}>
        <Ionicons name='ios-walk' size={20} style={{marginRight: 8}} />
        <Text style={{fontSize: 16}}>Leave</Text>
      </View>
    </FixedTouchable>
  )
}

