import React from 'react';
import { Text, View, StyleSheet } from 'react-native';
import { DefaultImage, WideButton, FormInput, FixedTouchable, parseDataUrl, getFileExtension, UserIcon, SmallButton, BackButton } from '../components/basics';
import { setUserIntroductionAsync, global_currentUser, setMyPhoto, setUserPhoto } from '../data/data';
import _ from 'lodash';
import { baseColor } from '../data/config';
import { pickImage } from '../components/photo';
import { resizeImageAsync } from '../components/shim';
import { newKey, getUrlForImage } from '../data/fbutil';
import { uploadImage } from '../data/servercall';
import { global_showPhotoPopup } from '../components/photopopup';
import { UserPopupMenu } from '../components/popupmenu';

class IntroEditor extends React.Component {
  state = {}
  render() {
    const {intro} = this.state;
    const {meeting, user, onClose, userIntro} = this.props;
    const currentText = intro != null ? intro : _.get(userIntro,'text','');
    return (
      <View>
        <FormInput part='intro' placeholder='What is your introduction?'
          value={currentText}
          onChangeText={intro => this.setState({intro})}
          multiline
          style = {{
              backgroundColor: 'white', padding: 8, height: 100,
              borderColor: '#ddd', borderWidth: 1, borderRadius: 8,
              marginTop: 8
            }}
        />
        <View style={{paddingTop: 8, flexDirection: 'row', justifyContent: 'flex-end'}}>
          <WideButton part='save' style={{margin: 0, marginRight: 16}}
            onPress={async () => {
              await setUserIntroductionAsync({meeting, user, text: intro})
              onClose();
            }}>
            Save</WideButton>
          <WideButton part='cancel' style={{margin: 0}} onPress={onClose}>
            Cancel
          </WideButton>
        </View>
      </View>
    )
  }
}

export function UserIntroScreen({isHost, user, meeting, userIntro, userInfo, onGotoUser, navigation}) {
  return (
    <View style={{flex: 1, flexDirection: 'column'}}>
      <View style={{flexDirection: 'row', padding: 4, justifyContent: 'space-between',
        borderBottomColor: '#ddd', borderBottomWidth: StyleSheet.hairlineWidth, zIndex: 10
        }}>
        <View style={{flex: 1, flexDirection: 'row'}}>
          <BackButton navigation={navigation} />
          <Text numberOfLines={1} style={{fontSize: 20, marginLeft: 4}}>
            {'About ' + userInfo.name}
          </Text>
        </View>
        <UserPopupMenu meeting={meeting} user={user} iAmHost={isHost} navigation={navigation} onGotoUser={onGotoUser} />
      </View>
      <UserIntro isHost={isHost} user={user} meeting={meeting} userIntro={userIntro} userInfo={userInfo} />
    </View>
  )
}

export class UserIntro extends React.Component {
  state = {}

  async pickPhoto() {
    const {meeting, isHost, user} = this.props;
    const pickedImage = await pickImage();
    if (!pickedImage || !pickedImage.uri) return;
    
    this.setState({uploading: true});
    const smallUrl = await resizeImageAsync({url: pickedImage.uri, pickedImage, rotate: true, maxSize: 800});
    const thumbUrl = await resizeImageAsync({url: pickedImage.uri, pickedImage, rotate: true, maxSize: 100});
    const smallData = parseDataUrl(smallUrl);
    const thumbData = parseDataUrl(thumbUrl);
    const smallKey = newKey('photo');
    const thumbKey = newKey('thumb');
    // console.log('keys', smallKey, thumbKey);
    const fileExtension = getFileExtension(smallData.contentType);
    const photo = global_currentUser+'/' + smallKey + fileExtension;
    const thumb = global_currentUser+'/' + thumbKey + fileExtension;
    const pImage = uploadImage({
      base64data:smallData.base64data, contentType: smallData.contentType, 
      key:smallKey, userId: global_currentUser});
    const pThumb = uploadImage({
        base64data:thumbData.base64data, contentType: thumbData.contentType, 
        key:thumbKey, userId: global_currentUser});
    await pImage; await pThumb;
    if (user == global_currentUser) {
      await setMyPhoto({meeting, photo, thumb});
    } else {
      await setUserPhoto({meeting, user, photo, thumb});
    }
    this.setState({uploading: false});
  }

  render() {
    const {isHost, user, meeting, userIntro, userInfo} = this.props;
    const {editing, uploading, text, expanded} = this.state;
    const name = _.get(userInfo,'name','User');
    const introText = _.get(userIntro,'text');

    if (userInfo.room) {
      return null;
    }

    if (!introText && !userInfo.photo && !isHost && user != global_currentUser) {
      return null;
    }
    return (
      <View style={{
            flexDirection: 'row',
            borderBottomColor: '#ddd', borderBottomWidth: StyleSheet.hairlineWidth,
            paddingHorizontal: 32, paddingVertical: 16
          }}>
        <View style={{alignItems: 'center'}}>
          {uploading ? 
            <View style={{width: 100, height: 100, borderRadius: 50, backgroundColor: '#ddd', justifyContent: 'center', alignItems: 'center'}}>
              <Text style={{textAlign: 'center', color: '#666'}}>
                Uploading...
              </Text>
            </View>
          :
            (userInfo.photo ? 
              <FixedTouchable onPress={() => global_showPhotoPopup({photo: userInfo.photo})}>
                <UserIcon userInfo={userInfo} thumb={false} size={100} />
              </FixedTouchable>
            : 
              (isHost || user == global_currentUser ? 
                <FixedTouchable onPress={()=>this.pickPhoto()}>
                  <View style={{marginTop: 4, borderRadius: 16, backgroundColor: baseColor, paddingHorizontal: 8, paddingVertical: 4, maxWidth: 100}}>
                    <Text style={{color: 'white', textAlign: 'center', fontSize: 20}}>Choose Profile Photo</Text>
                  </View>
                </FixedTouchable>
              : 
                <UserIcon userInfo={userInfo} thumb={false} size={100} />
              )
            )
          }
            {/* <DefaultImage colorKey={name} name={name} size={100} /> */}
            {userInfo.photo && (isHost || user == global_currentUser) ? 
              <FixedTouchable onPress={()=>this.pickPhoto()}>
                <View style={{marginTop: 4, borderRadius: 8, backgroundColor: baseColor, paddingHorizontal: 8, paddingVertical: 4}}>
                  <Text style={{color: 'white', textAlign: 'center', fontSize: 13}}>Update Photo</Text>
                </View>
              </FixedTouchable>
            : null}
        </View>
        <View style={{marginLeft: 12, flex: 2, flexShrink: 1}}>
          {introText ? 
            <Text style={{fontSize: 16, fontWeight: '600'}}>Introducing {name}</Text>
          : 
            <Text style={{color: '#666'}}>The host has not yet written an introduction for {user == global_currentUser ? 'you' : name}</Text>
          }
          {editing ? 
            <IntroEditor meeting={meeting} user={user} userInfo={userInfo} userIntro={userIntro} 
                onClose={() => this.setState({editing: false})} />
          :
            (introText ? 
              <FixedTouchable onPress={()=>this.setState({expanded: !expanded})}>
                <View style={{marginTop: 4}}>                
                  <Text numberOfLines={expanded ? null : 4} style={{color: '#666'}}>{_.get(userIntro,'text')}</Text>
                  <View style={{marginTop: 4}}>
                    <Text style={{fontSize: 12, color: '#999', marginLeft: 16}}>- by meeting host</Text>          
                  </View>
                </View>
              </FixedTouchable>
            : (isHost ? 
                <WideButton part='write' style={{marginHorizontal: 0, marginTop: 8}} 
                  onPress={() => this.setState({editing: true})}>
                Write Introduction
              </WideButton>
              : null)
            )
          }
          {(!editing && introText && isHost) ?
            <SmallButton part='edit' onPress={() => this.setState({editing: true})}>
              Edit
            </SmallButton>
          : null}
        </View>
      </View>
    )
  }
}