import React from 'react';
import { Text, View } from 'react-native';
import { watchMeetingInfo } from '../data/data';
import { getTimeNow, isTest } from './shim';
import { Entypo, FontAwesome } from '@expo/vector-icons';
import { DateTimeText } from './basics';
import { getFocusDate, getNextDates } from './dates';
import { internalReleaseWatchers } from '../data/fbutil';

const hourMillis = 1000 * 60 * 60;

export class NotStartedBanner extends React.Component {
  state = {}
  componentDidMount() {
    const {meeting} = this.props;
    watchMeetingInfo(this, meeting, meetingInfo => this.setState({meetingInfo}));
    this.forceRefresh();
  }
  componentWillUnmount() {
    internalReleaseWatchers(this);
  }

  forceRefresh() {
    if (isTest) return;
    this.setState({salt: getTimeNow()});
    setTimeout(() => this.forceRefresh(), 1000);
  }
  render() {
    const {meetingInfo} = this.state;
    if (!meetingInfo) {return null}
    const focusDate = getFocusDate(meetingInfo);
    const closeTimes = getNextDates({anchorDate: new Date(meetingInfo.date), repeat: meetingInfo.repeat, startDate: new Date(), count: 2});
    var nextDate = null;
    // console.log(closeTimes, closeTimes[0], closeTimes[1]);
    if (closeTimes && closeTimes[1] && closeTimes[0] != focusDate) {
      nextDate = closeTimes[0].valueOf();
    } else if (closeTimes && closeTimes[1] && closeTimes[1] != focusDate) {
      nextDate = closeTimes[1].valueOf();   
    }
    if (meetingInfo && focusDate > getTimeNow()) {
      return (
        <View style={{backgroundColor: '#FFCB2F', paddingHorizontal: 16, paddingVertical: 10, flexDirection: 'row', alignItems: 'center'}}>
          <FontAwesome name='clock-o' size={40} style={{marginRight: 8}} />
          <View>
            <Text style={{fontSize: 16, fontWeight: '600'}}>This gathering has not yet started</Text>
            <Text style={{color: '#666', marginTop: 2, fontSize: 13}}>
              starts at{' '} 
              <Text style={{color: 'black'}}>
                <DateTimeText date={focusDate} />
              </Text>
            </Text>
          </View>
        </View>
      )
    } else if (meetingInfo && focusDate < (getTimeNow() - 24 * hourMillis)) {
      return (
        <View style={{backgroundColor: '#FFCB2F', paddingHorizontal: 16, paddingVertical: 10, flexDirection: 'row', alignItems: 'center'}}>
          <FontAwesome name='clock-o' size={40} style={{marginRight: 8}} />
          <View>
            <Text style={{fontSize: 16, fontWeight: '600'}}>This gathering is over</Text>
            <Text style={{color: '#666', marginTop: 2, fontSize: 13}}>
              started at{' '} 
              <Text style={{color: 'black'}}>
                <DateTimeText date={focusDate} />
              </Text>,
            </Text>
            {nextDate ? 
              <Text style={{color: '#666', marginTop: 2, fontSize: 13}}>
                next gathering at{' '} 
                <Text style={{color: 'black'}}>
                  <DateTimeText date={nextDate} />
                </Text>
              </Text>
            : null}
          </View>
        </View>
      )

    } else {
      return null;
    }
  }
}