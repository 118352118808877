import React from 'react';
import { Notifications } from 'expo';
import * as Permissions from 'expo-permissions';
import {Text, View, Platform, Linking, StyleSheet} from 'react-native';
import { FixedTouchable, WideButton, ScreenContentScroll } from '../components/basics';
import { getNotifTokenAsync, setNotifTokenAsync, markNotifsSkippedAsync } from '../data/data';
import { Ionicons } from '@expo/vector-icons';
import _ from 'lodash';
import { isTest } from '../components/shim';
import { logError } from '../components/error';
import { internalReleaseWatchers } from '../data/fbutil';

export async function getNotifPermissionStatusAsync() {
  return await Permissions.getAsync(Permissions.NOTIFICATIONS);
}

export async function getExpoNotifToken() {
  return await Notifications.getExpoPushTokenAsync();
}

export async function requestNotifPermission() {
  return await Permissions.askAsync(Permissions.NOTIFICATIONS);
}

export async function checkIfNotifsGranted() {
  if (Platform.OS == 'web') {
    return true;
  }  
  const notifStatus = await getNotifPermissionStatusAsync();
  if (_.get(notifStatus, 'status') != 'granted') {
    return false;
  } else { 
    return true;
  }   
}

export async function refreshNotifToken() {
  if (Platform.OS == 'web') {
    return;
  }

  try {
    const notifToken = await getNotifTokenAsync();
    const expoNotifToken = await getExpoNotifToken();
    if (notifToken != expoNotifToken) {
      await setNotifTokenAsync(expoNotifToken);
    }
  } catch (e) {
    logError('notif error', e);
  }
}

var notifEnabledHooks = [];
export function watchNotifsGranted(func) {
  notifEnabledHooks.push(func);
}
function triggerNotifsGranted() {
  // console.log('trigger', notifEnabledHooks)
  notifEnabledHooks.forEach(func => {
    func()
  })
}

export class EnableNotifsBanner extends React.Component {
  state = {notifsEnabled: true, denied: false}
  async componentDidMount() {
    const notifsEnabled = await checkIfNotifsGranted();
    if (notifsEnabled) {
      await refreshNotifToken();
    }
    this.setState({notifsEnabled: await checkIfNotifsGranted()});
  }
  componentWillUnmount() {
    internalReleaseWatchers(this);
  }

  async setupNotifToken() {
    const notifStatus = await requestNotifPermission();
    if (_.get(notifStatus, 'status') == 'granted') {
      await refreshNotifToken();
      this.setState({notifsEnabled: true});
    } else {
      this.setState({denied: true});
    }
  }

  async pollForNotifsGranted() {
    const granted = await checkIfNotifsGranted();
    if (granted) {
      this.setState({notifsEnabled: true});
    } else if (!isTest) {
      setTimeout(() => this.pollForNotifsGranted(), 500);
    }
  }

  render() {
    const {notifsEnabled, denied} = this.state;
    if (notifsEnabled) return null;
    return (
      <FixedTouchable onPress={()=>this.setupNotifToken()}>
        <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', 
            backgroundColor: 'white', borderColor: '#ddd', borderWidth: StyleSheet.hairlineWidth, padding: 8, margin: 4}}>
          <Ionicons name='ios-notifications' size={30}/>
          <Text style={{marginHorizontal: 8, flex: 1}}>
            <Text style={{fontWeight: 'bold'}}>Enable notifications</Text> to know when someone messages you.
          </Text>
          {denied 
          ? <WideButton style={{margin: 0}} onPress={()=>{Linking.openURL('app-settings://'); this.pollForNotifsGranted()}}
              >Open Settings
            </WideButton>
          : <WideButton style={{margin: 0}} onPress={()=>this.setupNotifToken()}>Enable</WideButton>
          }
        </View>
      </FixedTouchable>
    )
  }
}

export class EnableNotifsScreen extends React.Component {
  state = {denied: false}
  async setupNotifToken() {
    const notifStatus = await requestNotifPermission();
    if (_.get(notifStatus, 'status') == 'granted') {
      await refreshNotifToken();
      triggerNotifsGranted();
    } else {
      // console.log('notifs denied');
      this.setState({denied: true});
    }
  }

  async componentDidMount() {
    await this.pollForNotifsGranted();
  }

  async pollForNotifsGranted() {
    // console.log('pollNotifs');
    const granted = await checkIfNotifsGranted();
    // console.log('granted: ', granted);
    if (granted) {
      triggerNotifsGranted();
    } else if (!isTest) {
      setTimeout(() => this.pollForNotifsGranted(), 500);
    }
  }

  render() {
    const {denied} = this.state;
    return (
      <ScreenContentScroll statusdark innerStyle={{backgroundColor: 'white'}}>
          <View style={{backgroundColor: 'white', padding: 16, marginTop: 32}}>
            <Text style={{textAlign: 'center', fontWeight: 'bold', fontSize: 18}}>Almost Finished</Text>          

            <Text style={{paddingVertical: 16, textAlign: 'center', color: '#666'}}>
              Enable notifications so we can tell you when someone sends you a message. 
              We promise not to spam you with any other notifications.
            </Text>

            {!denied ? null : 
              <Text style={{paddingVertical: 10, textAlign: 'center', color: '#666'}}>
                You previously denied notification permission, so you will need to enable
                notifications in the settings app
              </Text>
            }

            <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around'}} >
              {denied ? 
                <View style={{flexDirection: 'row', justifyContent: 'space-around'}}>
                  <WideButton onPress={()=>Linking.openURL('app-settings://')}>Open Settings</WideButton>
                </View>        
              :
                <View style={{flexDirection: 'row', justifyContent: 'space-around'}}>
                  <WideButton part='enable' onPress={()=>this.setupNotifToken()}>Enable Notifications</WideButton>
                </View>
              }
              <FixedTouchable onPress={()=>markNotifsSkippedAsync()}>
                <Text style={{color: '#666'}}>Maybe Later</Text>
              </FixedTouchable>
            </View>
        </View>
      </ScreenContentScroll>
    )
  }
}
