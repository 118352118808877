import React from 'react';
import {Text, View, StyleSheet} from 'react-native';
import { UserIcon, minuteMillis, hourMillis, dayMillis } from './basics';
import { global_currentUser } from '../data/data';
import _ from 'lodash';
import { baseColor } from '../data/config';
import { getTimeNow } from './shim';
import { FontAwesome } from '@expo/vector-icons';

export function UserOrRoomIcon({attendees, thumb=true, userInfo, style, size = 40, marginRight = null, isActive=true}) {
  if (userInfo.room && !userInfo.isPublic) {
    return <RoomIcon size={size} userInfo={userInfo} attendees={attendees} />
  } else {
    return <UserIcon userInfo={userInfo} style={style} thumb={thumb} size={size} marginRight={marginRight} isActive={isActive} />
  }
}


const positionsForCount = {
  0: {},
  1: {
    0: {x: 0.5, y: 0.5}
  },
  2: {
    0: {x: 0, y: 0},
    1: {x: 1, y: 1}
  },
  3: {
    0: {x: 0, y: 0},
    1: {x: 0, y: 1},
    2: {x: 1, y: 1}
  },
  4: {
    0: {x: 0, y: 0},
    1: {x: 1, y: 0},
    2: {x: 0, y: 1},
    3: {x: 1, y: 1}
  },
  5: {
    0: {x: 0, y: 0},
    1: {x: 1, y: 0},
    2: {x: 0, y: 1},
    3: {x: 1, y: 1},
    4: {x: 0.5, y: 0.5}
  },
  6: {
    0: {x: 0, y: 0},
    1: {x: 0, y: 1},
    2: {x: 0, y: 2},
    3: {x: 2, y: 0},
    4: {x: 2, y: 1},
    5: {x: 2, y: 2}
  },
  7: {
    0: {x: 0, y: 0},
    1: {x: 0, y: 1},
    2: {x: 0, y: 2},
    3: {x: 2, y: 0},
    4: {x: 2, y: 1},
    5: {x: 2, y: 2},
    6: {x: 1, y: 1}
  },
  8: {
    0: {x: 0, y: 0},
    1: {x: 0, y: 1},
    2: {x: 0, y: 2},
    3: {x: 2, y: 0},
    4: {x: 2, y: 1},
    5: {x: 2, y: 2},
    6: {x: 1, y: 0.5},
    7: {x: 1, y: 1.5},
  },
  9: {
    0: {x: 0, y: 0},
    1: {x: 0, y: 1},
    2: {x: 0, y: 2},
    3: {x: 2, y: 0},
    4: {x: 2, y: 1},
    5: {x: 2, y: 2},
    6: {x: 1, y: 0},
    7: {x: 1, y: 1},
    8: {x: 1, y: 2}
  },
  10: {
    0: {x: 0, y: 0},
    1: {x: 0, y: 1},
    2: {x: 0, y: 2},
    3: {x: 2, y: 0},
    4: {x: 2, y: 1},
    5: {x: 2, y: 2},
    6: {x: 1, y: 0},
    7: {x: 1, y: 2},
  }


}

export function GroupIcon({size}) {
  return (
    <View style={{width: size, height: size, justifyContent: 'center', alignItems: 'center'}}>
      <FontAwesome size={size * (4/5)} name='group' color='#666' />
    </View>
  )
}

export function RoomIcon({size=40, userInfo, attendees}) {
  const members = _.get(userInfo, 'members', {});
  const allKeys = _.filter(Object.keys(members), m => members[m] && _.get(attendees,m));
  const count = allKeys.length;
  const positions = positionsForCount[Math.min(count,10)];
  const offset = size/60;
  const borderWidth = 1;
  const innerSize = size - (2*offset) - (borderWidth * 2);
  const gridSize = count < 6 ? 2 : 3;
  const miniSize = count < 6 ? (innerSize/2) : innerSize/3;
  const scale = innerSize / gridSize; // ((innerSize - miniSize) / gridSize);
  const borderColor = '#666';

  if (count < 10) {
    return (
      <View style={{width: size, height: size, backgroundColor: '#f5f5f5', 
          borderWidth, borderRadius: size/6, 
          borderColor}}>
        {allKeys.map((u,i) => 
          <UserIcon key={u} size={miniSize} userInfo={attendees[u] || {}} 
              style={{position: 'absolute', 
                  left: positions[i].x * scale + offset, 
                  top: positions[i].y * scale + offset}} />
        )}
      </View>
    )
  } else {
    return (
      <View style={{width: size, height: size, backgroundColor: '#f5f5f5', 
          borderWidth, borderRadius: size/6, 
          borderColor}}>
        {allKeys.slice(0,8).map((u,i) => 
          <UserIcon key={u} size={miniSize} userInfo={attendees[u] || {}} 
              style={{position: 'absolute', 
                  left: positions[i].x * scale + offset, 
                  top: positions[i].y * scale + offset}} />
        )}
        <View style={{width: size / 2 , height: size /2 , 
            position: 'absolute', bottom: size/4, right: size/4,
            justifyContent: 'center', alignItems: 'center',
            backgroundColor: 'white', borderColor: '#ddd', borderWidth: 1,
            borderRadius: size/2}}>
          <Text style={{color: '#999', fontSize: 12}}>{count}</Text>
        </View>
      </View>
    )

  }
}


export function GatheringIcon({size=40, meeting, attendees, presence}) {
  const dayAgo = getTimeNow() - dayMillis;
  const allKeys = _.filter(Object.keys(presence || {}), p => presence[p] > dayAgo 
      && _.get(attendees,[p,'present'])
      && !_.get(attendees,[p,'room']));
  // const allKeys = Object.keys(attendees);
  const count = allKeys.length;
  const positions = positionsForCount[Math.min(count,10)];
  const offset = size/60;
  const isPresent = _.find(allKeys, m => m == global_currentUser);
  const borderWidth = isPresent ? 2 : 1;

  // console.log('present', meeting, attendees, allKeys, isPresent);
  const innerSize = size - (2*offset) - (borderWidth * 2);
  const gridSize = count < 6 ? 2 : 3;
  const miniSize = count < 6 ? (innerSize/2) : innerSize/3;
  const scale = innerSize / gridSize; // ((innerSize - miniSize) / gridSize);
  const borderColor = isPresent ? baseColor : '#ddd';

  // const borderColor = '#ddd';

  if (count < 10) {
    return (
      <View style={{width: size, height: size, backgroundColor: '#f5f5f5', 
          borderWidth, borderRadius: size/6, 
          borderColor}}>
        {allKeys.map((u,i) => 
          <UserIcon key={u} size={miniSize} userInfo={attendees[u]} 
              style={{position: 'absolute', 
                  left: positions[i].x * scale + offset, 
                  top: positions[i].y * scale + offset}} />
        )}
      </View>
    )
  } else {
    return (
      <View style={{width: size, height: size, backgroundColor: '#f5f5f5', 
          borderWidth, borderRadius: size/6, 
          borderColor}}>
        {allKeys.slice(0,8).map((u,i) => 
          <UserIcon key={u} size={miniSize} userInfo={attendees[u]} 
              style={{position: 'absolute',
                  left: positions[i].x * scale + offset, 
                  top: positions[i].y * scale + offset}} />
        )}
        <View style={{width: size / 2 , height: size /2 , 
            position: 'absolute', bottom: size/4, right: size/4,
            justifyContent: 'center', alignItems: 'center',
            backgroundColor: 'white', borderColor: '#ddd', borderWidth: 1,
            borderRadius: size/2}}>
          <Text style={{color: '#999', fontSize: 12}}>{count}</Text>
        </View>
      </View>
    )
  }
}

