import React from 'react';
import { isWeb, FixedTouchable } from './basics';
import {View, Text} from 'react-native';
import { Entypo } from '@expo/vector-icons';

export var global_setNotif = ({title, body, meeting}) => {};

export class NotifBanner extends React.Component {
  state = {title: null, body: null}
  
  componentDidMount() {
    global_setNotif = ({title, body, meeting}) => {
      const rand = Math.floor(Math.random() * 1000);
      this.setState({title, body, meeting, rand});
      setTimeout(() => {
          if (this.state.rand == rand) {
            this.setState({title: null, body: null, rand: null})
          }
        },
      10000)
    }
  }

  componentWillUnmount() {
    global_setNotif = () => {};
  }

  render() {
    const {navigation, meeting:thisMeeting} = this.props; 
    const {title, body, meeting} = this.state;

    if (isWeb) {
      return null;
    }

    if (title) {
      return (
        <View style={{paddingHorizontal: 10, paddingVertical: 6, borderRadius: 8, 
              backgroundColor: '#eee', marginHorizontal: 4,
              flexDirection: 'row', justifyContent: 'space-between'}}>
            <View style={{flex: 1}}>
              <FixedTouchable onPress={()=>navigation.navigate('gather', {meeting})}>
                <Text numberOfLines={1} style={{fontWeight: 'bold'}}>{title}</Text>
                <Text numberOfLines={1} style={{color: '#666'}}>{body}</Text>
              </FixedTouchable>
            </View>
          <FixedTouchable onPress={()=>this.setState({title: null})}>
            <Entypo name='circle-with-cross' size={20} color='#666' style={{marginLeft: 4}} />
          </FixedTouchable>
        </View>
     )
    } else {
      return null;
    }
  }
}