import React from 'react';
import {Text, View, Image, StyleSheet, ScrollView, TextInput} from 'react-native';
import { watchMeetingMembers, watchMeetingInfo, addNewMemberAsync, saveMemberAsync, global_currentUser, watchMyEmail } from '../data/data';
import { WideButton, FormInput, findMember, FixedTouchable, parseDataUrl, getFileExtension } from '../components/basics';
import { Entypo } from '@expo/vector-icons';
import { getClipboardImageAsync, resizeImageAsync, setTitle } from '../components/shim';
import { uploadImage } from '../data/servercall';
import { newKey, getUrlForImage, signOut } from '../data/fbutil';
import _ from 'lodash';

export class MembersEditScreen extends React.Component {
  state = {}

  componentDidMount() {
    const {meeting} = this.props;
    watchMeetingMembers(this, meeting, members => this.setState({members}));
    watchMeetingInfo(this, meeting, meetingInfo => {
      this.setState({meetingInfo});
      setTitle('Members: ' + meetingInfo.name + ' - ChillChat');
    });
    watchMyEmail(this, email => this.setState({email}));
  }

  render() {
    const {meeting} = this.props;
    const {members, meetingInfo, email} = this.state;

    if (members == null || meetingInfo == null || email == null) {
      return null;
    }

    const canEdit = meetingInfo.host == global_currentUser;
    const isMember = findMember({members, email});

    if (!canEdit && !isMember) {
      return <NoMemberAccess meetingInfo={meetingInfo} email={email} />
    }

    const sortedMembers = _.sortBy(Object.keys(members), m => members[m].name);
    return (
      <View style={{backgroundColor: '#FAFAFA', flex: 1}}>
        <View style={{paddingVertical: 10, backgroundColor: 'white', borderBottomColor: '#ddd', borderBottomWidth: StyleSheet.hairlineWidth, alignItems: 'center'}}>
          <Text style={{fontSize: 30, textAlign: 'center'}}>{_.get(meetingInfo,'name')}</Text>
          <Text style={{fontSize: 15, textAlign: 'center', marginTop: 4}}>
            {canEdit ? 'EDIT ' : ''}MEMBERS
          </Text>
        </View>
        <View style={{alignItems: 'center', flex: 1}}>
          <ScrollView>
            {canEdit ? 
              <View style={{flexDirection: 'row', justifyContent: 'center'}}>
                <WideButton onPress={()=>addNewMemberAsync(meeting)}>Add Member</WideButton>
              </View>
            : null }
            {sortedMembers.map(m => 
              <Member key={m} canEdit={canEdit} meeting={meeting} member={m} memberInfo={members[m]} />
            )}
            <View style={{height: 32}}/>
          </ScrollView>
        </View>
      </View>
    )
  }
}

class Member extends React.Component {
  state = {editing: false}

  async onPaste(event) {
    const bigUrl = await getClipboardImageAsync(event)
    if (!bigUrl) {
      // console.log('not a photo')
      return;
    }
    // console.log('bigUrl', bigUrl);
    this.setState({uploadingPhoto: true});
    const smallUrl = await resizeImageAsync({url: bigUrl, rotate: false, maxSize: 800});
    const thumbUrl = await resizeImageAsync({url: bigUrl, rotate: false, maxSize: 100});
    const smallData = parseDataUrl(smallUrl);
    const thumbData = parseDataUrl(thumbUrl);
    const smallKey = newKey('photo');
    const thumbKey = newKey('thumb');
    // console.log('keys', smallKey, thumbKey);
    const fileExtension = getFileExtension(smallData.contentType);
    const photo = global_currentUser+'/' + smallKey + fileExtension;
    const thumb = global_currentUser+'/' + thumbKey + fileExtension;

    const pImage = uploadImage({
      base64data:smallData.base64data, contentType: smallData.contentType, 
      key:smallKey, userId: global_currentUser});
    const pThumb = uploadImage({
        base64data:thumbData.base64data, contentType: thumbData.contentType, 
        key:thumbKey, userId: global_currentUser});
  
    await pImage; await pThumb;

    this.setState({photo, thumb, uploadingPhoto: false});  
  }

  render() {
    const {meeting, member, memberInfo, canEdit} = this.props;
    const {thumb, uploadingPhoto, editing, name, intro, email, photo} = this.state;

    if (canEdit && (editing || !memberInfo || !memberInfo.name || !memberInfo.intro)) {
      return (
        <View style={{width: 500, margin: 10, alignSelf: 'stretch', 
            borderColor: '#ddd', borderWidth: StyleSheet.hairlineWidth, backgroundColor: 'white', padding: 16,
            borderRadius: 3,
            shadowRadius: 4, shadowColor: 'rgba(0,0,0,0.09)', shadowOffset: {width: 0, height: 1}
            }}>
          <View style={{flexDirection: 'row'}}>
            <View style={{width: 100, height: 100}}>
              {photo || memberInfo.photo ?
                <Image source={{uri:getUrlForImage(photo || memberInfo.photo)}} 
                  style={{
                    position: 'absolute', top: 0, left: 0,
                    width: 100, height: 100, borderRadius: 50, opacity: 0.5
                  }} 
                />
              : null}
              {uploadingPhoto ?
                <View style={{justifyContent: 'center'}}> 
                  <Text style={{textAlign: 'center'}}>
                    Uploading...
                  </Text>
                </View>
              : 
                <TextInput 
                  style={{zIndex: 100, fontSize: 12, textAlign: 'center', height: 100, width: 100, borderColor: '#ddd', borderRadius: 50, borderWidth: StyleSheet.hairlineWidth}} 
                  placeholder='Paste image here'
                  placeholderTextColor='black'
                  onPaste={e => {this.onPaste(e); return false}}
                  value=''
                />
              }
            </View>
            <View style={{flex: 1, marginLeft: 16}}>
              <FormInput 
                style={{
                  backgroundColor: 'white', padding: 8, fontSize: 20,
                  borderColor: '#ddd', borderWidth: 1, borderRadius: 8,
                  alignSelf: 'stretch',
                }}
                onChangeText={name => this.setState({name})}
                value={name || _.get(memberInfo,'name','')} placeholder='What is their name?'/>
              <FormInput multiline  
                style={{
                  backgroundColor: 'white', padding: 8, height: 100,
                  borderColor: '#ddd', borderWidth: 1, borderRadius: 8,
                  marginTop: 8
                }}
                onChangeText={intro => this.setState({intro})}
                value={intro || _.get(memberInfo,'intro','')} placeholder='What is their introduction?'/>
              <FormInput   
                style={{
                  backgroundColor: 'white', padding: 8, 
                  borderColor: '#ddd', borderWidth: 1, borderRadius: 8,
                  marginTop: 8
                }}
                onChangeText={email => this.setState({email})}
                value={email || _.get(memberInfo,'email','')} placeholder='What is their email?'/>

            </View>
          </View>
          <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around'}}>
            <WideButton 
                onPress={async ()=>{
                  await saveMemberAsync({
                    meeting, member, name: name || memberInfo.name, 
                    intro: intro || memberInfo.intro, 
                    photo: photo || memberInfo.photo,
                    thumb: thumb || memberInfo.thumb, 
                    email: email || memberInfo.email});
                  this.setState({editing: false, name: null, intro: null, photo: null, email: null});
                }}>Save</WideButton>
            <WideButton onPress={()=>{
              this.setState({editing: false, name: null, intro: null, photo: null, thumb: null, email: null})
            }}>Cancel</WideButton>
          </View>
        </View>
      )
    } else {
      return (
        <View style={{flexDirection: 'row', marginVertical: 10}}>
          <View style={{width: 500, marginHorizontal: 10, alignSelf: 'stretch', 
              borderColor: '#ddd', borderWidth: StyleSheet.hairlineWidth, backgroundColor: 'white', padding: 16,
              borderRadius: 3,
              shadowRadius: 4, shadowColor: 'rgba(0,0,0,0.09)', shadowOffset: {width: 0, height: 1},
              flexDirection: 'row'
              }}>
            {_.get(memberInfo,'photo') ?
              <Image source={{uri:getUrlForImage(memberInfo.photo)}} 
                style={{width: 100, height: 100, borderRadius: 50}} />
            :
              <View style={{width: 100, height: 100, borderRadius: 50, backgroundColor: '#999'}} 
              />
            }

            <View style={{flex: 1, flexShrink: 1, marginLeft: 16}}>
              <Text style={{fontSize: 20}}>{_.get(memberInfo,'name')}</Text>
              <Text style={{marginTop: 8, fontSize: 14, color: '#666'}}>{_.get(memberInfo,'intro')}</Text>
              {canEdit ? 
                <Text style={{marginTop: 8, fontSize: 14, color: '#666'}}>{_.get(memberInfo,'email')}</Text>
              : null}
              {canEdit ? 
                <View style={{flexDirection: 'row', justifyContent: 'flex-end', marginTop: 8}}>
                  <WideButton style={{margin: 0}} onPress={()=>this.setState({editing: true})}>Edit</WideButton>
                </View>
              : null}
            </View>
          </View>
        </View>
      
      )
    }
  }
}

function NoMemberAccess({meetingInfo, email}) {
  return (
    <View style={{backgroundColor: '#FAFAFA', flex: 1}}>
      <View style={{paddingVertical: 10, backgroundColor: 'white', borderBottomColor: '#ddd', borderBottomWidth: StyleSheet.hairlineWidth, alignItems: 'center'}}>
        <Text style={{fontSize: 30, textAlign: 'center'}}>{_.get(meetingInfo,'name')}</Text>
        <Text style={{fontSize: 15, textAlign: 'center', marginTop: 4}}>
          MEMBERS
        </Text>
      </View>
      <View style={{maxWidth: 500, alignSelf: 'center'}}>
        <Text style={{fontSize: 20, marginTop: 16}}>
          Not a Member: <Text style={{fontWeight: 'bold'}}>{email}</Text>
        </Text>
        <Text style={{color: '#666', marginTop: 4}}>
          You do not have permission to view the member list for this community. 
          Make sure you signed in with the same email address that you were invited with.
        </Text>
      </View>
      <View style={{flexDirection: 'row', justifyContent: 'center'}}>
        <WideButton onPress={()=>signOut()}>Sign Out</WideButton>
      </View>
    </View>
  )
}
