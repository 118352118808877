import React from 'react';
import {Text, View, Image, ScrollView, StyleSheet} from 'react-native';
import { watchMyName, watchMeetingUserInfo, watchMessages, sendMessageAsync, global_currentUser, watchBoardMessages, copyMessageToBoardAsync, markUserReadAsync, setTypingIndicatorAsync, watchTypingIndicator, watchBoardPeeks, deleteMessage, watchMeetingConversationInfo, joinConversationAsync, watchMeetingAttendees, followConversationAsync, leaveConversationAsync, newConversationAsync, watchUserIntroduction, watchMeetingInfo, sendMessageCopiedToConversationAsync, editMessageAsync, shareMessageToConversationAsync, askToCopyMessageToBoardAsync, acceptBoardRequest, rejectBoardRequest, removeMessageFromBoardAsync, shareConversationAsync, likeMessageAsync, watchResponses, watchBoardResponses, watchPrivatePeek, watchVideoCallRequest, watchCurrentVideoCalls, watchUserCurrentVideoCall, admitPerson, addConversationFollowerAsync, addConversationMemberAsync, askToMoveMessageAsync, watchMeFollow, followUserAsync, unFollowUserAsync, watchRoomTyping, watchPrivateTypingIndicator, acceptPublication } from '../data/data';
import { DefaultImage, FixedTouchable, LinkText, BottomArrow, WideButton, formatTime, parseDataUrl, getFileExtension, ConIcon, MyBoardIcon, UserIcon, HoverLink, IconForUser, formatTimeAgo, HeaderSpaceView, BackButton, isWeb, SmallButton, getDiffs, PeopleList, getMessagePrivateTo, isMessageVisible, getNameOrYour, getNameOrYou, getRoomName, shouldMessageReplyBePublic } from '../components/basics';
import { getAppLoadingLifecycleEmitter } from 'expo/build/launch/AppLoading';
import { MessageEntryBox } from '../components/messageentrybox';
import { Catcher } from '../components/catcher';
import { BottomScroller, getTimeNow, BottomFlatScroller } from '../components/shim';
import { Entypo, Ionicons, FontAwesome, MaterialIcons } from '@expo/vector-icons';
import _ from 'lodash';
import { pickImage, compressImage, } from '../components/photo';
import { uploadImage } from '../data/servercall';
import { newKey, getUrlForImage, internalReleaseWatchers } from '../data/fbutil';
import { global_showPhotoPopup, PhotoPopup } from '../components/photopopup';
import { UserIntro, UserIntroScreen } from './UserIntro';
import { reloadIfVersionChanged } from '../data/versioncheck';
import { UserPopupMenu } from '../components/popupmenu';
// import { PersonSelectorPopDown } from './PersonSelector';
import { NewConversation } from './NewConversation';
import { MeetingInfo } from './MeetingInfo';
import { HelpBox } from '../components/help';
import { GroupIcon, UserOrRoomIcon } from '../components/groupicon';
import { Message, indexResponses } from './Message';
import { KeyboardSafeView } from '../components/keyboardsafeview.';
import { EnableNotifsBanner } from './NotifPermission';
// import { NewPostScreen } from './NewPost';
import { getFocusDate } from '../components/dates';
// import { SetRoomNameScreen } from './SetName';
import { FeedScreen } from './Feed';
import { ReportAbuseScreen } from './ReportAbuse';
import { NotifBanner } from '../components/notifbanner';


const minuteMillis = 1000 * 60;


export function ChatScreen({meeting, user, mode, replyToMessage, replyToMessageInfo, expandTime, navigation}) {
  return (
    <KeyboardSafeView style={{flex: 1}}>
      <HeaderSpaceView style={{flex:1 }}>
        <EnableNotifsBanner />
        <NotifBanner meeting={meeting} navigation={navigation} />
        <UserChat meeting={meeting} user={user} mode={mode}
            expandTime={expandTime}
            navigation={navigation} 
            replyToMessage={replyToMessage}
            replyToMessageInfo={replyToMessageInfo}
            onGotoUser={({user, mode, replyToMessage, replyToMessageInfo, replyTime}) => {
              navigation.push('chat', {user, mode, meeting, replyToMessageInfo, replyToMessage, replyTime})
            }}
            onGotoConversation={({user}) => navigation.push('chat',{meeting,user})} />
        <PhotoPopup />            
      </HeaderSpaceView>
    </KeyboardSafeView>
  )
}


function ChatHeader({attendees, iAmHost, isFollowing, meeting, user, userInfo, onGotoUser, navigation}) {
  const name = _.get(userInfo,'name','User').trim();  
  const roomName = _.get(userInfo, 'roomName');
  const firstName = name.split(' ')[0];
  return (
    <View style={{zIndex: 10, flexDirection: 'row', flex: 1,
        alignItems: 'center', justifyContent: 'space-between'}}>
      <View style={{flexShrink: 1, flexDirection: 'row', alignItems: 'center'}} >
        <UserOrRoomIcon size={30} attendees={attendees} user={user} userInfo={userInfo} />
        <FixedTouchable onPress={() => onGotoUser({user, mode: 'info'})} >    
          <View style={{marginLeft: 8}}>
            {userInfo.host ?
              <View>
                <Text numberOfLines={1} style={{flexShrink: 1, fontSize: 16}}>
                  {name}
                </Text>
                <Text numberOfLines={1} style={{flexShrink: 1, fontSize: 13, color: '#666'}}>
                  {userInfo.isModerated ? 
                    'moderated by ' + getNameOrYou({user: userInfo.host, attendees})
                  :
                    'unmoderated'
                  }
                </Text>
              </View>
            :
              <Text numberOfLines={1} style={{flexShrink: 1, fontSize: 20}}>
                {user == global_currentUser ? 'Your Room' : (name + "'s Room")}
              </Text>
            }
          </View>
        </FixedTouchable>
      </View>
      <View style={{flexDirection: 'row', alignItems: 'center', zIndex: 20}} >
        {(isFollowing && user != global_currentUser) ? 
          <SmallButton part='unfollow' onPress={()=>unFollowUserAsync({meeting, user})}
            style={{marginTop: 0}}>
            Unfollow
          </SmallButton>
        : null}
        {/* <UserPopupMenu meeting={meeting} user={user} iAmHost={iAmHost} navigation={navigation} onGotoUser={onGotoUser} /> */}
      </View>
    </View>
  )
}

function YourRoomInfo() {
  return (
    <HelpBox id='yourboard' title="This is Your Room">
      <Text style={{fontSize: 15, color: '#666'}}>
        Each person in the gathering has their own room where they moderate the conversation.
      </Text>
      <Text style={{fontSize: 15, color: '#666', marginVertical: 8}}>
        Use this space to talk about whatever is on your mind.
      </Text>
    </HelpBox>
  )
}

function TheirRoomInfo({firstName}) {
  return (
    <HelpBox id='theirboard' title={"This is " + firstName + "'s Room"}>
      <Text style={{fontSize: 15, color: '#666'}}>
        Each person in the gathering has their own room where they moderate the conversation.
      </Text>
      <Text style={{fontSize: 15, color: '#666', marginVertical: 8}}>
        You can submit messages to this room, but {firstName} chooses which messages get seen by others.
      </Text>
    </HelpBox>
  )
}

function TopicRoom({userInfo}) {
  return (
    <HelpBox id='topicroom' title={"This is a Topic Room"}>
      <Text style={{fontSize: 15, color: '#666'}}>
        Anyone can create a room about a particular topic.
      </Text>
      {userInfo.isPublic ? 
        <Text style={{fontSize: 15, color: '#666', marginVertical: 8}}>
          This room is visible to all people in the gathering, but you can also create rooms that are only visible to people you invite.
        </Text>      
      : 
        <Text style={{fontSize: 15, color: '#666', marginVertical: 8}}>
          This room is visible only to people invited by the host.
        </Text>
      }
    </HelpBox>
  )
}



function wordSplit(text, style) {
  return text.split(' ').map((word,i) => 
    <Text key={i} style={style}>{word} </Text>
  )
}

function PrivateChatInfo({user, userInfo, firstName}) {
  // const title = user == global_currentUser ? 'This is your room' : 'This is ' + firstName + "'s room";
  const title = 'Messages can be public or private'
  const host = userInfo.host || user;
  const hostName = userInfo.host ? 'the moderator' : firstName;
  const submitPerson = host == global_currentUser ? 'someone other than you' : 'you';
  const hostPublish = host == global_currentUser ? 'you publish them' : (hostName + ' publishes them');
  return (
    <HelpBox title={title} id='private-chat'>
      {/* <View style={{marginBottom: 12, flexDirection: 'row', alignItems: 'center'}}>
      </View> */}

      <View style={{marginVertical: 4, flexDirection: 'row', justifyContent: 'flex-start', flexWrap: 'wrap', alignItems: 'center'}}>
        <View style={{paddingVertical: 4, marginBottom: 4, paddingHorizontal: 8, 
                borderColor: '#ddd', borderWidth: StyleSheet.hairlineWidth,
                marginRight: 4, backgroundColor: 'white', borderRadius: 16,
                elevation: 3, shadowOpacity: 0.5, shadowRadius: 2, shadowColor: '#555', shadowOffset: {width: 0, height: 1},
                }}>
          <Text>Messages in white</Text>
        </View>
        {wordSplit('are visible to everyone', {color: '#666', fontSize: 15})}
      </View>

      <View style={{marginVertical: 4, flexDirection: 'row', justifyContent: 'flex-start', flexWrap: 'wrap', alignItems: 'center'}}>
        <Text style={{color:'#666'}}>Messages</Text>
        <View style={{marginLeft: 4, flexGrow: 0, paddingVertical: 4, paddingHorizontal: 8, marginRight: 4, backgroundColor: 'black', borderRadius: 16}}>
          <Text style={{color: 'white'}}>in black</Text>
        </View> 
        {wordSplit('are private whispers between yourself and another person', {color: '#666', fontSize: 15})}
      </View>      

      <View style={{marginVertical: 4, flexDirection: 'row', justifyContent: 'flex-start', flexWrap: 'wrap', alignItems: 'center'}}>
        <View style={{paddingVertical: 4, marginBottom: 4, paddingHorizontal: 8, 
                borderColor: '#ddd', borderWidth: StyleSheet.hairlineWidth,
                marginRight: 4, backgroundColor: '#eee', borderRadius: 16,
                elevation: 3, shadowOpacity: 0.5, shadowRadius: 2, shadowColor: '#555', shadowOffset: {width: 0, height: 1},
                }}>
          <Text>Messages in grey</Text>
        </View>
        {wordSplit('were submitted by ' + submitPerson + ' and become public only if ' + hostPublish, {color: '#666', fontSize: 15})}
      </View>

     
    </HelpBox>
  )
}

function ChatInfo({firstName, userInfo, user}) {

  return (
    <View>
      {userInfo.room ? 
        <TopicRoom userInfo={userInfo} />
      : 
      (user == global_currentUser ? 
        <YourRoomInfo />
      : <TheirRoomInfo firstName={firstName} /> )
      }
      <PrivateChatInfo firstName={firstName} userInfo={userInfo} user={user} />
    </View>
  )

  // return <PrivateChatInfo user={user} firstName={firstName} />

  // if (isBoard) {
  //   return <BoardInfo />
  // } else {
  // }
}


const dayMillis = 1000 * 60 * 60 * 24;

function PersonTypingState({user, typingState, lastMessagePerPerson, attendees}) {
  const lastTypeTime = _.get(lastMessagePerPerson, user, 0);
  const fiveMinutesAgo = getTimeNow() - (5 * minuteMillis);

  if (user == global_currentUser) {
    return null;
  }

  if (typingState && typingState.time) {
    if (typingState.time > lastTypeTime && typingState.time > fiveMinutesAgo) {
      if (typingState.to == user) {
        return (
          <Text style={{color: '#666'}}>
           {getNameOrYou({user, attendees})} is typing...
          </Text>
        )
      } else if (!_.get(attendees, [typingState.to, 'room'])){
        return (
          <Text style={{color: '#666'}}>
           {getNameOrYou({user, attendees})} is talking in {getRoomName({user: typingState.to, attendees})}
          </Text>
        )
      } else if (_.get(attendees, [typingState.to, 'isPublic']) || _.get(attendees, [typingState.to, 'members', global_currentUser])) {
        return (
          <Text style={{color: '#666'}}>
            {getNameOrYou({user, attendees})} is talking in {getRoomName({user: typingState.to, attendees})}
          </Text>
        )
      } else {
        return (
          <Text style={{color: '#666'}}>
            {getNameOrYou({user, attendees})} is talking in a private room
          </Text>
        )
      }
    } else {
      return null;
    }
  } else {
    return null;
  }
}

function RoomTypingState({lastMessagePerPerson, typingState, roomTyping, user, privateTyping, attendees}) {
  const typingUsers = Object.keys(roomTyping || {});
  const minuteAgo = getTimeNow() - minuteMillis;
  const activeTyping = typingUsers.filter(u => 
    roomTyping[u] > _.get(lastMessagePerPerson,u,0) 
    && roomTyping[u] > minuteAgo
    && u != global_currentUser);
  var sortedTypers = _.sortBy(activeTyping, u => roomTyping[u]);

  // console.log('RoomTypingState', {activeTyping, roomTyping, privateTyping});

  if (activeTyping.length == 0) {
    return null;
  }

  // if (activeTyping.length == 0) {
  //   if (privateTyping) {
  //     const lastTypeTime = _.get(lastMessagePerPerson, user, 0);
  //     if (lastTypeTime < privateTyping) {
  //       sortedTypers = [user];
  //     } else {
  //       return null;
  //     }
  //   } else {
  //     return null;
  //   }
  // }

  return (
    <View style={{flexDirection: 'row', marginBottom: 12, marginHorizontal: 16}}>
      <PeopleList users={sortedTypers} attendees={attendees} style={{color: '#666'}} />
      <Text style={{color: '#666'}}>
        {activeTyping.length == 1 ? ' is' : ' are'} typing...
      </Text>
    </View>
  )
}


function getLastMessagePerPerson({sortedMessageKeys, allMessages}) {
  var lastMessageTimes = {};
  sortedMessageKeys.forEach(m => {
    const messageInfo = allMessages[m];
    lastMessageTimes[messageInfo.from] = messageInfo.time;
  })
  return lastMessageTimes;
}

function MessageList({sortedMessageKeys, sortedAllMessageKeys, allMessages, isHost, focusDate, roomTyping, privateTyping, privatePeek, needsToJoin, showBoard, msgResponses, meeting, userIntro, userInfo, attendees, messages, boardMessages, broadcastMessages, user, firstName, isBoard, typingState, scrollRef, onReply, onPublish, onDelete, onEdit, onShare, onGotoConversation}) {
  // const monthAgoTime = getTimeNow() - (30 * dayMillis);
  // const allMessages = {...broadcastMessages, ..._.get(messages,'out', {}), ..._.get(messages,'in', {}), ...boardMessages};
  // const sortedAllMessageKeys = _.sortBy(Object.keys(allMessages || {}), m => allMessages[m].time); // .slice(0, 100);  
 
  // const visibleMessageKeys = _.filter(sortedAllMessageKeys, 
  //     m => isMessageVisible({user, attendees, userInfo, messageInfo: allMessages[m], expireTime: monthAgoTime})).slice(-100);
  // const sortedMessageKeys = visibleMessageKeys;
  // const sortedMessageKeys = _.sortBy(visibleMessageKeys, m => allMessages[m].time).slice(-100); // .slice(0, 100);  
  if (!boardMessages || !messages) {
    return <View style={{flex: 1, justifyContent: 'center'}}><Text style={{textAlign: 'center'}}>loading...</Text></View>
  }

  const lastMessagePerPerson = getLastMessagePerPerson({sortedMessageKeys:sortedAllMessageKeys, allMessages});

  return (
    <BottomFlatScroller style={{flex: 1, flexShrink: 1}} ref={r => scrollRef(r)} data={[
      {key: 'userIntro', value: () => 
        <UserIntro meeting={meeting} user={user} isHost={isHost} userIntro={userIntro} userInfo={userInfo} />
      },
      {key: 'chatIntro', value: () =>
        <ChatInfo user={user} userInfo={userInfo} firstName={firstName} isBoard={isBoard} showBoard={showBoard} />
      },
      {key: 'disappear', value: () => 
        <Text style={{color: '#666', textAlign: 'center', marginHorizontal: 16, marginVertical: 12}}>
          Messages disappear after 30 days.
        </Text>
      },
      ...sortedMessageKeys.map((m,k) =>
        ({key: m, value: () => 
          <Message key={m} message={m} messageInfo={allMessages[m]} 
              firstName={firstName} user={user} isBoard={isBoard}
              prevMessageInfo={allMessages[sortedMessageKeys[k-1]]} meeting={meeting}
              nextMessageInfo={allMessages[sortedMessageKeys[k+1]]}
              msgResponses={msgResponses} userInfo={userInfo}
              attendees={attendees}
              typingState={typingState} focusDate={focusDate}
              onPublish={(publish) => onPublish({publish, message: m, messageInfo: allMessages[m]})}
              onReply={replyToAll => onReply({message:m, messageInfo: allMessages[m], replyToAll})} 
              onDelete={() => onDelete({message: m, messageInfo: allMessages[m]})}
              onEdit={() => onEdit({message: m, messageInfo: allMessages[m]})}
              onShare={({selectedCon, selectedConInfo}) => onShare({selectedCon, selectedConInfo, message:m, messageInfo: allMessages[m]})}
              onGotoConversation={onGotoConversation}
              />
        })
      ),
      {key: 'spacer', value: () => 
        <View style={{height: 12}} />
      },
      {key: 'typing', value: () =>
        <View>
          <RoomTypingState userInfo={userInfo} typingState={typingState} roomTyping={roomTyping} user={user} privateTyping={privateTyping} attendees={attendees} lastMessagePerPerson={lastMessagePerPerson} />
          {/* <PersonTypingState user={user} typingState={typingState} attendees={attendees} lastMessagePerPerson={lastMessagePerPerson} /> */}
        </View>
        // (userInfo.room ? 
        // :
        //   <View style={{marginBottom: 12, marginHorizontal: 16}}>
        //   </View>
        // )
      }
    ]} />
  )
}

export class UserChat extends React.Component {
  state = {uploadCount: 0, boardMessages: null, broadcastMessages: null, messages: null, userInfo: null, replyToMessage: null, replyToMessageInfo: null}
  async componentDidMount() {
    const {meeting, user, replyToMessage, replyToMessageInfo} = this.props;
    watchMeetingUserInfo(this, {meeting, user}, userInfo => this.setState({userInfo}));
    // watchMeetingConversationInfo(this, {meeting, conversation: user}, conInfo => this.setState({conInfo}));
    watchMessages(this, {meeting, user}, messages => {this.setState({messages}); this.scrollToEnd()});
    watchBoardMessages(this, {meeting, user}, boardMessages => this.setState({boardMessages}));
    // watchBoardMessages(this, {meeting, user: global_currentUser}, myBoardMessages => this.setState({myBoardMessages}));
    watchTypingIndicator(this, {meeting, user}, typingState => this.setState({typingState}));
    watchPrivateTypingIndicator(this, {meeting, user}, privateTyping => this.setState({privateTyping}));
    watchRoomTyping(this, {meeting, user}, roomTyping => this.setState({roomTyping}));
    watchMeetingAttendees(this, meeting, attendees => this.setState({attendees}));
    watchMeetingInfo(this, meeting, meetingInfo => this.setState({meetingInfo}));
    watchUserIntroduction(this, {meeting, user}, userIntro => this.setState({userIntro}));
    watchResponses(this, {meeting, user}, responses => this.setState({responses}));
    watchBoardResponses(this, {meeting, user}, boardResponses => this.setState({boardResponses}));
    watchPrivatePeek(this, {meeting, user}, privatePeek => this.setState({privatePeek}));
    watchUserCurrentVideoCall(this, {meeting, user}, videoCall => this.setState({videoCall}));
    watchMeFollow(this, {meeting, user}, meFollow => this.setState({meFollow}));

    if (user == global_currentUser) {
      watchBoardMessages(this, {meeting, user: 'broadcast'}, broadcastMessages => this.setState({broadcastMessages}));
    } else {
      this.setState({broadcastMessages: {}});
    }
    if (replyToMessage) {
      this.setState({replyToMessage, replyToMessageInfo});
    }

    reloadIfVersionChanged();
  }
  componentWillUnmount(){
    internalReleaseWatchers(this);
  }

  scrollToEnd(){
    if (this.scroller) {
      this.scroller.safariScrollToEnd();
    }
  }
  async sendMessage({text, isPublic, photo, replyToMessage, replyToMessageInfo, editedMessage, editedMessageInfo}) {
    const {user, meeting} = this.props;
    const {userInfo, meFollow} = this.state;
    // const {replyToMessage, replyToMessageInfo} = this.state;
    const isBoard = user == global_currentUser || (isPublic && !replyToMessageInfo);
    const isBroadcast = user == 'broadcast';
    // console.log('sendMessage', text, user, replyToMessageInfo, selectedCon, selectedConInfo, isPublic);

    const privateTo = getMessagePrivateTo({user, userInfo, replyToMessageInfo, isPublic});

    var message;
    this.setState({replyToMessage: null, replyToMessageInfo: null, hasReply: null, showBoard: false});

    // if (userInfo && selectedCon && !selectedConInfo.isPublic && !_.get(selectedConInfo, ['members', user])) {
    //   const pMember = addConversationMemberAsync({meeting, conversation: selectedCon, user});      
    //   await addConversationFollowerAsync({meeting, conversation: selectedCon, user});
    //   await pMember;
    // } else if (replyToMessageInfo && selectedCon) {
    //   await addConversationFollowerAsync({meeting, conversation: selectedCon, user: replyToMessageInfo.from});
    // }

    if (!editedMessage && isPublic && replyToMessage && replyToMessageInfo.isPublic && !replyToMessageInfo.approved && (user == global_currentUser || userInfo.host == global_currentUser)) {
      await acceptPublication({meeting, message: replyToMessage, messageInfo: replyToMessageInfo});
    }

    if (editedMessage) {
      await editMessageAsync({meeting, photo, message: editedMessage, messageInfo: editedMessageInfo, text, requestPublic: isPublic && user != global_currentUser});
    } else if ((text && text.trim()) || photo) {
      const result = await sendMessageAsync({meeting, isModerated: userInfo.isModerated, 
        roomHost: userInfo.host, isRoom: userInfo.host, isPublic, privateTo, 
        requestPublic: isPublic && !isBoard, isBoard, isBroadcast, user, photo, 
        text, replyToMessageInfo, replyToMessage});
      // if (conInfo && !_.get(conInfo,['followers', global_currentUser])) {
      //   await followConversationAsync({meeting, conversation: user});
      // } 
      const pRead = await markUserReadAsync({meeting, user});

      // if (selectedCon) {
      //   await askToMoveMessageAsync({meeting, message: result.message, messageInfo: result.messageInfo,
      //       user, userInfo, conInfo, toCon: selectedCon, toConInfo:selectedConInfo});
      // }
      // if (isPublic && !replyToMessageInfo) {
      //   acceptPublication({meeting, message: result.message, messageInfo: result.messageInfo});
      // }
      await pRead;
    }

    if (!isPublic) {
      setTypingIndicatorAsync({meeting, user, userInfo, isTyping: false});
    }

    if (!meFollow) {
      followUserAsync({meeting, user});
    }
  }

  // async sendPhoto(uri) {
  //   // console.log('sendPhoto', uri);
  //   const {uploadCount} = this.state;
  //   this.setState({uploadCount: uploadCount + 1});
  //   // console.log('about to parse');
  //   const {contentType, base64data} = parseDataUrl(uri);    
  //   // console.log('parsed', contentType, base64data);
  //   const key = newKey('photo');
  //   await uploadImage({base64data, contentType, key, userId: global_currentUser})
  //   const fileExtension = getFileExtension(contentType);
  //   const photo = global_currentUser+'/' + key + fileExtension;
  //   // const imageUrl = getUrlForImage(photo);
  //   await this.sendMessage({photo});
  //   // this.setState({uploadCount: this.state.uploadCount - 1});
  // }

  render() {
    const {expandTime, meeting, user, navigation, onGotoConversation, mode, onGotoUser} = this.props;
    const {hasReply, privatePeek, roomTyping, privateTyping, responses, boardResponses, meFollow, triedBoard, videoCall, myBoardMessages, userIntro, meetingInfo, attendees, uploadCount, userInfo, boardMessages, broadcastMessages, messages, replyToMessageInfo, replyToMessage, typingState, showBoard} = this.state;
    const isBoard = user == global_currentUser;
    // const needsToJoin = conInfo && !_.get(conInfo,['members',global_currentUser]);
    // const isFollowing = !conInfo || _.get(conInfo,['followers', global_currentUser]); 
    const isFollowing = meFollow || user == global_currentUser; // || _.get(conInfo,['followers', global_currentUser]);
    const firstName = _.get(userInfo,'name','User').split(' ')[0];
    const toName = _.get(userInfo,'name', 'Someone');
    const msgResponses = indexResponses({..._.get(responses,'in'), ..._.get(responses,'out'), ...boardResponses});

    const iAmHost = _.get(meetingInfo,'host') == global_currentUser;
    const needsAdmit = iAmHost && user != global_currentUser && !userInfo.admitted;
    const focusDate = getFocusDate(meetingInfo);

    // console.log('render Chat', meeting, user, replyToMessage, replyToMessageInfo, this.props);

    if (user == 'newconvo') {
      return <NewConversation navigation={navigation} meeting={meeting} onGotoUser={onGotoUser}/>
    } else if (user == 'meetinginfo') {
      return <MeetingInfo navigation={navigation} meeting={meeting} onGotoUser={onGotoUser} />
    // } else if (user == 'newpost') {
    //   return <NewPostScreen navigation={navigation} meeting={meeting} onGotoUser={onGotoUser} />
    } else if (user == 'feed') {
      return <FeedScreen navigation={navigation} meeting={meeting} onGotoUser={onGotoUser} />
    // } else if (mode == 'nameroom') {
    //   return <SetRoomNameScreen navigation={navigation} meeting={meeting} user={user} onGotoUser={onGotoUser} />
    } else if (mode == 'info' && userInfo && userInfo.room) {
      return <NewConversation room={user} meeting={meeting} navigation={navigation} onGotoUser={onGotoUser} />
    } else if (mode == 'info' && userInfo) {
      return <UserIntroScreen userIntro={userIntro} userInfo={userInfo} user={user} meeting={meeting} isHost={iAmHost} navigation={navigation} onGotoUser={onGotoUser} />
    } else if (mode == 'report') {
      return <ReportAbuseScreen user={user} meeting={meeting} navigation={navigation} />
    }

    if (meFollow == null || userIntro == null || meetingInfo == null || attendees == null || userInfo == null || boardMessages == null || messages == null) {
      return null;
    }

    const monthAgoTime = getTimeNow() - (30 * dayMillis);
    const allMessages = {...broadcastMessages, ..._.get(messages,'out', {}), ..._.get(messages,'in', {}), ...boardMessages};
    const sortedAllMessageKeys = _.sortBy(Object.keys(allMessages || {}), m => allMessages[m].time); // .slice(0, 100);  
   
    const visibleMessageKeys = _.filter(sortedAllMessageKeys, 
        m => isMessageVisible({user, attendees, userInfo, messageInfo: allMessages[m], expireTime: monthAgoTime})).slice(-100);
    const sortedMessageKeys = visibleMessageKeys;

    const lastMessage = sortedMessageKeys[sortedMessageKeys.length -1];
    var defaultReplyMessage = null;
    var defaultReplyMessageInfo = null;
    if (lastMessage) {
      if (allMessages[lastMessage].to == user && !shouldMessageReplyBePublic({messageInfo: allMessages[lastMessage], user})) {
        defaultReplyMessage = lastMessage;
        defaultReplyMessageInfo = allMessages[lastMessage];
        // defaultReplyMessageInfo = {...allMessages[lastMessage], replyToMessageInfo: null};
      }
    }
  
      
    return (
      <View style={{flex: 1, backgroundColor: 'white'}}> 
        <Catcher style={{zIndex: 10}}>
          <View style={{padding: 4, flexDirection: 'row', borderBottomColor: '#ddd', borderBottomWidth: StyleSheet.hairlineWidth}}>
            <BackButton navigation={navigation} />
            <ChatHeader part='header' user={user} meeting={meeting} attendees={attendees} userInfo={userInfo || this.props.userInfo} 
                showBoard={showBoard} onToggleBoard={showBoard => this.setState({showBoard, triedBoard: true})}
                videoCall={videoCall} iAmHost={iAmHost} navigation={navigation}
                onGotoUser={onGotoUser} 
                isFollowing={isFollowing}
                isBoard={isBoard} isBroadcast={user == 'broadcast'}  />
          </View>
        </Catcher>
        {!isFollowing && !needsAdmit ?
          <View style={{flexDirection: 'row', alignItems: 'center', backgroundColor: 'white', padding: 8, borderBottomColor: '#ddd', borderBottomWidth: StyleSheet.hairlineWidth}}>
            <WideButton part='follow' style={{margin: 0}} onPress={()=>followUserAsync({meeting, user})}>
              Follow
            </WideButton>
            <Text style={{marginLeft: 8, flex: 1, fontSize: 15, color: '#666'}}>
              Get notified about new messages
            </Text>
          </View>
        : null }
        {needsAdmit ?
          <View style={{flexDirection: 'row', alignItems: 'center', backgroundColor: '#fceea6', padding: 8, borderBottomColor: '#ddd', borderBottomWidth: StyleSheet.hairlineWidth}}>
            <WideButton onPress={()=>admitPerson({meeting, user})} style={{marginVertical: 0}}>
              Admit
            </WideButton>
            <Text style={{marginLeft: 8, flex: 1, fontSize: 15, color: '#666'}}>
              Let {firstName} join the gathering
            </Text>
          </View>
        : null}

        <Catcher style={{flex: 1}}>
          <MessageList 
            allMessages={allMessages}
            sortedMessageKeys={sortedMessageKeys} sortedAllMessageKeys={sortedAllMessageKeys}
            boardMessages={boardMessages} messages={messages} firstName={firstName} isBoard={isBoard} 
            showBoard={showBoard} focusDate={focusDate}
            roomTyping={roomTyping} privateTyping={privateTyping}
            msgResponses={msgResponses} privatePeek={privatePeek}
            attendees={attendees} userIntro={userIntro} isHost={meetingInfo.host == global_currentUser}
            typingState={typingState} user={user} broadcastMessages={broadcastMessages}
            // onPublish={({publish, message, messageInfo}) => requestPublication({meeting, user, message, messageInfo, publish})}
            // askToCopyMessageToBoardAsync({meeting, user, conInfo, message, messageInfo})}
            onDelete={({message, messageInfo}) => deleteMessage({meeting, user, message, messageInfo})}
            onEdit={({message, messageInfo}) => this.entryBox.editMessage({message, messageInfo})}
            // onShare={({message, messageInfo, selectedCon, selectedConInfo}) => 
            //   askToMoveMessageAsync({meeting, user, userInfo, conInfo, 
            //     toConInfo: selectedConInfo, toCon: selectedCon, 
            //     message, messageInfo})}
            onGotoConversation={onGotoConversation} meeting={meeting}
            scrollRef={r => {this.scroller = r}} uploadCount={uploadCount}
            userInfo={userInfo}
            onReply={({message, messageInfo, replyToAll}) => {
              if (messageInfo.from != global_currentUser && !replyToAll) {
                onGotoUser({user: messageInfo.from, replyToMessage: message, 
                  replyToMessageInfo: messageInfo, 
                  replyTime: getTimeNow()})   
              } else {
                this.setState({
                  hasReply: true,
                  replyToMessage: message, 
                  replyToMessageInfo: messageInfo
                });
                if (this.entryBox) {
                  this.entryBox.focus();
                }  
              }
            }}
          />
        </Catcher>
        <Catcher>
        <MessageEntryBox part='entry' saveKey={user}
            ref={r => {this.entryBox = r}} 
            replyToMessage={hasReply ? replyToMessage : defaultReplyMessage} 
            replyToMessageInfo={hasReply ? allMessages[replyToMessage] : allMessages[defaultReplyMessage]} 
            meeting={meeting} user={user} 
            attendees={attendees} userInfo={userInfo}
            expandTime={expandTime}
            destName={isBoard ? 'Everyone' : firstName}
            placeholder={isBoard ? 'Write a public post' : 'Write a private message'} 
            onStartTyping={() => {
              setTypingIndicatorAsync({meeting, toName, user, userInfo, isTyping:true}); 
              this.scroller.safariScrollToEnd();
              if (!hasReply) {
                this.setState({replyToMessage: defaultReplyMessage, replyToMessageInfo: defaultReplyMessageInfo, hasReply: true})
              }
            }}
            onEndTyping={() => {
              setTypingIndicatorAsync({meeting, toName, user, userInfo, isTyping:false}); 
              this.scroller.safariScrollToEnd();
              if (!replyToMessage) {
                this.setState({hasReply: false});
              }                              
            }}
            onClearReply={() => this.setState({replyToMessage: null, replyToMessageInfo: null, hasReply: true})}
            onFocusChange={() => {this.scrollToEnd();}}
            // onSendPhoto={url => this.sendPhoto(url)}
            // onSendGroup={group => this.sendGroup(group)}
            onTextEntered={({text, isPublic, photo, editedMessage, editedMessageInfo, replyToMessage, replyToMessageInfo})=>
                this.sendMessage({text, photo, replyToMessage, replyToMessageInfo, editedMessage, editedMessageInfo, isPublic})} />
        </Catcher>
      </View>
    )
  }
}

