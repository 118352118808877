import React from 'react';
import {Text, View, ScrollView, StyleSheet, Platform} from 'react-native';
import { watchMeetingAttendees, watchMyMeetingChatPeeks, global_currentUser, watchMeetingInfo, watchMyName, markUserReadAsync, watchReadTimes, watchBoardPeeks, markSelfPresent, markSelfAbsent, watchPresence, watchMyMeetings, watchMyConversations, watchConversations, setCurrentUserName, followConversationAsync, watchAllTyping, global_currentUserName, watchMyMeetingSecret, getMyMeetingSecretAsync, watchCurrentVideoCalls, leaveMeetingAsync, watchFollows, watchNotifToken, markSelfActiveAsync } from '../data/data';
import { JoinChat } from './JoinChat';
import { SetName } from './SetName';
import { FixedTouchable, DefaultImage, formatTimeAgo, formatTime, FixedWebTouchable, ConIcon, MyBoardIcon, doesTextMatchPrefix, ScreenContentScroll, UserIcon, VideoCallIcon, getRepeatString, HeaderSpaceView, BackButton, isWeb, Loading, WideButton, getNameOrYou, getNameOrYour, formatDate } from '../components/basics';
import { Catcher } from '../components/catcher';
import { UserChat } from './Chat';
import { fakeWebNavigation, playAlertSound, watchAppFocus, watchAppBlur, setTitle, setFavicon, TitleBlinker, getTimeNow, setBeaconUrl, ShareBox, ShareScreen } from '../components/shim';
import { Ionicons, Entypo, FontAwesome } from '@expo/vector-icons';
import _ from 'lodash';
import { PhotoPopup } from '../components/photopopup';
import { SearchBox } from '../components/searchbox';
import { SharePopup } from './ConvoSelector';
import { NotStartedBanner } from '../components/notstartedbanner';
import { GroupIcon, UserOrRoomIcon } from '../components/groupicon';
import { makeLeaveMeetingUrl } from '../data/servercall';
import { getFocusDate } from '../components/dates';
import { VideoRingPopup, VideoPanel, VideoReceiverPreview } from '../components/video';
import { ErrorBanner } from '../components/error';
import { baseColor } from '../data/config';
import { internalReleaseWatchers } from '../data/fbutil';
import { EnableNotifsBanner } from './NotifPermission';
import { PairChat } from './PairChat';
import { MobileRequired } from './MobileRequired';
import { NotifBanner } from '../components/notifbanner';

const minuteMillis = 1000 * 60;
const hourMillis = minuteMillis * 60;
const dayMillis = hourMillis * 24;


// function BroadcastPreview({boardpeek, selected, onPress}) {
//   return (
//     <FixedTouchable onPress={onPress}>
//       <View style={{flexDirection: 'row', padding: 8, borderRadius: 8, alignItems: 'center',
//           backgroundColor: selected ? '#eee' : 'white'}}>
//         <Ionicons name='ios-radio' size={40} style={{width: 40, textAlign: 'center'}} color='black' />
//         <View style={{marginLeft: 8, flexShrink: 1, flex: 1}}>
//           <Text style={{flex: 1, fontSize: 15, fontWeight: '300'}}>
//             Broadcast Prompts
//           </Text>
//           <Text numberOfLines={1} style={{color: '#999', fontSize: 13, marginTop: 2}}>
//             {_.get(boardpeek, 'text')}
//           </Text>
//         </View>
//       </View>
//     </FixedTouchable>
//   )
// }

// function BoardPreview({boardpeek, userInfo, broadcastPeek, readTime, selected, onPress, onNew}) {
//   const unread = _.get(broadcastPeek,'time',0) > readTime;
//   const broadcastLatest = _.get(broadcastPeek,'time',0) > _.get(boardpeek,'time',0);
//   const latestPeek = (unread || broadcastLatest)? broadcastPeek : boardpeek;
//   const dayAgo = getTimeNow() - dayMillis;

//   const prefix = (unread || broadcastLatest) ? 'Broadcast: ' : '';
//   return (
//     <View style={{flexDirection: 'row', alignItems: 'center', marginRight: 4}}>
//       <View style={{flex: 1}}>
//         <FixedTouchable onPress={onPress} style={{flex: 1}}>
//           <View style={{flexDirection: 'row', padding: 8, borderRadius: 8, alignItems: 'center',
//               backgroundColor: selected ? '#eee' : 'white',
//               }}>
//             <MyBoardIcon userInfo={userInfo} size={50} />
//             <View style={{marginLeft: 8, flexShrink: 1, flex: 1, justifyContent: 'flex-start'}}>
//               <Text style={{fontSize: 15, fontWeight: unread ? '500' : '300'}}>
//                 Your Room
//               </Text>
//               {latestPeek && latestPeek.time > dayAgo ?
//                 <View style={{
//                   borderRadius: 8, borderColor: '#ddd',
//                   borderWidth: StyleSheet.hairlineWidth,
//                   paddingHorizontal: 6, paddingVertical: 4, marginTop: 2}}>
//                   <Text numberOfLines={1} style={{color: '#999', fontSize: 13, marginTop: 2}}>
//                     {prefix + _.get(latestPeek, 'text','')}
//                   </Text>
//                 </View>
//               :
//                 <Text numberOfLines={1} style={{fontWeight: 'bold', fontSize: 12, marginTop: 2}}>
//                   Write posts for others to see
//                 </Text>
//               }
//             </View>
//           </View>
//         </FixedTouchable>
//       </View>
//       {/* <FixedTouchable onPress={() => onPress(true)} title='Write New Post'>
//         <View style={{backgroundColor: '#f5f5f5', width: 36, height: 36, borderRadius: 10,
//               alignItems: 'center', justifyContent: 'center', marginLeft: 8}}>
//           <Entypo name='new-message' size={24} color='black' />
//         </View>
//       </FixedTouchable> */}
//     </View>
//   )
// }

// function VideoCallPreview({selected, userInfo, onPress}) {
//   if (!userInfo) {
//     return null;
//   }
//   return (
//     <View style={{flexDirection: 'row', alignItems: 'center', marginRight: 4}}>
//       <View style={{flex: 1}}>
//         <FixedTouchable onPress={onPress} style={{flex: 1}}>
//           {/* {selected ?  */}
//             <View style={{flexDirection: 'row', padding: 8, borderRadius: 8, alignItems: 'center',
//                 backgroundColor: selected ? '#eee' : '#6AE91A',
//                 }}>
//               <VideoCallIcon userInfo={userInfo} size={40} />
//               <View style={{marginLeft: 8, flexShrink: 1, flex: 1}}>
//                 <Text style={{flex: 1, fontSize: 15, fontWeight: '700', color: selected ? 'black' : 'white'}}>
//                   Video: {userInfo.name}
//                 </Text>
//                 <Text numberOfLines={1} style={{color: selected ? '#999' : 'white', fontWeight: '500', fontSize: 13, marginTop: 2}}>
//                   Video call with {userInfo.name}
//                 </Text>
//               </View>
//             </View>
//           {/* : null} */}
//           {/* <VideoReceiverPreview shown={!selected} /> */}
//         </FixedTouchable>
//       </View>
//     </View>
//   )
// }

function UserImage({name, attendeeInfo, isCon, attendees, isActive = true}) {
  // if (isCon) {
  //   return (
  //     <View style={{width: 50, height: 50, justifyContent: 'center', alignItems: 'center', opacity: isActive ? undefined : 0.5}}>
  //       <FontAwesome size={40} name='group' color='#666' />
  //     </View>
  //     // <GroupIcon size={50} conInfo={attendeeInfo} attendees={attendees} />
  //   )
  // } else {
    return (
      <UserOrRoomIcon attendees={attendees} userInfo={attendeeInfo} size={50} isActive={isActive} 
          style={{opacity: isActive ? null : 0.4}} />
    )
  // }
}


function TypingPreview({typingState, attendees}) {
  if (!typingState) {
    return null;
  } else if (typingState.to != global_currentUser && typingState.time > (getTimeNow() - (5 * minuteMillis))) {
    const targetInfo = _.get(attendees, typingState.to, {});
    const mid =  targetInfo.room ? 'Talking in' : 'Talking to'
    const isPrivate = targetInfo.room && !targetInfo.isPublic && !_.get(targetInfo, ['members', global_currentUser]);
    const name = isPrivate ? 'a private room' : targetInfo.name;
    return <Text numberOfLines={1} style={{marginTop: 2, fontSize: 13, color: '#999', fontWeight: '300'}}>{mid} {name}</Text>
  } else {
    return null;
  }
}


function UserPeekLine({typing, followed, user, boardpeek, chatpeek, readTime, attendees, attendeeInfo, isCon, isPresent, time}) {
  const meFirst = _.get(chatpeek,['out','time'],0) > _.get(chatpeek,['in','time'],0);
  const latestPeek = meFirst ? _.get(chatpeek,'out') : _.get(chatpeek,'in');
  const unread = !meFirst && readTime < _.get(latestPeek,'time',0);
  const showBoardPeek = !unread && _.get(boardpeek,'time', 0) > _.get(latestPeek,'time',0);
  const unreadBoard = !readTime || readTime < _.get(boardpeek,'time', 0);
  const boldBoard = followed && unreadBoard;

  const name = _.get(attendeeInfo,'name','User');
  const firstName = name.split(' ')[0];
  const monthAgo = getTimeNow() - (dayMillis * 30);
  var shownPeek = showBoardPeek ? boardpeek : latestPeek;
  if (!shownPeek) {
    // if (conInfo) {
    //   shownPeek = {text: 'Talk to Everyone', time: 0}
    // } else 
    // console.log('XXX missing peek', attendeeInfo.name);
    if (attendeeInfo.host) {
      // console.log('no peek', attendeeInfo.name, {user, attendeeInfo, shownPeek, latestPeek, chatpeek, boardpeek});
      shownPeek = {from: attendeeInfo.host, text: 'new room created', time: _.get(attendeeInfo,'time')}
    } else {
      shownPeek = {from: user, text: firstName + ' joined the gathering', time: _.get(attendeeInfo,'time')}
    }
  }
  const dayAgo = getTimeNow() - dayMillis;
  const peekIsStale = shownPeek.time < monthAgo;
  const text = _.get(shownPeek, 'text', _.get(shownPeek, 'photo') ? 'photo' : '(empty)');
  const from = _.get(shownPeek, 'from');
  const author = from == global_currentUser ? 'You' : _.get(attendees,[from,'name'],'').split(' ')[0]

  if (typing && !unread && !unreadBoard && typing.to != global_currentUser && typing.to != user && typing.time > (getTimeNow() - (5 * minuteMillis))) {
    return <TypingPreview typingState={typing} attendees={attendees} />
  } else if (peekIsStale && !attendeeInfo.host) {
    return <Text style={{color: '#999', fontSize: 13, marginTop: 2}}>{firstName} joined the Gathering</Text>
  } else if (peekIsStale) {
    return <Text style={{color: '#999', fontSize: 13, marginTop: 2}}>New room created</Text>
  } else if (showBoardPeek) {
    // const showLoud = _.get(conInfo, ['members', global_currentUser]) && unreadBoard;
    const showLoud = boldBoard;
    return (
      <View style={{
            borderRadius: 8, borderColor: showLoud ? '#666' : '#ddd',
            borderWidth: StyleSheet.hairlineWidth,
            paddingHorizontal: 6, paddingVertical: 4, marginTop: 2}}>
        <Text numberOfLines={1} style={{
            fontSize: 13, color: showLoud ? '#222' : '#666',
            fontWeight: showLoud ? '700' : undefined}}>
          {author + ': ' + text}
        </Text>
      </View>
    )
  } else {
  // } else if (isCon) {
    const from = _.get(latestPeek, 'from');
    const author = from == global_currentUser ? 'You' : _.get(attendees,[from,'name'],'').split(' ')[0]
    // const isFollowing = _.get(conInfo,['followers',global_currentUser]);
    const bold = unread || !readTime;
    // const bold = ((_.get(conInfo,['followers',global_currentUser]) || user == global_currentUser) && unread) || !readTime;

    return (
      <Text numberOfLines={1} style={{color: bold ? 'black' : '#999',
          fontWeight: bold ? '700' : undefined, fontSize: 13, marginTop: 2}}>
        {(author ? (author + ': ') : '') + text}
      </Text>
    )
  // } else {
  //   const fromMe = _.get(latestPeek, 'from') == global_currentUser;

  //   return (
  //     <Text numberOfLines={1} style={{color: (unread && !fromMe) ? 'black' : '#999',
  //         fontWeight: (unread && !fromMe) ? '700' : undefined, fontSize: 13, marginTop: 2}}>
  //       {(fromMe ? 'You: ' : '') + text}
  //     </Text>
  //   )
  }
}


function FeedIcon() {
  return (
    <FontAwesome name='feed' size={30} style={{width: 50, height: 30, textAlign: 'center'}} />
  )
}


function FeedPreview({selected, onPress}) {
  return (
    <FixedTouchable onPress={onPress}>
      <View style={{flexDirection: 'row', padding: 8, borderRadius: 8, alignItems: 'center',
            backgroundColor: selected ? '#eee' : 'white', marginRight: 4
          }}>
        <FeedIcon />
        <View style={{marginLeft: 8, flexShrink: 1, flex: 1}}>
          <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end'}}>
            <Text numberOfLines={1} style={{flex: 1, fontSize: 15, fontWeight: '300'}}>
              Feed
            </Text>
          </View>
        </View>
      </View>
    </FixedTouchable>
  )
}


function UserPreview({typing, conInfo, attendees, followed, videoCall, attendeeInfo, isCon, isPresent, 
      presentTime, user, isHost, readTime, selected, chatpeek, boardpeek, 
      focusDate, prevAttendeeTime, time,
      onPress}) {
  const meFirst = _.get(chatpeek,['out','time'],0) > _.get(chatpeek,['in','time'],0);
  const latestPeek = meFirst ? _.get(chatpeek,'out') : _.get(chatpeek,'in');
  const isFollowing = true; // !conInfo || _.get(conInfo, ['followers', global_currentUser]);
  const unread = !meFirst && readTime < _.get(latestPeek,'time',0);
  const unreadBoard = followed && readTime < _.get(boardpeek,'time',0);
  const bold = unread;
  // const bold = unread || unreadBoard || !readTime;
  // const bold = (isFollowing && unread) || !readTime;
  const name = _.get(attendeeInfo,'name','User').trim();
  const roomName = _.get(attendeeInfo, 'roomName');
  const showBoardPeek = !unread && _.get(boardpeek,'time', 0) > _.get(latestPeek,'time',0);

  var shownPeek = showBoardPeek ? boardpeek : latestPeek;
  const from = _.get(shownPeek, 'from');
  const author = from == global_currentUser ? 'You' : _.get(attendees,[from,'name'],'Someone').split(' ')[0]
  const isWaiting = !isCon && !attendeeInfo.admitted && isPresent;

  const dayAgo = getTimeNow() - dayMillis;

  if (!shownPeek) {
    // if (!conInfo) {
    //   shownPeek = {text: 'Joined the gathering', time: _.get(attendeeInfo,'time')}
    // } else {
      shownPeek = {text: 'Talk to everyone', time: attendeeInfo.time}
    // }
  }

  const isActive = presentTime > dayAgo;
  const earlyStart = focusDate - hourMillis;


  const needsMeetingDivider = new Date(prevAttendeeTime || getTimeNow()).getDate() != new Date(time).getDate(); 

  // const needsMeetingDivider = earlyStart < getTimeNow() 
  //   && time < earlyStart && (!prevAttendeeTime || prevAttendeeTime > earlyStart);

 
  return (
    <View>
      {needsMeetingDivider ?
         <View style={{marginTop: 16, marginBottom: 16, paddingTop: 16, borderTopColor: '#ddd', borderTopWidth: StyleSheet.hairlineWidth, alignItems: 'center', alignSelf: 'stretch'}}>
           <Text style={{color: '#666'}}>{formatDate(time)}</Text>
         </View>
      : null}
      <FixedTouchable onPress={onPress}>
        <View style={{flexDirection: 'row', padding: 8, borderRadius: 8, alignItems: 'center',
              backgroundColor: selected ? '#eee' : (isWaiting ? '#fceea6' : 'white'), marginRight: 4
            }}>
          <UserImage name={name} isPresent={isPresent} isCon={isCon} attendeeInfo={attendeeInfo} attendees={attendees} isActive={isActive} />
          <View style={{marginLeft: 8, flexShrink: 1, flex: 1, opacity: isPresent ? null : 0.5}}>
            {attendeeInfo.host ?
              <View style={{marginBottom: 2, flex: 1}}>
                <Text numberOfLines={1} style={{textDecorationLine: isPresent ? null : 'line-through', flex: 1, fontSize: 14, fontWeight: bold ? '700' : '300'}}>
                  {name}
                </Text>
                <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end'}}>
                  <Text numberOfLines={1} style={{flex: 1, fontSize: 12, color: '#666', fontWeight: '300'}}>
                    {'hosted by ' + getNameOrYou({user: attendeeInfo.host, attendees})}
                  </Text>
                  <Text style={{color: "#999", flexShrink: 0, fontSize: 12}}>{formatTime(time,{short:true})}</Text>
                </View>
              </View>
            :
              <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end'}}>
                <Text numberOfLines={1} style={{color: isActive ? null : '#666', textDecorationLine: isPresent ? null : 'line-through', flex: 1, fontSize: 15, fontWeight: bold ? '700' : '300'}}>
                  {user == global_currentUser ?
                    'Your Room'
                  :
                    name + "'s Room" + (isHost ? ' (Host)' : '') + (isWaiting ? ' (Waiting)' : '')
                  }
                </Text>
                <Text style={{color: "#999", flexShrink: 0, fontSize: 12}}>{formatTime(time,{short:true})}</Text>
              </View>
            }
            <UserPeekLine typing={typing} user={user} boardpeek={boardpeek} chatpeek={chatpeek}
              attendees={attendees} videoCall={videoCall} followed={followed} time={time}
              readTime={readTime} attendeeInfo={attendeeInfo} isCon={isCon} isPresent={isPresent} />
          </View>
        </View>
      </FixedTouchable>
    </View>
  )
}

function NewConversationButton({onPress}) {
  return (
    <FixedTouchable onPress={onPress}>
      <View style={{flexDirection: 'row', padding: 8, borderRadius: 8, alignItems: 'center', marginRight: 4}}>
        <Entypo name='plus' size={30} color='#0084ff' style={{width: 50, textAlign: 'center', marginRight: 8}} />
        <Text style={{fontSize: 15, fontWeight: '300'}}>
          New Room
        </Text>
      </View>
    </FixedTouchable>
  )
}

function MeetingInfoBox({meetingInfo, selected, navigation, onPress}) {
  const date = new Date(getFocusDate(meetingInfo));
  const dateString = date.toLocaleDateString(undefined, {weekday: 'short', month: 'short', day: 'numeric'});
  const timeString = date.toLocaleTimeString(undefined, {hour: 'numeric', minute: 'numeric'});
  const repeatString = getRepeatString(meetingInfo.repeat);
  return (
    <FixedTouchable onPress={onPress}>
      <View style={{flexDirection: 'row'}}>
        <BackButton navigation={navigation} />
        <View style={{flex: 1, paddingHorizontal: 8, marginRight: 4, paddingBottom: 4,
              paddingVertical: Platform.OS == 'web' ? 4 : 0,
              borderRadius: 8, backgroundColor: selected ? '#eee' : 'white'}}>
          <View style={{flexDirection: 'row',
                alignItems: Platform.OS == 'web' ? 'flex-start' : 'center', justifyContent: 'space-between'}}>
            <Text numberOfLines={1} style={{fontSize: 24, fontWeight: '500', marginBottom: 4}}>{meetingInfo.name}</Text>
            <Ionicons name='ios-settings' size={24} color='#999' />
          </View>
          <Text style={{color: '#666'}}>
            {repeatString}
            {repeatString ?
              <Entypo name='dot-single' />
            : null}
            {dateString} <Entypo name='dot-single' />
            {timeString}
          </Text>
        </View>
      </View>
    </FixedTouchable>
  )
}

export class UserListScreen extends React.Component {
  state = {}
  componentDidMount() {
    const {meeting} = this.props;
    watchPresence(this, meeting, presence => this.setState({presence}));
    watchMeetingAttendees(this, meeting, attendees => this.setState({attendees}))
    watchMeetingInfo(this, meeting, meetingInfo => this.setState({meetingInfo}));
    watchMyName(this, myName => {
      this.setState({myName});
      setCurrentUserName(myName);
    });
  }
  componentWillUnmount() {
    internalReleaseWatchers(this);
  }

  render() {
    const {meeting, navigation} = this.props;
    const {attendees, myName, meetingInfo} = this.state;

    const dayAgo = getTimeNow() - dayMillis;

    if (!attendees || !meetingInfo || !myName) {
      return <Loading/>
    } else if (myName != null && typeof(myName) !== 'string') {
      return <SetName />
    } else if (!meetingInfo.name) {
      // console.log('meetingInfo', meeting, meetingInfo);
      return <NoSuchMeeting navigation={navigation} />
    } else if (!attendees[global_currentUser] || !attendees[global_currentUser].present || attendees[global_currentUser].present < dayAgo) {
      return <JoinChat navigation={navigation} meeting={meeting} myName={myName} meetingInfo={meetingInfo} />
    } else {
      return (
        <HeaderSpaceView>
          <EnableNotifsBanner />
          <NotifBanner meeting={meeting} navigation={navigation} />
          <UserList callingUser={null} selectedUser={null} meeting={meeting}
              navigation={navigation}
              onSelectUser={async ({user, pairUser, nameroom, expand, replyToMessage, replyToMessageInfo}) => {
                await navigation.push(pairUser ? 'pair' : 'chat', {meeting, user, pairUser,
                  replyToMessage, replyToMessageInfo, nameroom,
                  expandTime: expand ? getTimeNow() : 0});
                await markUserReadAsync({meeting, user})
              }}
        />
        </HeaderSpaceView>
      )
    }
  }
}



class UserList extends React.PureComponent {
  state = {presence: null}
  componentDidMount() {
    const {meeting} = this.props;
    watchPresence(this, meeting, presence => this.setState({presence}));
    watchMeetingAttendees(this, meeting, attendees => this.setState({attendees}));
    watchConversations(this, meeting, conversations => this.setState({conversations}));
    // watchConPeeks(this, meeting, conpeeks => this.setState({conpeeks}));
    watchMyConversations(this, meeting, myConversations => this.setState({myConversations}));
    watchBoardPeeks(this, meeting, boardpeeks => this.setState({boardpeeks}));
    watchMyMeetingChatPeeks(this,meeting, chatpeeks => this.setState({chatpeeks}));
    watchMeetingInfo(this, meeting, meetingInfo => this.setState({meetingInfo}));
    watchReadTimes(this, meeting, readTimes => this.setState({readTimes}));
    watchAllTyping(this, meeting, allTyping => this.setState({allTyping}));
    watchCurrentVideoCalls(this, meeting, videoCalls => this.setState({videoCalls}));
    watchFollows(this, meeting, follows => this.setState({follows}));
  }
  componentWillUnmount(){
    internalReleaseWatchers(this);
  }


  render(){
    const {meeting, callingUser, selectedUser, pairUser, onSelectUser, navigation} = this.props;
    const {follows, allTyping, search, readTimes, meetingInfo, presence, boardpeeks, chatpeeks, attendees, conpeeks, conversations, myConversations, videoCalls} = this.state;

    const iAmHost = _.get(meetingInfo,'host') == global_currentUser;

    // var conversations = this.state.conversations;
    // if (!conversations || Object.keys(conversations).length == 0) {
    //   conversations = {everyone: {name: 'Everyone'}};
    // } else {
    //   conversations = {...conversations, everyone: {name: 'Everyone'}}
    // }

    if (!attendees || !chatpeeks || !meetingInfo || !readTimes || !boardpeeks || !conversations || !myConversations) {
      if (isWeb) {
        return <View style={{width: 300}} />;
      } else {
        return (
          <View style={{flex:1, justifyContent: 'center', alignItems: 'center'}}>
            <Text>loading...</Text>
          </View>
        )
      }
    }

    const focusDate = getFocusDate(meetingInfo);

    // const weekAgo = getTimeNow() - (7 * dayMillis);
    // const dayAgo = getTimeNow() - dayMillis;
    const monthAgo = getTimeNow() - (30 * dayMillis);

    const myAttendeeInfo = attendees[global_currentUser];
    const iAmAdmitted = _.get(myAttendeeInfo, 'admitted') || iAmHost;

    const otherAttendees = _.filter(Object.keys(attendees), u =>
        u != global_currentUser && 
        presence[u] > monthAgo &&
        // presence[u] > (_.get(attendees, [u, 'room']) ? monthAgo : dayAgo) &&
        _.get(attendees, [u, 'present']) &&
        (!_.get(attendees, [u, 'room']) || _.get(attendees, [u, 'isPublic']) || _.get(attendees, [u, 'members', global_currentUser])) &&
        (iAmHost || _.get(attendees, [u, 'admitted'])));

    const attendeeTimes = _.mapValues(attendees, (info,u) => 
      _.max([_.get(boardpeeks,[u,'time'],0),
            // - (_.get(follows, [u, global_currentUser]) ? 0 : 5 * minuteMillis)),
        _.get(attendees,[u,'time'],0),
        _.get(chatpeeks,[u,'in','time'],0),
        _.get(chatpeeks,[u,'out','time'],0)
      ])
    );

      

    const sortedAttendees = _.sortBy(otherAttendees, u => attendeeTimes[u]).reverse();

    // const sortedAttendees = _.sortBy([...otherAttendees, ...visibleConversations], u =>
    //     _.max([_.get(boardpeeks,[u,'time'],0),
    //               // - (_.get(follows, [u, global_currentUser]) ? 0 : 5 * minuteMillis)),
    //           _.get(attendees,[u,'time'],0),
    //           _.get(chatpeeks,[u,'in','time'],0),
    //           _.get(chatpeeks,[u,'out','time'],0)
    //           // _.get(conversations,[u, 'members', global_currentUser, 'time'], 0),
    //           // ((!_.get(conversations,u) || _.get(conversations,[u, 'followers', global_currentUser])) ?
    //           //   _.get(chatpeeks,[u,'in','time'],0) : _.get(chatpeeks,[u,'in','time'],0) - (5 * minuteMillis)),
    //           // _.get(chatpeeks,[u,'out','time'],0)
    //         ]
    //         )).reverse();
    let sortedVisibleAttendees;
    if (search) {
      sortedVisibleAttendees = _.filter(sortedAttendees, a =>
          doesTextMatchPrefix(_.get(attendees,[a,'name']) || _.get(conversations,[a,'name']), search));
    } else {
      sortedVisibleAttendees = sortedAttendees;
    }

    if (!iAmAdmitted) {
      sortedVisibleAttendees = [meetingInfo.host];
    }

    return (
      <View style={{
            width: Platform.OS == 'web' ? 300 : undefined,
            flex: Platform.OS == 'web' ? undefined : 1,
            backgroundColor: 'white', padding: 4,
            borderRightColor: '#ddd', borderRightWidth: StyleSheet.hairlineWidth}}>
        <Catcher>
          <MeetingInfoBox part='meetinginfo' meetingInfo={meetingInfo} navigation={navigation}
              selected={selectedUser == 'meetinginfo'}
              onPress={()=>onSelectUser({user:'meetinginfo'})} />
          {/* <FixedTouchable onPress={()=>onSelectUser({user: 'video'})}>
            <VideoReceiverPreview key='video-preview' shown={callingUser && selectedUser != 'video'} />
          </FixedTouchable> */}
        </Catcher>
        <ScrollView>
          {/* {iAmAdmitted ?
            <SearchBox part='user-search' search={search} onChangeText={search => this.setState({search})} />
          : null} */}
          {/* <FixedTouchable onPress={()=>onSelectUser({user:Platform.OS == 'web' ? 'newpost' : global_currentUser, expand:true})} >
            <View style={selectedUser == 'newpost' ? {backgroundColor: '#eee', paddingHorizontal: 8} : {paddingHorizontal: 8}} >
              <View style={{
                  // borderColor: '#ddd', borderWidth: StyleSheet.hairlineWidth,
                  backgroundColor: '#f5f5f5', marginVertical: 10, marginHorizontal: 8,
                  paddingHorizontal: 12, paddingVertical: 8, borderRadius: 16}}>
                <Text style={{fontSize: 16, color: '#666'}}>What's on your mind?</Text>
              </View>
            </View>
          </FixedTouchable>
 */}

          {/* {callingUser ?
            <Catcher>
              <VideoCallPreview userInfo={attendees[callingUser]} selected={selectedUser == 'video'}
                  onPress={()=>onSelectUser({user: 'video'})} />
            </Catcher>
          : null} */}
          {/* {search || (meetingInfo.host != global_currentUser) ? null :
            <Catcher>
              <BroadcastPreview part='broadcast' boardpeek={boardpeeks['broadcast']}
                selected={selectedUser == 'broadcast'}
                onPress={()=>onSelectUser({user:'broadcast'})}
              />
            </Catcher>
          } */}
          <NewConversationButton part='newconvo' onPress={()=>onSelectUser({user: 'newconvo'})}/>
          <FeedPreview part='feed' selected={selectedUser == 'feed'} 
            onPress={() => onSelectUser({user:'feed'})}
          />
          {/* {search ? null : */}
            <Catcher key='board'>
              <UserPreview part='board' user={global_currentUser} selected={selectedUser == global_currentUser && !pairUser}
                  attendeeInfo={attendees[global_currentUser]}
                  attendees={attendees} 
                  time={attendeeTimes[global_currentUser]}
                  followed
                  attendeeTimes={attendeeTimes}
                  readTime={_.get(readTimes,global_currentUser,0)}
                  chatpeek={chatpeeks[global_currentUser]} boardpeek={boardpeeks[global_currentUser]}
                  isPresent
                  // isPresent={conversations[a] || (_.get(attendees,[a,'present']) && (_.get(presence,a,0) > getTimeNow() - minuteMillis))}
                  presentTime={_.get(presence,global_currentUser,0)}
                  onPress={()=>onSelectUser({user:global_currentUser})} />

              {/* <BoardPreview part='board' selected={selectedUser == global_currentUser && !pairUser}
                onPress={expand=>onSelectUser({user:global_currentUser,expand})}
                userInfo={attendees[global_currentUser]}
                readTime={_.get(readTimes, global_currentUser, 0)} boardpeek={boardpeeks[global_currentUser]}
                onNew={()=>onSelectUser({user:'newtopic'})}
                broadcastPeek={boardpeeks['broadcast']} /> */}
            </Catcher>
          {sortedVisibleAttendees.map((a,k) =>
            <Catcher key={a}>
                <UserPreview part={a} user={a} selected={selectedUser == a && !pairUser}
                    attendeeInfo={attendees[a]} 
                    focusDate={focusDate} prevAttendeeTime={_.get(attendeeTimes,sortedVisibleAttendees[k-1])}
                    attendees={attendees}
                    time={attendeeTimes[a]}
                    typing={_.get(allTyping,a)}
                    followed={_.get(follows,[a,global_currentUser])}
                    videoCall={_.get(videoCalls, a)}
                    isBoard={false} isHost={meetingInfo.host == a}
                    readTime={_.get(readTimes,a,0)} chatpeek={chatpeeks[a]} boardpeek={boardpeeks[a]}
                    // broadcastPeek={a == global_currentUser ? boardpeeks['broadcast'] : null}
                    isPresent
                    // isPresent={conversations[a] || (_.get(attendees,[a,'present']) && (_.get(presence,a,0) > getTimeNow() - minuteMillis))}
                    presentTime={_.get(presence,a,0)}
                    onPress={()=>onSelectUser({user:a})} />
            </Catcher>
          )}
          {!iAmAdmitted ?
            <NotAdmitted />
          : 
            <ShareBox meeting={meeting} meetingInfo={meetingInfo} onSelect={()=>onSelectUser({user:'invite'})} />
          }
        </ScrollView>
        {/* <View style={{borderTopColor: '#ddd',
            flexDirection: 'row', justifyContent: 'space-around',
            borderTopWidth: StyleSheet.hairlineWidth, alignItems: 'center'}}>
          {/* <WideButton>Leave</WideButton> */}
          {/* <LeaveButton meeting={meeting} navigation={navigation} />
          <ShareBox meeting={meeting} meetingInfo={meetingInfo} onSelect={()=>onSelectUser({user:'invite'})} />
        </View> */}
      </View>
    )
  }
}

function LeaveButton({meeting, navigation}) {
  return (
    <FixedTouchable onPress={()=>{
        leaveMeetingAsync(meeting);
        if (!isWeb) {
          navigation.goBack();
        }
      }}>
      <View style={{margin: 8, paddingVertical: 8, paddingHorizontal: 12,
          // backgroundColor: '#f5f5f5',
          // borderRadius: 4,
          flexDirection: 'row', alignItems: 'center',
          borderColor: '#ddd', borderWidth: StyleSheet.hairlineWidth
          }}>
        <Ionicons name='ios-walk' size={20} style={{marginRight: 8}} />
        <Text style={{fontSize: 16}}>Leave</Text>
      </View>
    </FixedTouchable>
  )
}


function NotAdmitted() {
  return (
    <View style={{backgroundColor: '#FFCB2F', marginTop: 16, paddingHorizontal: 16, paddingVertical: 10, borderRadius: 16}}>
      <Text style={{fontSize: 16, fontWeight: '600'}}>You are not yet admitted</Text>
      <Text style={{marginTop: 4}}>
        Until you are admitted, you will only be able to interact with the host, and write posts.
      </Text>
      <Text style={{marginTop: 4}}>
        Once you are admitted, you will be able to see all people present and all active conversations.
      </Text>
    </View>
  )
}

export class Meeting extends React.Component {
  state = {attendees: null, boardpeeks: null, chatpeeks: null, meetingInfo: null, myName: null, selectedUser: null, readTimes: null, presence: null};
  lastUpdate = 0;
  timeStarted = 0;

  async componentDidMount() {
    const {meeting} = this.props;
    watchMeetingAttendees(this, meeting, async attendees => {
      this.setState({attendees})
      this.maybeMarkSelfPresent();
      // if (_.get(attendees,[global_currentUser,'present'])) {
      //   await markSelfPresent({meeting});
      // }
    });
    watchConversations(this, meeting, conversations => this.setState({conversations}));
    watchMyMeetingChatPeeks(this,meeting, chatpeeks => this.setState({chatpeeks}));
    watchMeetingInfo(this, meeting, meetingInfo => {
      this.maybeMarkSelfPresent();
      // if (meetingInfo && meetingInfo.name) {
      //   this.keepSelfMarkedPresent();
      // }
      this.setState({meetingInfo})
    });
    watchBoardPeeks(this, meeting, boardpeeks => this.setState({boardpeeks}));
    watchReadTimes(this, meeting, readTimes => this.setState({readTimes}));
    watchPresence(this, meeting, presence => this.setState({presence}));
    watchFollows(this, meeting, follows => this.setState({follows}));
    watchMyName(this, myName => {
      this.setState({myName});
      setCurrentUserName(myName);
    });
    this.setState({selectedUser: global_currentUser});
    this.timeStarted = getTimeNow();
    watchAppFocus(() => this.onWindowFocussedAsync());
    watchAppBlur(() => this.setState({focussed: false}));
    // watchNotifToken(this, notifToken => this.setState({notifToken}));

    this.keepSelfMarkedPresent();

    this.onSelectUser = async ({user, pairUser=null, mode=null, nameRoom=false, replyToMessage, replyToMessageInfo, expand}) => {
      // const {conversations} = this.state;
      // console.log('onSelectUser', user, nameRoom);
      this.setState({selectedUser: user, pairUser, mode, nameRoom, replyToMessage, replyToMessageInfo, expandTime: expand ? getTimeNow() : 0});
      // const conInfo = _.get(conversations, user);
      // if (conInfo && !_.get(conInfo,['followers',global_currentUser]) &&
      //     !_.get(conInfo,['members', global_currentUser])) {
      //   await followConversationAsync({meeting, conInfo, conversation: user});
      // }
      // console.log('select user mark read', user);
      // if (this.videoPanel) {
      //   this.videoPanel.setSelectedUser(user);
      // }
      await markUserReadAsync({meeting, user});
    }

    watchMyMeetingSecret(this, meeting, secret => {
      setBeaconUrl(makeLeaveMeetingUrl({meeting, secret}));
    })
  }

  setVideoCall({callingUser, sessionId, callMode='called'}) {
    this.setState({callingUser, sessionId, callMode});
    this.onSelectUser({user: 'video'});
    this.videoRingPopup.setCallingUser({callingUser});
  }

  async maybeMarkSelfPresent() {
    const {meeting} = this.props;
    const {attendees, meetingInfo} = this.state;
    const dayAgo = getTimeNow() - dayMillis;
    if (!attendees || !meetingInfo || !attendees[global_currentUser] || !attendees[global_currentUser].present || attendees[global_currentUser].present < dayAgo) {
      // DO NOTHING - still needs to join
    } else {
      await markSelfPresent({meeting});
    }
  }

  async keepSelfMarkedPresent() {
    this.maybeMarkSelfPresent();

    setTimeout(() => this.keepSelfMarkedPresent(), 1000 * 30);
  }

  async onWindowFocussedAsync() {
    const {meeting} = this.props;
    const {selectedUser, meetingInfo} = this.state;
    if (meetingInfo) {
      setTitle(meetingInfo.name + ' - Talkbeat')
    }
    this.setState({focussed: true});
    this.setState({lastFocusTime: getTimeNow()});
    // console.log('window focused user read', selectedUser);
    if (meetingInfo && meetingInfo.name) {
      await markUserReadAsync({meeting, user:selectedUser});
      await markSelfActiveAsync(meeting);
    }
  }

  // TODO: Merge this with UserList computation of visible attendees
  countUnread() {
    const {chatpeeks, attendees, boardpeeks, readTimes, follows, lastFocusTime, meetingInfo, presence} = this.state;
    if (!chatpeeks || !readTimes || !boardpeeks || !follows || !attendees || !meetingInfo || !presence) {
      return 0;
    } else {
      // const followedBoards = _.filter(Object.keys(boardpeeks || {}), c => _.get(follows,[c,global_currentUser]));
      const iAmHost = _.get(meetingInfo,'host') == global_currentUser;
      const monthAgo = getTimeNow() - (30 * dayMillis);

      const otherAttendees = _.filter(Object.keys(attendees), u =>
        // u != global_currentUser && 
        presence[u] > monthAgo &&
        // presence[u] > (_.get(attendees, [u, 'room']) ? monthAgo : dayAgo) &&
        _.get(attendees, [u, 'present']) &&
        (!_.get(attendees, [u, 'room']) || _.get(attendees, [u, 'isPublic']) || _.get(attendees, [u, 'members', global_currentUser])) &&
        (iAmHost || _.get(attendees, [u, 'admitted'])));

      const followedOthers = _.filter(otherAttendees, u => 
        _.get(follows, [u, global_currentUser])
      )

      const unreadPeeks = _.filter(followedOthers, p =>
      // const unreadPeeks = _.filter(Object.keys(attendees), p =>

        _.max([_.get(chatpeeks, [p,'in','time'],0), _.get(boardpeeks, [p,'time'])])
        >
        _.max([_.get(chatpeeks, [p,'out','time'],0), _.get(readTimes,p,0), lastFocusTime]));
      // console.log('unread',unreadPeeks);
      if (unreadPeeks[0]) {
        const unread = unreadPeeks[0];
        // console.log('why unread', {unread, attendee: attendees[unread], lastFocusTime, 
        //     boardPeek: boardpeeks[unread], follows,
        //     readTime: readTimes[unread], chatpeek: chatpeeks[unread]})
      } else {
        // console.log('nothing unread', {lastFocusTime, otherAttendees, unreadPeeks});
      }
      return unreadPeeks.length;
    }
  }

  // requestFullScreen() {
  //   // const node = findDOMNode(this);
  //   // node.requestFullScreen();
  // }

  render() {
    const {meeting} = this.props;
    const {presence, focussed, attendees, boardpeeks, chatpeeks, meetingInfo,
      replyToMessage, replyToMessageInfo, conversations, expandTime, follows,
      callingUser, sessionId, callMode, pairUser, notifToken,
      myName, selectedUser, readTimes, nameRoom, mode} = this.state;

    // console.log('render', {selectedUser, nameRoom});

    const followedBoards = _.filter(Object.keys(boardpeeks || {}), c => _.get(follows,[c,global_currentUser]));
    const lastBoardUpdate = _.max(followedBoards.map(u => _.get(boardpeeks, [u,'time'],0)));
    const followedChats = _.filter(Object.keys(chatpeeks || {}), c => !conversations[c] || _.get(conversations,[c,'followers', global_currentUser]));
    const lastChatUpdate = _.max(followedChats.map(u => _.get(chatpeeks, [u,'in','time'],0)));
    const lastUpdate = _.max([lastChatUpdate, lastBoardUpdate]);
    // console.log('boards', {boardpeeks, follows});
    // console.log('pings', {followedBoards, lastBoardUpdate, followedChats, lastChatUpdate, lastUpdate: this.lastUpdate});
    if (lastUpdate > this.lastUpdate && !focussed) {
      if (lastUpdate > this.timeStarted) {
        playAlertSound();
      }
    }
    this.lastUpdate = lastUpdate;


    // const weekAgo = getTimeNow() - (dayMillis * 7);
    const dayAgo = getTimeNow() - dayMillis;

    if (!attendees || !myName || !meetingInfo) {
      return (
        <View style={{flex:1, justifyContent: 'center', alignItems: 'center'}}>
          <Text>loading...</Text>
        </View>
      )
    // } else if (!notifToken) {
    //   return <MobileRequired />
    } else if (!meetingInfo.name) {
      // console.log('meetingInfo', meeting, meetingInfo);
      return <NoSuchMeeting />
    } else if (typeof(myName) != 'string') {
      return <SetName />
    // } else if (!_.get(attendees,[global_currentUser,'present'])) {
    } else if (!attendees[global_currentUser] 
          || !attendees[global_currentUser].name
          || !attendees[global_currentUser].present 
          || attendees[global_currentUser].present < dayAgo) {
      return <JoinChat meeting={meeting} myName={myName} meetingInfo={meetingInfo} />
    } else {
      return (
        <View style={{flex: 1, backgroundColor: 'white'}}>
          <Catcher>
            <NotStartedBanner meeting={meeting} />
          </Catcher>
          <Catcher>
            <ErrorBanner />
          </Catcher>
          <View style={{flexDirection: 'row', flex: 1}}>
            <UserList part='userlist' meeting={meeting} iAmHost={meetingInfo.host == global_currentUser}
              selectedUser={selectedUser} pairUser={pairUser} callingUser={callingUser} onSelectUser={this.onSelectUser} />
            <Catcher>
              <TitleBlinker focussed={focussed} count={this.countUnread()} title={_.get(meetingInfo,'name') + ' - Talkbeat'} />
            </Catcher>
            <View style={{flex: 1}}>
              {/* <Catcher style={{flex: 1}}>
                <VideoHack />
              </Catcher> */}
              {selectedUser == 'invite' ? <ShareScreen meeting={meeting} /> :
                <Catcher style={{flex: 1}}>
                  {/* {pairUser ?
                    <PairChat key={selectedUser + '-' + pairUser} user={selectedUser} pairUser={pairUser}
                      meeting={meeting} navigation={fakeWebNavigation}
                      onGotoUser={this.onSelectUser}
                      onGotoConversation={this.onSelectUser}
                    />
                  : */}
                    <UserChat key={selectedUser} meeting={meeting} user={selectedUser} userInfo={attendees[selectedUser]}
                      replyToMessage={replyToMessage} replyToMessageInfo={replyToMessageInfo}
                      iAmHost={meetingInfo.host === global_currentUser} expandTime={expandTime}
                      navigation={fakeWebNavigation} nameRoom={nameRoom} mode={mode}
                      // onCallUser={this.onCallUser}
                      onGotoConversation={this.onSelectUser} onGotoUser={this.onSelectUser}
                    />
                  {/* } */}
                </Catcher>
              }
            </View>
            <PhotoPopup />
            {/* <SharePopup meeting={meeting} /> */}
            {/* <VideoPanel key='video-panel' ref={ref => this.videoPanel = ref}
                onRequestFullScreen={() => this.requestFullScreen()}
                onSelectUser={args => this.onSelectUser(args)}
                onSetVideoCall={args => this.setVideoCall(args)}
            />
            <VideoRingPopup key='video-ring' ref={r => this.videoRingPopup = r} meeting={meeting}
                onRequestFullScreen={() => this.requestFullScreen()}
                onSelectUser={args => this.onSelectUser(args)}
                onSetVideoCall={args => this.setVideoCall(args)} /> */}
          </View>
        </View>
      )
    }
  }
}

function NoSuchMeeting() {
  return (
    <ScreenContentScroll>
      <View style={{flex: 1, alignSelf: 'center', maxWidth: 500, padding: 10, backgroundColor: 'white', borderColor: '#eee', borderWidth: StyleSheet.hairlineWidth, marginTop: 32, marginHorizontal: 8}}>
        <Text style={{fontSize: 24, textAlign: 'center'}}>No Such Gathering</Text>
        <Text style={{marginTop: 4, textAlign: 'center', color: '#666'}}>
          The URL you entered does not correspond to a known Talkbeat meeting.
        </Text>
      </View>
    </ScreenContentScroll>
  )
}
