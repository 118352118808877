import React from 'react';
import {Text, View, StyleSheet, ScrollView } from 'react-native';
import { ScreenContentScroll, WideButton, FormInput, HeaderSpaceView, BackButton, isWeb } from '../components/basics';
import { setMyName, setRoomName, watchRoomName } from '../data/data';
import { KeyboardSafeView } from '../components/keyboardsafeview.';
import { internalReleaseWatchers } from '../data/fbutil';

const nameRegex = /^\s*([A-Za-z.]{2,}([.,] |[-']| ))+([A-Za-z. ])*[A-Za-z]{2,}\.?\s*$/

export function isValidName(name) {
  return name.match(nameRegex);
}

export class SetName extends React.Component {
  state = {name: null, nameError: false}

  async onSetNamePressed() {
    const {name} = this.state;
    if (name && isValidName(name)) {
      await setMyName(name);
    } else {
      this.setState({nameError: true});
    }
  }

  render() {
    const {name, nameError} = this.state;
    return (
      <ScreenContentScroll>
        <View style={{flexDirection: 'row'}}>        
          <View style={{flex: 1, maxWidth: 500, padding: 10, backgroundColor: 'white', borderColor: '#eee', borderWidth: StyleSheet.hairlineWidth, marginTop: 32, marginHorizontal: 8}}>
            <Text style={{fontSize: 18, marginBottom: 16, textAlign: 'center'}}>What is your Name?</Text>
            <FormInput part='name' 
                placeholder='Enter your Full Name'
                onChangeText={name => this.setState({name})} />
            {!nameError ? null : 
              <Text style={{color: 'red', fontSize: 12, marginBottom: 8, marginHorizontal: 16}}>Please enter your full real name.</Text>
            }
            <WideButton part='submit' disabled={!name} onPress={() => this.onSetNamePressed()}>Set Name</WideButton>
          </View>
        </View>
      </ScreenContentScroll>
    )
  }
}

// export class SetRoomNameScreen extends React.Component {
//   state = {name: null, nameError: false}

//   async goBackToUser() {
//     const {user, navigation, onGotoUser} = this.props;
//     if (isWeb) {
//       onGotoUser({user});
//     } else {
//       navigation.goBack();
//     }
//   }

//   async onSetNamePressed() {
//     const {meeting, user} = this.props;
//     const {name} = this.state;
//     await setRoomName({meeting, user, name});
//     await this.goBackToUser();
//   }

//   async onClearNamePressed() {
//     const {meeting, user, navigation} = this.props;
//     await setRoomName({meeting, user, name:null});
//     await this.goBackToUser();
//   }

//   componentDidMount() {
//     const {meeting, user} = this.props;
//     watchRoomName(this, {meeting, user}, name => {
//       console.log('roomName', name);
//       this.setState({name})
//     });
//   }
//   componentWillUnmount() {
//     internalReleaseWatchers(this);
//   }

//   render() {
//     const {navigation} = this.props;
//     const {name, nameError} = this.state;
//     return (
//       <KeyboardSafeView>
//         <HeaderSpaceView style={{backgroundColor: 'white'}}>
//           <View style={{flexDirection: 'row', padding: 4,
//               borderBottomColor: '#ddd', borderBottomWidth: StyleSheet.hairlineWidth
//             }}>
//             <BackButton navigation={navigation} />
//             <Text style={{fontSize: 20, marginLeft: 4}}>Set your Room Name</Text>
//           </View>
//           <ScrollView>
//             <View style={{flexDirection: 'column', marginTop: 16}}>        
//               <FormInput part='name' 
//                   value={name || ''}
//                   placeholder='Enter your Room Name'
//                   onChangeText={name => this.setState({name})} />
//               {!nameError ? null : 
//                 <Text style={{color: 'red', fontSize: 12, marginBottom: 8, marginHorizontal: 16}}>Please enter your full real name.</Text>
//               }
//               <View style={{flexDirection: 'row', justifyContent:'space-around'}}>
//                 <WideButton part='submit' disabled={!name} onPress={() => this.onSetNamePressed()}>Set Room Name</WideButton>
//                 <WideButton part='submit' disabled={!name} onPress={() => this.onClearNamePressed()}>CLear Room Name</WideButton>
//               </View>
//             </View>
//           </ScrollView>
//         </HeaderSpaceView>
//       </KeyboardSafeView>
//     )
//   }
// }



