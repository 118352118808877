import { getTimeNow } from "./shim";
import { dayMillis } from "./basics";

export function makeDate({year, month = 0, dayOfMonth = 1, hours = 0, minutes = 0}) {
  return new Date(year, month, dayOfMonth, hours, minutes);
}

function getDailyNext(anchorDate, startDate, count) {
  var dateList = [];
  var i = 0;
  while(true) {
     const date = makeDate({
      year: startDate.getFullYear(), 
      month: startDate.getMonth(),
      dayOfMonth: startDate.getDate() + i,
      hours: anchorDate.getHours(),
      minutes: anchorDate.getMinutes()
    });

    if (i < count) {
      dateList.push(date);
    } else {
      return dateList;
    }

    i = i + 1;
  }
}

function isWeekday(dayOfWeek) {
  if (dayOfWeek == 0 || dayOfWeek == 6) {
    return false;
  } else {
    return true;
  }
}

function getWeekdaysNext(anchorDate, startDate, count) {
  var offset = 0;
  var dateList = [];
  var i = 0;
  while(true) {
     const date = makeDate({
      year: startDate.getFullYear(), 
      month: startDate.getMonth(),
      dayOfMonth: startDate.getDate() + offset,
      hours: anchorDate.getHours(),
      minutes: anchorDate.getMinutes()
    });

    if (i < count) {
      if (isWeekday(date.getDay())) {
        dateList.push(date);
        i = i + 1;
      }
    } else {
      return dateList;
    }

    offset = offset + 1;
  }
}


export function getWeeklyNext(anchorDate, startDate, count) {
  const dayOffset = (anchorDate.getDay() - startDate.getDay() + 7) % 7; 
  var dateList = [];
  var date = makeDate({
    year: startDate.getFullYear(), 
    month: startDate.getMonth(),
    dayOfMonth: startDate.getDate() + dayOffset,
    hours: anchorDate.getHours(),
    minutes: anchorDate.getMinutes()
  });
  var i = 0;
  while (i < count) {
    dateList.push(date);
    date = makeDate({
      year: date.getFullYear(), 
      month: date.getMonth(),
      dayOfMonth: date.getDate() + 7,
      hours: date.getHours(),
      minutes: date.getMinutes()
    });
    i = i + 1;
  }
  return dateList;
}

export function getMonthlyNext(anchorDate, startDate, count) {
  const weekOffset = Math.floor((anchorDate.getDate() - 1) / 7)
  // var month = startDate.getMonth();
  var dateList = [];
  var offset = 0;
  var i = 0;
  while(i < count && offset < count + 1) {
    var month = startDate.getMonth() + offset;

    const weekStart = makeDate({
      year: startDate.getFullYear(), 
      month: month,
      dayOfMonth: (7 * weekOffset) + 1,
      hours: startDate.getHours(),
      minutes: startDate.getMonth(),
    })
    const dayOffset = (anchorDate.getDay() - weekStart.getDay() + 7) % 7; 
    const date = makeDate({
      year: startDate.getFullYear(), 
      month: month,
      dayOfMonth: weekStart.getDate() + dayOffset,
      hours: anchorDate.getHours(),
      minutes: anchorDate.getMinutes()
    });

    if (i < count) {
      if (date >= startDate) {
        i = i + 1;
        dateList.push(date);
      }
    } else {
      return dateList;
    }

    offset = offset + 1;
  }
  return dateList;
}

export function getQuarterlyNext(anchorDate, startDate, count) {
  const weekOffset = Math.floor((anchorDate.getDate() - 1) / 7)
  // var month = startDate.getMonth();
  var dateList = [];
  var i = 0;
  while(i < count) {
    const month = startDate.getMonth() + (3 * i);
    const weekStart = makeDate({
      year: startDate.getFullYear(), 
      month: month,
      dayOfMonth: (7 * weekOffset) + 1,
      hours: startDate.getHours(),
      minutes: startDate.getMonth(),
    })
    const dayOffset = (anchorDate.getDay() - weekStart.getDay() + 7) % 7; 
    const date = makeDate({
      year: startDate.getFullYear(), 
      month: month,
      dayOfMonth: weekStart.getDate() + dayOffset,
      hours: anchorDate.getHours(),
      minutes: anchorDate.getMinutes()
    });

    if (i < count) {
      dateList.push(date);
    } else {
      return dateList;
    }

    i = i + 1;

    // month = month + 3;  
  }
  return dateList;
}


export function getNextDates({anchorDate, repeat, startDate, count}) {
  switch (repeat) {
    case 'daily': return getDailyNext(anchorDate, startDate, count);
    case 'weekdays': return getWeekdaysNext(anchorDate, startDate, count);
    case 'weekly': return getWeeklyNext(anchorDate, startDate, count);
    case 'monthly': return getMonthlyNext(anchorDate, startDate, count);
    case 'quarterly': return getQuarterlyNext(anchorDate, startDate, count);
    default: return [anchorDate];
  }
}

export function getFocusDate(meetingInfo) {
  if (!meetingInfo) {return getTimeNow()}
  var thisTimeYesterday = new Date();
  thisTimeYesterday.setDate(thisTimeYesterday.getDate() - 1);
  
  const closeTimes = getNextDates({anchorDate: new Date(meetingInfo.date), repeat: meetingInfo.repeat, startDate: thisTimeYesterday, count: 2});
  if (closeTimes[1] && closeTimes[1].valueOf() - getTimeNow() < dayMillis) {
    return closeTimes[1].valueOf();
  } else {
    return closeTimes[0].valueOf();
  }
}
