import * as Permissions from 'expo-permissions';
import * as ImagePicker from 'expo-image-picker';
import * as ImageManipulator from 'expo-image-manipulator';

export async function pickImage() {
  const { status: existingStatus } = await Permissions.getAsync(
    Permissions.CAMERA_ROLL
  );
  if (existingStatus !== 'granted') {
    const { status } = await Permissions.askAsync(Permissions.CAMERA_ROLL);
  }

  const result = await ImagePicker.launchImageLibraryAsync({
    mediaTypes: 'Images',
    base64: true
  });
  // console.log('result', {...result, base64:result.base64.slice(0,60), base64length: result.base64.length});
  return {result, uri: result.uri, base64: result.base64, width: result.width, height: result.height}
}

export async function compressImage({uri, shortDimension, width, height}) {
  var newWidth; var newHeight;
  if (width > height) {
    newHeight = shortDimension; 
    newWidth = (width / height) * shortDimension;
  } else {
    newWidth = shortDimension;
    newHeight = (height / width) * shortDimension;
  }
  // console.log('resizing to :', {newWidth, newHeight});
  return await ImageManipulator.manipulateAsync(uri, 
    [{resize: {width: newWidth, height: newHeight}}],
    {format: 'jpeg', compress: 0.7, base64: true});
}
