import React from 'react';
import { WideButton, ScreenContentScroll, FixedTouchable, DateTimeText, HeaderSpaceView, isWeb, FlatScroller, Loading, dayMillis, AlignedButton, commaSepStrings, hourMillis, SmallButton, GroupIcon, ClockIcon } from '../components/basics';
import { View, Platform, Image, Text, StyleSheet, ScrollView, SafeAreaView } from 'react-native';
import { watchMeetingInfo, watchMyMeetings, updateMeetingAsync, updateMyMeetingAsync, watchMeetingAttendees, watchPresence, global_currentUser, watchMyMeetingChatPeeks, watchReadTimes, watchMyName, joinMeeting, markMeetingReadAsync, leaveMeetingAsync } from '../data/data';
import _ from 'lodash';
import { Catcher } from '../components/catcher';
import { HelpBox } from '../components/help';
import { setTitle, navigateToScreen, getTimeNow, setBadgeCount } from '../components/shim';
import { getFocusDate } from '../components/dates';
import { internalReleaseWatchers } from '../data/fbutil';
import { GatheringIcon } from '../components/groupicon';
import { Feather, FontAwesome } from '@expo/vector-icons';
import { SetName } from './SetName';
import { baseColor } from '../data/config';
import { PastToggle } from '../components/boardtoggle';

function UpcomingMeetings({myMeetings}) {
  const meetingNextDates = _.mapValues(myMeetings, m => getFocusDate(m.meetingInfo));
  const sortedBySoonest = _.sortBy(Object.keys(myMeetings), m => meetingNextDates[m]);
  const soonestTime = meetingNextDates[sortedBySoonest[0]];

}

function MeetingHighlightPreview({myMeetingInfo, attendees}) {
  // console.log('MeetingHighlightPreview', myMeetingInfo, attendees)
  if (!attendees) return null;

  const messages = _.get(myMeetingInfo,'message',{});
  const messageSenders = Object.keys(myMeetingInfo.message || {}) 
  const freshSenders = _.filter(messageSenders, m => _.get(messages, [m,'time'],0) > myMeetingInfo.lastRead || 0);
  const sortedSenders = _.sortBy(freshSenders, m => _.get(messages, [m, 'time'], 0));
  // console.log('senders', myMeetingInfo, messageSenders, freshSenders, sortedSenders);
  const names = commaSepStrings(sortedSenders.map(u => _.get(attendees, [u, 'name'], 'Someone')));
  if (sortedSenders.length > 0) {
    return (
      <Text numberOfLines={1} style={{color: '#666', marginTop: 8}}>
        <Text style={{fontWeight: '700', color: 'black'}}>{names}</Text>
        {} sent {sortedSenders.length == 1 ? 'a message' : 'messages'}
      </Text>
    )
  } else {
    return (
      <Text numberOfLines={1} style={{color: '#666', marginTop: 8}}>
        Gathering started
      </Text>
    )
  }
}

class MeetingPreview extends React.Component {
  state = {meetingInfo: null, updated: false}

  componentDidMount(){
    const {meeting} = this.props;
    watchMeetingAttendees(this, meeting, attendees => this.setState({attendees}));
    // watchMyMeetingChatPeeks(this,meeting, chatPeeks => this.setState({chatPeeks}));
    watchPresence(this, meeting, presence => this.setState({presence}));
    watchMeetingInfo(this, meeting, async meetingInfo => {
        this.setState({meetingInfo});
        if (!_.isEqual(meetingInfo, this.props.meetingInfo) && !this.state.updated) {
          await updateMyMeetingAsync({meeting, meetingInfo});
        }
      } 
    );
  }
  componentWillUnmount() {
    internalReleaseWatchers(this);
  }

  gotoMeeting() {
    const {meeting, navigation} = this.props;
    markMeetingReadAsync(meeting);
    navigateToScreen({navigation, screen: 'gather', item: meeting, props: {meeting}});
  }

  async joinMeeting() {
    const {meeting, myName} = this.props;
    const {meetingInfo} = this.state;
    await joinMeeting({meeting, meetingInfo, myName});
    await this.gotoMeeting();
  }

  render() {
    const {attendees, presence} = this.state;
    const {meeting, myMeetingInfo} = this.props;
    const meetingInfo = this.state.meetingInfo || this.props.meetingInfo || {};
    if (!meetingInfo || !myMeetingInfo) return null;

    const focusDate = getFocusDate(meetingInfo);
    const dayAgo = getTimeNow() - dayMillis;
    const shadowStyle = {
      elevation: 3,
      shadowRadius: 4, shadowOpacity: 0.5, shadowColor: '#555', shadowOffset: {width: 0, height: 2}
    }
    var active = false;
    if (focusDate < getTimeNow() && focusDate > (getTimeNow() - (4 * hourMillis))) {
      active = true;
    }
    const isPresent = myMeetingInfo.present > dayAgo;
    const unread = myMeetingInfo.time > (myMeetingInfo.lastRead || 0);
    const newStart = myMeetingInfo.notifTime > (myMeetingInfo.lastRead || 0);

    // console.log('Preview:', {active, isPresent, unread}, myMeetingInfo)

    return (
      <FixedTouchable onPress={() => this.gotoMeeting()}>
        <View style={{width: isWeb ? 500 : undefined,
              maxWidth: 500, marginHorizontal: 8, padding: 8, marginVertical: 4, backgroundColor: 'white', 
              borderColor: '#ddd', borderRadius: 8, borderWidth: StyleSheet.hairlineWidth,
              ...(active || isPresent ? shadowStyle : {})
              }}>
          <View style={{flexDirection: 'row'}}>
            {isPresent ?
              <Catcher>
                <GatheringIcon size={60} meeting={meeting} attendees={attendees || {}} presence={presence || {}} /> 
              </Catcher>
            : (active ? <GroupIcon size={60} /> : <ClockIcon size={60} follow={myMeetingInfo.follow} />)}
            <View style={{flex: 1, flexGrow: 1, marginLeft: 10}}>
              <Text numberOfLines={1} style={{fontSize: 20, fontWeight: unread ? '700' : null}}>{meetingInfo.name}</Text>
              {unread ? 
                <Catcher>
                  <MeetingHighlightPreview active={active} myMeetingInfo={myMeetingInfo} attendees={attendees} />
                </Catcher>
              : 
                <Text numberOfLines={1} style={{fontWeight: newStart ? '700' : undefined, 
                    color: newStart ? 'black' : '#666', marginTop: 8}}>
                    <DateTimeText date={focusDate} repeat={meetingInfo.repeat} />
                </Text>
              }
            </View>
            {active && !(myMeetingInfo.present > dayAgo) ?
              <WideButton onPress={() => this.joinMeeting()}>Join</WideButton>
            : null}
            {/* {isPresent ?
              <SmallButton style={{marginTop: 0}} onPress={() => leaveMeetingAsync(meeting)}>Leave</SmallButton>
            : null} */}
          </View>
        </View>
      </FixedTouchable>
    )
  }
}



export class HomeScreen extends React.Component {
  state = {meetings: null, isFuture: true};

  componentDidMount() {
    watchMyMeetings(this, meetings => this.setState({meetings}));
    watchMyName(this, myName => this.setState({myName}));
    setTitle('Talkbeat - Your Gatherings');
  }
  componentWillUnmount() {
    internalReleaseWatchers(this);
  }

  newGathering() {
    const {navigation} = this.props;
    navigateToScreen({navigation, screen: 'new'});
  }

  render() {
    const {navigation} = this.props;
    const {meetings, myName, isFuture} = this.state;
    var sortedMeetings;

    if (!meetings) {
      return <Loading />
    }

    if (isFuture) {
      const recentTime = getTimeNow() - (12 * hourMillis); 
      const meetingNextDates = _.mapValues(meetings, myInfo => getFocusDate(myInfo.meetingInfo));
      const meetingUnread = _.mapValues(meetings, myInfo => myInfo.time > (myInfo.lastRead || 0));
      const futureMeetings = Object.keys(meetings).filter(m => meetingNextDates[m] > recentTime || meetingUnread[m])
      sortedMeetings = _.sortBy(futureMeetings, m => 
        _.min([meetingNextDates[m], meetingUnread[m] ? meetings[m].time : null]));    
      // console.log('future', {meetingNextDates, meetingUnread, futureMeetings})
    } else {
      sortedMeetings = _.sortBy(Object.keys(meetings || {}), m => _.max([meetings[m].joinTime, meetings[m].createTime, meetings[m].notifTime, meetings[m].time])).reverse(); 
    }

    if (myName != null && typeof(myName) !== 'string') {
      return <SetName />
    }

    const unreadMeetings = _.filter(sortedMeetings, m => (meetings[m].lastRead || 0) < meetings[m].time);
    setBadgeCount(unreadMeetings.length);

    return (
      <HeaderSpaceView style={{backgroundColor: '#fafafa'}}>
          <View style={{paddingVertical: 10, backgroundColor: 'white', borderBottomColor: '#ddd', borderBottomWidth: StyleSheet.hairlineWidth, alignItems: isWeb ? 'center' : undefined}}>
            {isWeb ? 
              <View style={{flexDirection: 'row', alignItems: 'center', maxWidth: 500, alignSelf: 'center'}}>

                <Image source={{uri: 'https://talkbeat.com/talkbeat_web_icon.png'}}
                  style={{width: 28, height: 28, marginRight: 8, marginBottom: 2}}
                />
                <Text style={{fontSize: 30, fontWeight: '700', marginRight: 40}}>Talkbeat</Text>
                <PastToggle toggled={isFuture} onToggle={() => this.setState({isFuture: !isFuture})} />
              </View>
            :
              <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', maxWidth: 500, paddingHorizontal: 20}}>
                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                  <Image source={{uri: 'https://talkbeat.com/talkbeat_web_icon.png'}}
                    style={{width: 28, height: 28, marginRight: 8, marginBottom: 2}}
                  />
                  <Text style={{fontSize: 30, fontWeight: '700'}}>Talkbeat</Text>
                </View>
                <PastToggle toggled={isFuture} onToggle={() => this.setState({isFuture: !isFuture})} />
              </View>
            }
          </View>
          {meetings ? 
            <View style={{alignItems: isWeb ? 'center' : 'stretch', flex: 1}}>
              <FlatScroller data={[
                {key: 'spacer', value: () => <View style={{height: 16}}/>},
                ...sortedMeetings.map(m => ({key: m, value: () => 
                  <MeetingPreview meeting={m} myName={myName}
                      myMeetingInfo={_.get(meetings,m)}
                      meetingInfo={_.get(meetings,[m,'meetingInfo'])} navigation={navigation} /> 
                })),
                {key: 'help', value: () =>
                  <HelpBox id='gatherlist' title='Join More Gatherings'>                    
                    <Text style={{fontSize: 15, color: '#666', marginBottom: 4}}>
                      To join a new gathering, get someone to share the link with you, 
                      then click the link to open it{isWeb ? '' : ' in this app'}.
                    </Text>
                    {/* <Text style={{fontSize: 15, color: '#666', marginBottom: 4}}>
                      A gathering is a regular time when people get together to 
                      have semi-private conversations with each other.
                    </Text> */}
                  </HelpBox>
                }
              ]} />
            </View>
          :
            <Loading /> 
          }
          <View style={{flexDirection: 'row', justifyContent: 'center',
              borderTopColor: '#ddd', borderTopWidth: StyleSheet.hairlineWidth
            }}>
            <WideButton onPress={()=>this.newGathering()}>Create a New Gathering</WideButton>
          </View>
      </HeaderSpaceView>
    )  
  }
}
