import React from 'react';
import {View, Text, StyleSheet} from 'react-native';
import { FixedTouchable } from './basics';
import { Entypo, MaterialIcons, Ionicons } from '@expo/vector-icons';

export class PublicToggle extends React.Component {
  state = {};

  render() {
    const {onToggle, toggled, style} = this.props;

    return (
      <View style={[{flexDirection: 'row'},style]}>
        <FixedTouchable part='boardToggle' onPress={() => onToggle(!toggled)}>
          <View style={{flexDirection: 'row', alignItems: 'center', 
                backgroundColor: '#eee',
                borderRadius: 16, borderColor: '#ddd', borderWidth: StyleSheet.hairlineWidth}}>
            <View style={toggled ? styles.activeBoard : styles.inActiveBoard}>
                {/* <MaterialIcons name='public' size={15} style={{marginRight: 4}} color={toggled ? 'black' : '#666'} /> */}
              <Text style={{color: toggled ? 'black' : '#666'}}>
                Everyone
              </Text>
            </View>
            <View style={toggled ? styles.inActiveBoard : styles.activeBoard}>
              <Text style={{color: toggled ? '#666' : 'black'}}>
                People I invite</Text>
            </View>
          </View>
        </FixedTouchable>
      </View>
    )
  }
}


export class PastToggle extends React.Component {
  state = {};

  render() {
    const {onToggle, toggled, style} = this.props;

    return (
      <View style={[{flexDirection: 'row'},style]}>
        <FixedTouchable part='boardToggle' onPress={() => onToggle(!toggled)}>
          <View style={{flexDirection: 'row', alignItems: 'center', 
                backgroundColor: '#eee',
                borderRadius: 16, borderColor: '#ddd', borderWidth: StyleSheet.hairlineWidth}}>
            <View style={toggled ? styles.activeBoard : styles.inActiveBoard}>
              <Text style={{color: toggled ? 'black' : '#666'}}>
                Upcoming
              </Text>
            </View>
            {/* <View style={toggled ? styles.inActivePrivate : styles.activePast}>
              <Text style={{color: toggled ? '#666' : 'white'}}>
                Past
              </Text>
            </View> */}

            <View style={toggled ? styles.inActiveBoard : styles.activeBoard}>
              <Text style={{color: toggled ? '#666' : 'black'}}>
                Past
              </Text>
            </View>
          </View> 
        </FixedTouchable>
      </View>
    )
  }
}



export class ModeratedToggle extends React.Component {
  state = {};

  render() {
    const {onToggle, toggled, style} = this.props;

    return (
      <View style={[{flexDirection: 'row'},style]}>
        <FixedTouchable part='boardToggle' onPress={() => onToggle(!toggled)}>
          <View style={{flexDirection: 'row', alignItems: 'center', 
                backgroundColor: '#eee',
                borderRadius: 16, borderColor: '#ddd', borderWidth: StyleSheet.hairlineWidth}}>
            <View style={toggled ? styles.activeBoard : styles.inActiveBoard}>
                {/* <MaterialIcons name='public' size={15} style={{marginRight: 4}} color={toggled ? 'black' : '#666'} /> */}
              <Text style={{color: toggled ? 'black' : '#666'}}>
                Moderated
              </Text>
            </View>
            <View style={toggled ? styles.inActiveBoard : styles.activeBoard}>
              <Text style={{color: toggled ? '#666' : 'black'}}>
                Unmoderated</Text>
            </View>
          </View>
        </FixedTouchable>
      </View>
    )
  }
}



export class BoardToggle extends React.Component {
  state = {};

  render() {
    const {onToggle, toggled, isCon, needsToJoin} = this.props;

    return (
      <FixedTouchable part='boardToggle' onPress={() => onToggle(!toggled)}>
        <View style={{flexDirection: 'row', alignItems: 'center', 
              backgroundColor: '#eee',
              borderRadius: 16, borderColor: '#ddd', borderWidth: StyleSheet.hairlineWidth}}>
          <View style={toggled ? styles.activeBoard : styles.inActiveBoard}>
              {/* <MaterialIcons name='public' size={15} style={{marginRight: 4}} color={toggled ? 'black' : '#666'} /> */}
            {isCon ? 
              <Ionicons name='md-flashlight' size={15} style={{marginRight: 4}} color={toggled ? 'black' : '#666'} /> 
            :
              <Ionicons name='ios-radio' size={15} style={{marginRight: 4}} color={toggled ? 'black' : '#666'} /> 
              // <Entypo name='blackboard' size={15} style={{marginRight: 4}} color={toggled ? 'black' : '#666'} />             
            }
            <Text style={{color: toggled ? 'black' : '#666'}}>
               {isCon ? 'Highlights' : 'Broadcasts'}
            </Text>
          </View>
          <View style={toggled ? styles.inActivePrivate : styles.activePrivate}>
            <Text style={{color: toggled ? '#666' : 'white'}}>
              {isCon ? 'All Messages' : 'Private Mesages'}</Text>
          </View>
        </View>
      </FixedTouchable>
    )
  }
}

const styles = StyleSheet.create({
  activeBoard: {
    backgroundColor: 'white',
    paddingHorizontal: 12,
    paddingVertical: 8,
    borderRadius: 16,
    marginTop: -4,
    // borderColor: '#ddd', borderWidth: StyleSheet.hairlineWidth, marginHorizontal: 4, marginBottom: 4,
    shadowRadius: 4, shadowColor: '#555', shadowOffset: {width: 0, height: 2},
    shadowOpacity: 0.5, elevation: 3,
    flexDirection: 'row',
    alignItems: 'center'
  },
  activePrivate: { 
    backgroundColor: '#0084ff',
    paddingHorizontal: 12,
    paddingVertical: 8,
    borderRadius: 16
  },
  activePast: {
    backgroundColor: 'black',
    paddingHorizontal: 12,
    paddingVertical: 8,
    borderRadius: 16,
    marginTop: -4,
    // borderColor: '#ddd', borderWidth: StyleSheet.hairlineWidth, marginHorizontal: 4, marginBottom: 4,
    shadowRadius: 4, shadowColor: '#555', shadowOffset: {width: 0, height: 2},
    shadowOpacity: 0.5, elevation: 3,
    flexDirection: 'row',
    alignItems: 'center'
  },
  inActivePrivate: {
    paddingRight: 16,
    paddingLeft: 8,
    paddingVertical: 8,
  },
  inActiveBoard: {
    paddingHorizontal: 12,
    // paddingLeft: 16,
    // paddingRight: 8,
    paddingVertical: 8,
    flexDirection: 'row',
    alignItems: 'center'
  }
});
