import React from 'react';
import {View, Text, StyleSheet, ScrollView} from 'react-native';
import { ScreenContentScroll, CenterScreenBox, LogoHeader, FormInput, WideButton, Link, HeaderSpaceView, BackButton, isWeb } from '../components/basics';
// const DatePicker = require('react-datepicker');
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
import {DatePicker, navigateToScreen, setTitle, getTimeNow, PopupSelector, setCurrentUrl} from '../components/shim';
import { createMeetingAsync, setCurrentUser } from '../data/data';
import { HelpBox } from '../components/help';
import { KeyboardSafeView } from '../components/keyboardsafeview.';
// import 'bootstrap/dist/css/bootstrap.min.css';

export function NewChatScreen({navigation}) {
  return <NewChat navigation={navigation} />
}

export class NewChat extends React.Component {
  state = {name: null, repeat: 'once', date: getTimeNow(), time: null, summary: null}

  async createGathering() {
    const {name, date, time, repeat, summary} = this.state;
    const {navigation} = this.props;
    if (!date) return;
    // var comboDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 
    //     time.getHours(), time.getMinutes());
    // const dateVal = comboDate.valueOf();
    const meeting = await createMeetingAsync({name, summary, repeat, date: date.valueOf()});
    if (isWeb) {
      setCurrentUrl('/gather/' + meeting);
    } else {
      navigateToScreen({navigation, screen: 'gather', item: meeting, props: {meeting}});
    }
  }

  componentDidMount() {
    setTitle('New Gathering - Talkbeat');
  }

  render() {
    const {navigation} = this.props;
    const {name, date, repeat, time, summary, focusTime} = this.state;

    return (
      <KeyboardSafeView>
      <HeaderSpaceView style={{backgroundColor: '#FaFaFa', flex: 1}}>
        <View style={{flexDirection: 'row', backgroundColor: 'white', justifyContent: isWeb ? 'space-between' : null,
            borderBottomColor: '#ddd', borderBottomWidth: StyleSheet.hairlineWidth}}>
          <BackButton navigation={navigation} />
          <View style={{flex: 1, paddingBottom: 8, paddingTop: isWeb ? 8 : 2, alignItems: isWeb ? 'center' : null}}>
            <Text style={{fontSize: 20, fontWeight: '700', textAlign: 'center'}}>New Gathering</Text>
          </View>
          <View />
        </View>
        <ScrollView>
          <View style={{flexDirection: 'row', justifyContent: 'center'}}>
            <View style={{flex: 1, alignSelf: 'stretch', maxWidth: 500, backgroundColor: 'white', 
                  borderColor: '#ddd', borderWidth: StyleSheet.hairlineWidth,
                  borderRadius: 16,
                  shadowOpacity: 0.5, shadowRadius: 4, shadowColor: '#555', shadowOffset: {width: 0, height: 2},
                  marginHorizontal: 8,
                  marginTop: isWeb ? 32 : 8}} >
              {/* {isWeb ? 
                <View style={{padding:10, borderColor: '#eee', backgroundColor: '#444', 
                      borderBottomWidth: StyleSheet.hairlineWidth,
                      borderTopLeftRadius: 16, borderTopRightRadius: 16}}>
                  <Text style={{fontSize: 14, color: '#eee'}}>New Gathering</Text>
                </View>
              : null } */}
              <FormInput part='name' 
                  placeholder='Title' style={styles.nameBox}
                  defaultValue={name} 
                  onFocus={() => this.setState({focusTime: getTimeNow()})}
                  // textAlign = 'center'
                  onChangeText={name => this.setState({name})}/>
              {/* <View style={{marginTop: 16, marginHorizontal: 16, flexDirection: 'row', alignItems: 'center'}}> */}
                <View style={{borderColor: '#ddd', borderWidth: 1, 
                    marginTop: 16, marginHorizontal: 16, zIndex: 200,
                    borderRadius: 8, paddingVertical: 4, paddingHorizontal: 8}}>
                  <DatePicker part='date'
                      className='datepicker'
                      style={{marginLeft: 16, fontSize: 30}}
                      placeholderText='Date'
                      showTimeSelect
                      disabledKeyboardNavigation
                      selected={date}
                      focusTime={focusTime}
                      onChange={date => this.setState({date})}
                      // showTimeSelect
                      // timeFormat="HH:mm"
                      timeIntervals={15}
                      dateFormat="MMM d, yyyy   h:mm aa"
                    />
                {/* </View> */}
                </View>
                <View style={{zIndex: 100, marginTop: 16, flexDirection: 'row', alignItems: 'center'}}>
                  <Text style={{fontSize: 20, marginLeft: 24, color: '#666', marginHorizontal: 8}}>repeating</Text>
                  <PopupSelector width={200} value={repeat || 'once'} 
                    onSelect={repeat => this.setState({repeat})}
                    items={[
                      {label: 'Does not repeat', id:'once'}, 
                      {label: 'Daily', id:'daily'},
                      {label: 'Weekdays', id:'weekdays'},
                      {label: 'Weekly', id:'weekly'},
                      {label: 'Monthly', id:'monthly'},
                      {label: 'Quarterly', id:'quarterly'},
                    ]}/>
                </View>


                {/* <Text style={{fontSize: 20, color: '#666', marginHorizontal: 8}}>at</Text>
                <View style={{borderColor: '#ddd', borderWidth: StyleSheet.hairlineWidth, borderRadius: 8, paddingVertical: 4, paddingHorizontal: 8}}>
                  <DatePicker part='time'
                      className='datepicker'
                      style={{marginLeft: 16, fontSize: 30}}
                      placeholderText='Time'
                      selected={time}
                      onChange={time => this.setState({time})}
                      // showTimeSelect
                      timeFormat="h:mm aa"
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                  />
                </View> */}
              {/* </View> */}

              <FormInput part='summary' multiline
                  placeholder='Description' style={styles.summaryBox}
                  defaultValue={summary}
                  onFocus={() => this.setState({focusTime: getTimeNow()})}                  
                  // textAlign = 'center'
                  onChangeText={summary => this.setState({summary})}/>

              <WideButton style={{zIndex: -1}} 
                part='submit' onPress={() => this.createGathering()} disabled={!date || !name}>
                Create Gathering
              </WideButton>
            </View>
          </View>
          <HelpBox id='newgathering' title='Schedule a time for people to get together'>  
            <Text style={{fontSize: 15, color: '#666', marginBottom: 8}}>
              A gathering is a scheduled time at which people can come together to have small-group text conversations.
            </Text>
            
            <Text style={{fontSize: 15, color: '#666', marginBottom: 8}}>
              If you are new to Talkbeat then feel free to create a test gathering so you can play around.
            </Text>
          </HelpBox>
        </ScrollView>
      </HeaderSpaceView>
      </KeyboardSafeView>
    )
  }
}

const styles = StyleSheet.create({
  nameBox: {
    backgroundColor: 'white',
    padding: 8,
    // width: 150,
    borderColor: '#ddd',
    borderRadius: 8,
    borderWidth: 1,
    // marginHorizontal: 4,
    marginTop: 16,
    // textAlign: 'center',
    // flex: 1,
    marginHorizontal: 16,
    fontSize: 20
  },
  summaryBox: {
    backgroundColor: 'white',
    padding: 8,
    // width: 150,
    height: 100,
    borderColor: '#ddd',
    borderRadius: 8,
    borderWidth: 1,
    marginVertical: 16,
    // marginHorizontal: 4,
    // textAlign: 'center',
    // flex: 1,
    marginHorizontal: 16,
    fontSize: 16
  }
})
