import React from 'react';
import {View, Text, StyleSheet} from 'react-native';
import { watchAllBoardMessages, watchFollows, global_currentUser, watchMeetingAttendees, watchAllPrivateMessages } from '../data/data';
import { BackButton, isMessageVisible, dayMillis } from '../components/basics';
import { Catcher } from '../components/catcher';
import { FontAwesome } from '@expo/vector-icons';
import { getTimeNow, BottomFlatScroller } from '../components/shim';
import { Message } from './Message';
import _ from 'lodash';

function FeedHeader() {
  return (
    <View style={{zIndex: 10, flexDirection: 'row', flex: 1,
        alignItems: 'center', justifyContent: 'space-between'}}>
      <View style={{flexShrink: 1, flexDirection: 'row', alignItems: 'center'}} >
        <FontAwesome name='feed' size={30} style={{marginHorizontal: 8}} />
        <View>
          <Text numberOfLines={1} style={{flexShrink: 1, fontSize: 20}}>
            Feed
          </Text>
        </View>
      </View>
    </View>
  )
}

function getFollowedMessages({allBoardMessages, allPrivateMessages, follows}) {
  var followedMessages = {};
  Object.keys(allBoardMessages).forEach(user => {
    if (_.get(follows,[user,global_currentUser])) {
      followedMessages = {...followedMessages, ...allBoardMessages[user]};
    }
  });
  Object.keys(allPrivateMessages).forEach(user => {
    if (_.get(follows,[user,global_currentUser])) {
      followedMessages = {...followedMessages, ..._.get(allPrivateMessages,[user, 'in'])};
    }
  });

  return followedMessages;
}

export class FeedScreen extends React.Component {
  state = {}

  async componentDidMount() {
    const {meeting} = this.props;
    watchAllBoardMessages(this, meeting, allBoardMessages => this.setState({allBoardMessages}));   
    watchAllPrivateMessages(this, meeting, allPrivateMessages => this.setState({allPrivateMessages}));
    watchFollows(this, meeting, follows => this.setState({follows}))
    watchMeetingAttendees(this, meeting, attendees => this.setState({attendees}));
  }

  render() {
    const {meeting, navigation, onGotoUser} = this.props;
    const {allBoardMessages, allPrivateMessages, follows, attendees} = this.state;
    if (!allBoardMessages || !allPrivateMessages || !follows) {
      return null;
    }
    const followedMessages = getFollowedMessages({allBoardMessages, allPrivateMessages, follows});
    const allMessages = followedMessages;
    const allMessageKeys = Object.keys(followedMessages || {});
    const monthAgoTime = getTimeNow() - (30 * dayMillis);
    const visibleMessageKeys = _.filter(allMessageKeys, m => 
      isMessageVisible({messageInfo: followedMessages[m], expireTime: monthAgoTime}));
    const sortedMessageKeys = _.sortBy(visibleMessageKeys, m => allMessages[m].time).slice(-50);
    return (
      <View style={{flex: 1, backgroundColor: 'white'}}> 
        <Catcher style={{zIndex: 10}}>
          <View style={{padding: 4, flexDirection: 'row', borderBottomColor: '#ddd', borderBottomWidth: StyleSheet.hairlineWidth}}>
            <BackButton navigation={navigation} />
            <FeedHeader />
          </View>
        </Catcher>
        <BottomFlatScroller style={{flex: 1, flexShrink: 1}} data={[
          ...sortedMessageKeys.map((m, k) =>
            ({key: m, value: () => 
                <Message key={m} message={m} messageInfo={followedMessages[m]} 
                    firstName={'feed'} user='feed' 
                    userInfo={{}}
                    prevMessageInfo={allMessages[sortedMessageKeys[k-1]]} meeting={meeting}
                    nextMessageInfo={allMessages[sortedMessageKeys[k+1]]}
                    msgResponses={{}}
                    onReply={() => {}}
                    attendees={attendees} 
                    // typingState={typingState} 
                    // onPublish={(publish) => onPublish({publish, message: m, messageInfo: allMessages[m]})}
                    // onReply={replyToAll => onReply({message:m, messageInfo: allMessages[m], replyToAll})} 
                    // onDelete={() => onDelete({message: m, messageInfo: allMessages[m]})}
                    // onEdit={() => onEdit({message: m, messageInfo: allMessages[m]})}
                    // onShare={({selectedCon, selectedConInfo}) => onShare({selectedCon, selectedConInfo, message:m, messageInfo: allMessages[m]})}
                    onGotoConversation={onGotoUser}
                    />         
            })
          ),
          {key: 'pad', value: () => <View style={{height: 16}} />}
        ]} 
        />
      </View>
    )
  }
}