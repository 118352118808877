import React from 'react';
import {Text, View, Image, ScrollView, StyleSheet} from 'react-native';
import { watchMyName, watchMeetingUserInfo, watchMessages, sendMessageAsync, global_currentUser, watchBoardMessages, markUserReadAsync, setTypingIndicatorAsync, watchTypingIndicator, watchBoardPeeks, deleteMessage, watchMeetingConversationInfo, joinConversationAsync, watchMeetingAttendees, followConversationAsync, leaveConversationAsync, newConversationAsync, watchUserIntroduction, watchMeetingInfo, sendMessageCopiedToConversationAsync, editMessageAsync, shareMessageToConversationAsync, askToCopyMessageToBoardAsync, acceptBoardRequest, rejectBoardRequest, removeMessageFromBoardAsync, shareConversationAsync, likeMessageAsync, watchResponses, watchBoardResponses, clearInviteAsync, acceptMoveRequest, rejectMoveRequest, acceptPublication } from '../data/data';
import { DefaultImage, FixedTouchable, LinkText, BottomArrow, WideButton, formatTime, parseDataUrl, getFileExtension, ConIcon, MyBoardIcon, UserIcon, HoverLink, IconForUser, getNameOrYou, isWeb, getNameOrYour, getMessagePrivateTo, getRoomName, isMessageVisible } from '../components/basics';
import { BottomScroller, getTimeNow, ModalMenu, vibrate, showAlert } from '../components/shim';
import { Entypo, Ionicons, FontAwesome } from '@expo/vector-icons';
import _ from 'lodash';
import { newKey, getUrlForImage } from '../data/fbutil';
import { global_showPhotoPopup } from '../components/photopopup';
import { global_showSharePopup } from './ConvoSelector';
import { baseColor } from '../data/config';

function MessagePopup({meeting, user, conInfo, message, messageInfo, onReply, onEdit, onDelete, onClose, onPublish}) {
  var actions = [];
  if (messageInfo.from != global_currentUser) {
    if (messageInfo.to == user) {
      actions.push({id: 'like', label: 'Like'})
    };
  } else {
    actions.push({id: 'remove', label: 'Remove'});
    actions.push({id: 'edit', label: 'Edit'});
  }
  actions.push({id: 'reply', label: 'Reply'});

  return (
    <ModalMenu part='modalmenu' items={actions} onClose={onClose} onSelect={async id => {
      switch(id) {
        case 'like':
          onClose();
          return likeMessageAsync({meeting, user, message, conInfo, messageInfo})
        case 'reply':
          onClose();
          return onReply(true);
        // case 'reply-private':
        //   onClose();
        //   return onReply(false);
        case 'edit':
          onClose();
          return onEdit();
        // case 'publish':
        //   onClose();
        //   return onPublish(true);
        // case 'unpublish':
        //   onClose();
        //   return onPublish(false);
        case 'remove':
          // await onClose();
          return await showAlert('Remove this Message?', 'Are you sure you want to delete this message',
            [
              {text: 'Cancel', style: 'cancel', onPress: onClose},
              {text: 'Remove', onPress: () => {onClose(); onDelete()}}
            ],
            {cancellable: false})
        default:
          onClose();
          return null;
      }
    }} />
  )
}


class MessageControls extends React.Component {
  state = {showMore: false}
  render() {
    const {user, meeting, message, onReply, onDelete, onEdit, responses, messageInfo, conInfo} = this.props;
    const {showMore} = this.state;
    const fromMe = messageInfo.from == global_currentUser;
    // const likedByMe = _.get(responses,['like',global_currentUser])
    const likedByMe = false;

    return (
      <View style={{flexDirection: fromMe ? 'row' : 'row-reverse', marginHorizontal: 8, alignItems: 'center'}}>
        {messageInfo.from == global_currentUser ?
          <View>
            <FixedTouchable part='more' onPress={() => this.setState({showMore: !showMore})} title='More'>
              <Ionicons name='ios-more' size={20} style={{marginHorizontal: 16}} color='#666' />
            </FixedTouchable>
            {!showMore ? null :
              <View style={{position: 'absolute', bottom: 24, left: -28,
                  backgroundColor: 'black', borderColor: 'white', borderWidth: 1,
                  borderRadius: 8, alignItems: 'center', width: 100
                }}>
                {messageInfo.from == global_currentUser ?
                  <FixedTouchable part='remove' onPress={onDelete}>
                    <View style={{marginVertical: 8, marginHorizontal: 12}}>
                      <Text style={{color: 'white'}}>Remove</Text>
                    </View>
                  </FixedTouchable>
                : null}
                {messageInfo.from == global_currentUser ?
                  <FixedTouchable part='edit' onPress={onEdit}>
                    <View style={{marginVertical: 8, marginHorizontal: 12}}>
                      <Text style={{color: 'white'}}>Edit</Text>
                    </View>
                  </FixedTouchable>
                : null}
                <BottomArrow color='black' style={{position: 'absolute', bottom: -10, left: 45}} />
              </View>
            }
          </View>
        : null }
        {(messageInfo.from == global_currentUser || messageInfo.to != user) ? null :
          <FixedTouchable part='like' onPress={()=>likeMessageAsync({meeting, user, message, conInfo, messageInfo})} title={likedByMe ? 'Unlike' : 'Like'}>
            <FontAwesome name={likedByMe ? 'heart' : 'heart-o'} size={24} style={{marginHorizontal: 8}} color='#666' />
          </FixedTouchable>
        }
        <FixedTouchable part='reply' onPress={() => onReply(true)} title='Reply'>
          <Entypo name='reply' size={24} style={{marginHorizontal: 8}} color='#666' />
        </FixedTouchable>
      </View>
    )
  }
}

function getUserName({attendees, user}) {
  // console.log('getUserNam', attendees, user);
  if (user == global_currentUser) {
    return 'You';
  } else {
    return _.get(attendees,[user,'name'],'Someone');
  }
}


// function MiniHighlightIcon() {
//   return <Ionicons name='md-flashlight' size={14} style={{marginRight: 2}} />
// }


// function MiniBroadcastIcon() {
//   return <Ionicons name='ios-radio' size={14} style={{marginRight: 3, position: 'relative', top: 1}}/>
// }



function getMessageExplainText({messageInfo, userInfo, prevMessageInfo, pairUser, user, pairFirstName, firstName, attendees, conInfo, onGotoConversation}) {
  const fromMe = messageInfo.from == global_currentUser;

  if (user == 'feed' && messageInfo.to == global_currentUser) {
    return (
      <View style={{
        flexDirection: 'row', alignItems: 'center'
        }}>
        <HoverLink text={getNameOrYou({user: messageInfo.from, attendees})}
          onPress={()=>onGotoConversation({user: messageInfo.from})}
          style={{color: baseColor, fontSize: 13}} />
        <Text style={{color: '#666', fontSize: 13}}>
          {' to You'}
        </Text>
      </View>
    )

  } else if (user == 'feed') {
    return (
      <View style={{
        flexDirection: 'row', alignItems: 'center'
        }}>
        <Text style={{color: '#666', fontSize: 13}}>
          {getNameOrYou({user: messageInfo.from, attendees})} in </Text>
        <HoverLink text={getRoomName({user: messageInfo.to, attendees})}
          onPress={()=>onGotoConversation({user: messageInfo.to})}
          style={{color: baseColor, fontSize: 13}} />
      </View>
    )
  // } else if (messageInfo.to == messageInfo.from && messageInfo.isBoard) {
  //   return 'on ' + getNameOrYour({user: messageInfo.from, attendees}) + ' public profile';
  } else if (messageInfo.isBoard && messageInfo.conversation != user && messageInfo.to != user && messageInfo.from == global_currentUser && user != global_currentUser) {
    return null;
  } else if (messageInfo.to != user /* && messageInfo.to != global_currentUser */) {
    return (
      <View style={{
        flexDirection: 'row', alignItems: 'center'
        }}>
        {/* <Text style={{color: '#666', fontSize: 13}}> */}
          {/* {fromMe ? '' : getNameOrYou({user: messageInfo.from, attendees})} in </Text> */}
        <Text style={{color: '#666', fontSize: 13}}>In </Text>
        <HoverLink text={getRoomName({user: messageInfo.to, attendees})}
          onPress={()=>onGotoConversation({user: messageInfo.to})}
          style={{color: baseColor, fontSize: 13}} />
      </View>
    )
  } else if (messageInfo.isBoard) {
    const asked = (!messageInfo.approved && messageInfo.from != user && messageInfo.from != userInfo.host) ? ' submitted a message' : '';
    return getNameOrYou({user: messageInfo.from, attendees}) + asked;
  } else if (!messageInfo.isBoard) {
    if (messageInfo.from == global_currentUser) {
      const viewer = getMessagePrivateTo({user, userInfo, replyToMessageInfo: messageInfo.replyToMessageInfo, isPublic:false});
      return (
        'just to ' + getNameOrYou({user: viewer, attendees})
      )
    } else {
      return getNameOrYou({user: messageInfo.from, attendees}) + ' - just to you';
    }
  } else if (user == global_currentUser) {
    return null;
  } else {
    return null;
  }
}

const minuteMillis = 1000 * 60;

export function indexResponses(responses) {
  var msgResponses = {};
  Object.keys(responses).forEach(r => {
    const response = responses[r];
    _.set(msgResponses, [response.about, response.type, response.from], response);
  })
  return msgResponses;
}

export class Message extends React.Component {
  state = {hover: false, popupmenu: false}
  render() {
    const {focusDate, userInfo, msgResponses, pairUser, pairFirstName, meeting, message, messageInfo, prevMessageInfo, nextMessageInfo, attendees, conInfo, firstName, user, typingState, onReply, onPublish, onDelete, onEdit, onShare, onGotoConversation} = this.props;
    const {hover, popupmenu} = this.state;
    const fromMe = messageInfo.from == (pairUser || global_currentUser);
    const toMe = messageInfo.to == global_currentUser;
    const isBoard = messageInfo.isBoard;
    const isBroadcast = messageInfo.isBroadcast;
    const justifyContent = (fromMe && !isBroadcast) ? 'flex-end' : 'flex-start';
    const backgroundColor = messageInfo.isBoard ? (
        (messageInfo.from == messageInfo.to || messageInfo.approved || messageInfo.from == userInfo.host)
          ? '#eee' : 'white')
      : '#222';
    const color = messageInfo.isBoard ? 'black' : 'white';
    const borderWidth = 1;
    const hasShadow = isBoard;
    const borderColor = isBroadcast ? '#ddd' : 'black';
    const leftControls = fromMe && !isBroadcast;
    const marginLeft = 0; const marginRight = 0;
    const timePassed = (messageInfo.time - _.get(prevMessageInfo,'time',0)) > (1 * minuteMillis);
    const nextClose = nextMessageInfo && ((nextMessageInfo.time - messageInfo.time) < (1 * minuteMillis));
    const bunchWithNext = nextClose && (nextMessageInfo.from == messageInfo.from) && !messageInfo.type && !nextMessageInfo.type;

    const explainText = getMessageExplainText({pairUser, userInfo, messageInfo, prevMessageInfo, user, firstName, pairFirstName, attendees, conInfo, onGotoConversation});
    const noHover = messageInfo.type == 'echo'
        const meetingAfterThis = focusDate < getTimeNow() && messageInfo.time < focusDate && (!nextMessageInfo || nextMessageInfo.time > focusDate);
    // const isBackgroundBoard = messageInfo.to != user;
    const isBackgroundBoard = false;
    const responses = msgResponses[message];

    return (
      <View style={{marginHorizontal: 4,
            marginTop: (messageInfo.from == _.get(prevMessageInfo,'from')) ? 1 : 4,
            marginBottom: (messageInfo.from == _.get(nextMessageInfo,'from')) ? 1 : 4
            }}>
        {popupmenu ?
          <MessagePopup meeting={meeting} user={user} conInfo={conInfo} message={message} messageInfo={messageInfo}
            onReply={onReply} onPublish={onPublish} onDelete={onDelete} onEdit={onEdit}
            onClose={()=>this.setState({popupmenu: false})}/>
        : null}
        {timePassed ?
          <Text style={{textAlign: 'center', fontSize: 13, color: '#999', marginTop: 8, marginBottom: 4}}>
            {formatTime(messageInfo.time)}
          </Text>
        : null}
        {explainText ?
          <View style={{alignSelf: justifyContent,
              marginLeft: leftControls ? undefined : 38,
              marginRight: leftControls && pairUser ? 38 : undefined,
              marginTop: 8, marginBottom: 2}}>
            {typeof(explainText) == 'string' ?
              <Text style={{color: '#666', fontSize:12, marginHorizontal: 8}}>
                {explainText}
              </Text>
            :
              explainText
            }
          </View>
        : null}
        <View part={message} style={{flexDirection: 'row', alignItems: 'center', justifyContent, backgroundColor: hover ? '#f8f8f8' : undefined}}
            onMouseOver={()=>this.setState({hover:!noHover})} onMouseLeave={()=>this.setState({hover:false})}>
          {(hover && leftControls && !isBackgroundBoard) ?
              <MessageControls part='controls' user={user} meeting={meeting}
                  message={message} conInfo={conInfo} responses={responses}
                  onReply={onReply} onPublish={onPublish} onDelete={onDelete}
                  onEdit={onEdit} onShare={onShare} messageInfo={messageInfo} /> : null}
          {leftControls ? null :
            <View style={{alignSelf: 'flex-end', marginRight: 2, width: 30}}>
              {bunchWithNext ? null :
                <FixedTouchable part='message-face' onPress={()=>onGotoConversation({user:messageInfo.from})} >
                  <IconForUser size={30} user={messageInfo.from} userInfo={_.get(attendees,messageInfo.from)} />
                </FixedTouchable>
              }
            </View>
          }
          <FixedTouchable part='menupress' onLongPress={()=>{vibrate(); this.setState({popupmenu: true})}} dummy={isWeb}
            onPress={()=>{vibrate(); this.setState({popupmenu: true})}}
          >
            <MessageBody userInfo={userInfo} meeting={meeting} message={message} pairUser={pairUser}
                firstName={firstName} responses={responses} hasShadow={hasShadow}
                hover={hover} user={user} messageInfo={messageInfo} attendees={attendees}
                backgroundColor={backgroundColor} borderWidth={borderWidth}
                borderColor={borderColor} marginLeft={marginLeft} marginRight={marginRight}
                color={color} onGotoConversation={onGotoConversation}
                onLongPress={() => {vibrate(); this.setState({popupmenu: true})}}
                />
          </FixedTouchable>
          {(hover && !leftControls && !isBackgroundBoard) ?
              <MessageControls part='controls' user={user} meeting={meeting}
                  message={message} conInfo={conInfo} responses={responses}
                  onReply={onReply} onPublish={onPublish} onDelete={onDelete}
                  onEdit={onEdit} onShare={onShare} messageInfo={messageInfo} /> : null}
          {!leftControls || !pairUser ? null :
            <View style={{alignSelf: 'flex-end', marginRight: 2, width: 30}}>
              {bunchWithNext ? null :
                <FixedTouchable onPress={()=>onGotoConversation({user:messageInfo.from})} >
                  <IconForUser size={30} user={messageInfo.from} userInfo={_.get(attendees,messageInfo.from)} />
                </FixedTouchable>
              }
            </View>
          }

        </View>
        {(user == global_currentUser || global_currentUser == userInfo.host) && messageInfo.isBoard && !messageInfo.approved && messageInfo.to == user && messageInfo.from != user && messageInfo.from != global_currentUser ?
          <View style={{alignSelf: justifyContent}}>
            <MessagePublishRequest meeting={meeting} firstName={firstName} message={message} messageInfo={messageInfo} responses={responses} />
          </View>
        : (!responses ? null :
          <MessageResponses meeting={meeting} message={message} messageInfo={messageInfo} user={user} justifyContent={justifyContent} responses={responses}
              attendees={attendees} marginRight={leftControls && pairUser ? 38 : undefined}
              onGotoConversation={onGotoConversation} firstName={firstName}/>
        )
        }
        {meetingAfterThis ?
          <MeetingSeparatorLine />
        : null}
      </View>
    )
  }
}

function MeetingSeparatorLine() {
  return (
    <View style={{marginTop: 32, marginBottom: 32, paddingTop: 4, borderTopColor: '#ddd', borderTopWidth: StyleSheet.hairlineWidth, alignItems: 'center', alignSelf: 'stretch'}}>
      <Text style={{color: '#666'}}>Latest Gathering Starts Here</Text>
    </View>
  )
}

function MessageLikes({attendees, justifyContent, likes}) {
  const likeKeys = Object.keys(likes);
  const length = likeKeys.length;
  return (
  <View style={{alignSelf: justifyContent, flexDirection: 'row',
        justifyContent: 'flex-start', flexWrap: 'wrap', alignItems: 'center',
        paddingLeft: 46, marginRight: 8, marginTop: 2, maxWidth: isWeb ? 350 : 250}}>
    <FontAwesome name={likes[global_currentUser] ? 'heart' : 'heart-o'} color={baseColor} style={{marginRight: 2, marginTop: 0}} size={14} />
    {likeKeys.map((l,i) =>
      <UserIcon key={l} size={14} userInfo={attendees[likes[l].from]} marginRight={2} />
    )}
    {likeKeys.map((l,i) =>
      <Text key={'like-' + l} style={styles.responseText}>
        {(i == 0 ? '' : (i == length - 1 ? (length == 2 ? ' and ' : ', and ') : ', '))
        + getUserName({attendees, user: likes[l].from})}
      </Text>
    )}
    <Text style={styles.responseText}>
      {' '}liked this
    </Text>
  </View>
  )
}


function MessagePrivateReplies({attendees, justifyContent, replies}) {
  const replyKeys = Object.keys(replies);
  const length = replyKeys.length;
  return (
  <View style={{alignSelf: justifyContent, flexDirection: 'row',
        justifyContent: 'flex-start', flexWrap: 'wrap', alignItems: 'center',
        paddingLeft: 46, marginRight: 8, marginTop: 2, maxWidth: isWeb ? 350 : 250}}>
    <FontAwesome name='user-secret' color='#666' style={{marginRight: 2, marginTop: 0}} size={14} />
    {replyKeys.map((l,i) =>
      <UserIcon key={l} size={14} userInfo={attendees[replies[l].from]} marginRight={2} />
    )}
    {replyKeys.map((l,i) =>
      <Text key={'reply-'+l} style={styles.responseText}>
        {(i == 0 ? '' : (i == length - 1 ? (length == 2 ? ' and ' : ', and ') : ', '))
        + getUserName({attendees, user: replies[l].from})}
      </Text>
    )}
    <Text style={styles.responseText}>
      {' '}replied privately
    </Text>
  </View>
  )
}

function MessagePublishRequest({meeting, message, firstName, messageInfo, responses}) {
    return (
      <View style={{marginLeft: 46}}>
        <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around', marginTop: 4}}>
          <FixedTouchable part='accept' onPress={async ()=>{
              await acceptPublication({meeting, message, messageInfo});
            }} >
            <View style={{backgroundColor: '#6BAB12', paddingHorizontal: 12, paddingVertical: 8, borderRadius: 8}}>
              <Text style={{color: 'white', fontWeight: 'bold'}}>Publish</Text>
            </View>
          </FixedTouchable>
        </View>
      </View>
    )
}


function MessageResponses({meeting, firstName, message, user, attendees, marginRight, messageInfo, responses, justifyContent}) {
  return (
    <View style={{alignSelf: justifyContent, marginBottom: 8, marginRight}}>
      {responses.like ?
        <MessageLikes attendees={attendees} justifyContent={justifyContent} likes={responses.like} />
      : null }
      {responses.reply ?
        <MessagePrivateReplies attendees={attendees} justifyContent={justifyContent} replies={responses.reply} />
      : null }
    </View>
  )
}

function MessageBody({meeting, userInfo, hasShadow, responses, firstName, message, attendees, onGotoConversation, messageInfo, user, pairUser, hover, backgroundColor, borderColor, borderWidth, marginLeft, marginRight, color, onLongPress}) {
  const shadowStyle = {elevation: 3, shadowRadius: 4, shadowOpacity: 0.5, shadowColor: '#555', shadowOffset: {width: 0, height: 2}}
  if (
    (messageInfo.to != user /* && messageInfo.to != global_currentUser */ && !(user == 'feed' && messageInfo.from != global_currentUser))
    || (pairUser && !((messageInfo.from == pairUser && messageInfo.to == user) || (messageInfo.from == user && messageInfo.to == pairUser)))
    || (messageInfo.isBoard && messageInfo.to != user && messageInfo.conversation != user && messageInfo.from == global_currentUser && user != global_currentUser)) {
    return (
      <View style={{flexDirection: 'row', alignItems: 'center'}}>
        <FixedTouchable onPress={()=>onGotoConversation({user:messageInfo.to})} onLongPress={onLongPress}>
          <View style={{
                  flexDirection: 'row', alignItems: 'center', marginLeft: 4,
                  backgroundColor: 'white', borderRadius: 14, paddingHorizontal: 6, paddingVertical: 6,
                  borderWidth: StyleSheet.hairlineWidth, borderColor: '#ddd',
                  maxWidth: isWeb ? 350 : 250 }}>
            {messageInfo.photo ?
              <FixedTouchable onPress={()=>global_showPhotoPopup({photo: messageInfo.photo})} onLongPress={onLongPress}>
                <Image source={{uri:getUrlForImage(messageInfo.photo)}}
                  style={{width: 30, height: 30, borderRadius: 8, marginRight: messageInfo.text ? 4 : 0}} />
              </FixedTouchable>
            : null}
            <Text numberOfLines={1} style={{marginHorizontal: 4, fontSize: 13, color: '#222'}}>{messageInfo.text}</Text>
          </View>
        </FixedTouchable>
      </View>
    )
  } else if (messageInfo.isBoard || messageInfo.isBroadcast) {
    const approved = messageInfo.approved || messageInfo.from == messageInfo.to || messageInfo.from == userInfo.host;
    return (
      <View style={{flexShrink: 1, backgroundColor: approved ? 'white' : '#eee', borderRadius: 8, paddingHorizontal: 4, paddingVertical: 4,
        borderColor: '#ddd', borderWidth: StyleSheet.hairlineWidth, marginHorizontal: 4, marginBottom: 4,
        maxWidth: isWeb ? 358 : 258,
        elevation: 3, shadowOpacity: 0.5, shadowRadius: 4, shadowColor: '#555', shadowOffset: {width: 0, height: 2}}}>
          <RepliedMessage attendees={attendees} messageInfo={messageInfo} color='black' onGotoConversation={onGotoConversation} />
          {messageInfo.photo ?
            <MessagePhoto photo={messageInfo.photo} padTop={messageInfo.replyToMessageInfo} onLongPress={onLongPress} />
          : null}
          {messageInfo.text ?
            <View style={{paddingHorizontal: 10, paddingVertical: 6, maxWidth: isWeb ? 350 : 250}}>
              <LinkText style={{fontSize: 15, color: 'black', lineHeight: 20}} text={messageInfo.text} />
            </View>
          : null}
          {messageInfo.edited ?
            <Text style={{color: 'black', fontSize: 13, marginLeft: 16}}>- edited</Text>
          : null}
      </View>
    )
  } else {
    return (
      <View style={{flexShrink: 1, backgroundColor, borderRadius: 18, paddingHorizontal: 4, paddingVertical: 4,
          borderWidth, borderColor, maxWidth: isWeb ? 358 : 258,
          marginLeft, marginRight}}>
        <RepliedMessage attendees={attendees} messageInfo={messageInfo} color={color} onGotoConversation={onGotoConversation} />
        {messageInfo.photo ?
          <MessagePhoto photo={messageInfo.photo} padTop={messageInfo.replyToMessageInfo} onLongPress={onLongPress} />
        : null}
        {messageInfo.text ?
          <View style={{paddingHorizontal: 10, paddingVertical: 2, maxWidth: isWeb ? 350 : 350}}>
            <LinkText style={{fontSize: 15, color, lineHeight: 20}} text={messageInfo.text} />
          </View>
        : null}
        {messageInfo.edited ?
          <Text style={{color, fontSize: 13, marginLeft: 16}}>- edited</Text>
        : null}
      </View>
    )
  }
}

function MessagePhoto({photo, padTop, onLongPress}) {
  return (
    <FixedTouchable onPress={() => global_showPhotoPopup({photo})} onLongPress={onLongPress} >
        <Image source={{uri:getUrlForImage(photo)}}
          style={{width: isWeb ? 350 : 250, height: isWeb ? 350 : 250, borderRadius: 18, marginTop: padTop ? 10 : 0}} />
    </FixedTouchable>
  )
}

export class RepliedMessage extends React.Component {
  state = {expanded: false}

  render() {
    const {expanded} = this.state;
    const {attendees, messageInfo, color, onGotoConversation} = this.props;
    if (!messageInfo.replyToMessageInfo) {
      return null;
    }
    return (
      <View style={{borderLeftColor: color, borderLeftWidth: StyleSheet.hairlineWidth, marginLeft: 10, paddingHorizontal: 12, marginTop: 8, marginBottom: 4}}>
        <Text style={{color, fontSize: 13, fontWeight: '700', marginBottom: 2}}>
          {getNameOrYou({user: messageInfo.replyToMessageInfo.from, attendees})}{!messageInfo.replyToMessageInfo.isPublic && messageInfo.isPublic ? ' (private)' : ''}:
        </Text>
        {messageInfo.replyToMessageInfo.photo ?
          <FixedTouchable onPress={()=>global_showPhotoPopup({photo: messageInfo.replyToMessageInfo.photo})}>
            <Image source={{uri:getUrlForImage(messageInfo.replyToMessageInfo.photo)}}
              style={{width: 75, height: 75, borderRadius: 4, marginBottom: messageInfo.replyToMessageInfo.text ? 4 : 0}}
            />
          </FixedTouchable>
        : null}
        {(messageInfo.replyToMessageInfo.isPublic || messageInfo.from == global_currentUser || messageInfo.replyToMessageInfo.from == global_currentUser) ?
          <FixedTouchable onPress={() => this.setState({expanded: !expanded})}>
            <Text numberOfLines={expanded ? null : 2} style={{opacity: 0.8, fontSize: 14, color}}>
              {messageInfo.replyToMessageInfo.text}
            </Text>
          </FixedTouchable>
        :
          <Text style={{color: '#666', fontStyle: 'italic', fontSize: 14}}>private message</Text>
        }
      </View>
    )
  }
}

function OLD_RepliedMessage({attendees, messageInfo, color, onGotoConversation}) {
  if (!messageInfo.replyToMessageInfo) {
    return null;
  } else if (messageInfo.replyToMessageInfo.type === 'conversation') {
    return (
      <View style={{marginLeft: 10, borderLeftColor: color, borderLeftWidth: StyleSheet.hairlineWidth, paddingHorizontal: 12, marginVertical: 8}}>
        <FixedTouchable onPress={() => onGotoConversation({user: messageInfo.replyToMessageInfo.extra.sharedCon})} >
          <View style={{flexDirection: 'row', alignItems: 'center', backgroundColor: 'white',
                padding: 8,
                borderRadius: 4, borderColor: '#ddd', borderWidth: StyleSheet.hairlineWidth}}>
            <FontAwesome name='group' size={30} color='#0084ff' />
            <View style={{marginLeft: 8}}>
              <Text numberOfLines={1} style={{fontSize: 15, fontWeight: '700'}}>{messageInfo.replyToMessageInfo.extra.sharedConName}</Text>
              <View style={{marginTop: 2}}>
                <Text style={{fontSize: 13}}>
                  view conversation
                </Text>
              </View>
            </View>
          </View>
        </FixedTouchable>
      </View>
    )
  } else {
    return (
      <View style={{borderLeftColor: color, borderLeftWidth: StyleSheet.hairlineWidth, marginLeft: 10, paddingHorizontal: 12, marginTop: 8, marginBottom: 4}}>
        <Text style={{color, fontSize: 13, fontWeight: '700', marginBottom: 2}}>{getNameOrYou({user: messageInfo.replyToMessageInfo.from, attendees})}:</Text>
        {messageInfo.replyToMessageInfo.photo ?
          <FixedTouchable onPress={()=>global_showPhotoPopup({photo: messageInfo.replyToMessageInfo.photo})}>
            <Image source={{uri:getUrlForImage(messageInfo.replyToMessageInfo.photo)}}
              style={{width: 75, height: 75, borderRadius: 4, marginBottom: messageInfo.replyToMessageInfo.text ? 4 : 0}}
            />
          </FixedTouchable>
        : null}
        <Text style={{opacity: 0.8, fontSize: 14, color}}>
          {messageInfo.replyToMessageInfo.text}
        </Text>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  responseText: {
    fontSize: 13,
    color: '#666'
  }
})