import React from 'react';
import {View, Text} from 'react-native';
import { getIsDebug, addGlobalErrorCatcher } from './shim';
import { FixedTouchable } from './basics';
import { Entypo } from '@expo/vector-icons';
import { saveErrorAsync } from '../data/data';

var global_errorBanner;

export class ErrorBanner extends React.Component {
  state = {}

  componentDidMount() {
    global_errorBanner = this;
  }

  setError(error) {
    this.setState({error});
  }

  render() {
    const {error} = this.state;

    if (error) {
      return (
        <View style={{flexDirection: 'row', justifyContent: 'space-between', 
              backgroundColor: 'red', paddingHorizontal: 16, paddingVertical: 10}}>
          <Text style={{color: 'white', fontSize: 16}}>{error}</Text>
          <FixedTouchable part='search-cancel' onPress={()=>this.setState({error: null})}>
            <Entypo name='circle-with-cross' size={20} color='white' style={{marginHorizontal: 8}} />   
          </FixedTouchable>
        </View>
      )
    } else {
      return null;
    }
  }
}

export function logError(errorMsg, errorDetails = null) {
  if (global_errorBanner) {
    global_errorBanner.setError(errorMsg);
  }
  if (!getIsDebug()) {
    saveErrorAsync({errorMsg, errorDetails});
  }
}


addGlobalErrorCatcher(e => {
  if (!getIsDebug()) {
    saveErrorAsync({errorMsg: e.message, errorDetails: {filename: e.filename, lineno: e.lineno}})
  }
})


