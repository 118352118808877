import { appDomain, firebaseApp } from "./config";
import { Platform } from 'react-native';
import { getCurrentDomain } from "../components/shim";
import { global_currentUser } from "./data";
// const fetch = require('node-fetch');

export function makeLeaveMeetingUrl({meeting, secret}) {
  return getCurrentDomain() 
      + '/leaveMeeting?user=' + encodeURIComponent(global_currentUser) 
      + '&meeting=' + encodeURIComponent(meeting) 
      + '&secret=' + encodeURIComponent(secret);
}

export async function requestLoginCode({email}) {
  console.log('requestLoginCode', email, getCurrentDomain());

  const fetchUrl = getCurrentDomain() + '/requestLoginCode?email=' + encodeURIComponent(email.toLowerCase());
  const response = await fetch(fetchUrl);
  const data = await response.json();
  return data;
}

export async function signinWithLoginCode({email, code, onError}) {
  console.log('signinWithLoginCode', email, code, getCurrentDomain());

  const fetchUrl = getCurrentDomain() + '/getLoginTokenForCode?email=' + encodeURIComponent(email.toLowerCase()) + '&code=' + code;
  const response = await fetch(fetchUrl);
  const data = await response.json();
  if (data.success == true) {
    firebaseApp.auth().signInWithCustomToken(data.token).catch(error => {
      onError(error.message);
    })
  } else {
    onError(data.message);
  }
}

export async function uploadImage({base64data, key, contentType}) {
  console.log('uploadImage', {base64data, key, contentType});
  const body = JSON.stringify({base64data, contentType, userId: global_currentUser, key});
  console.log('body', body.slice(0,100));
	return await fetch('https://chillchat.org/uploadImage', {
  // return await fetch('http://localhost:5000/chillchat/us-central1/uploadImage', {
		method: 'POST', mode: 'cors', cache: 'no-cache',
		headers: {'Content-Type': 'application/json'},
		body: JSON.stringify({base64data, contentType, userId: global_currentUser, key})
	})
}

// export async function takeAction({fromUser, type, data}) {
//   const response = await fetch('https://talkbeat.com/takeAction?fromUser=' + encodeURIComponent(global_currentUser));
//   const data = await response.json();
//   return data;
// }

export async function getRtcServers() {
  const response = await fetch('https://talkbeat.com/getRtcServers');
  const data = await response.json();
  return data;
}

// export function leaveMeeting(meeting) {
//   navigator.sendBeacon('https://chillchat.org/leaveMeeting/' + meeting);
// }


