import React from 'react';
import { Image, Platform, TextInput, StyleSheet, View, TouchableOpacity, Text} from 'react-native';
import Ionicons from 'react-native-vector-icons/Ionicons';
import { Entypo, FontAwesome } from '@expo/vector-icons';
import _ from 'lodash';
import { resizeImage, getClipboardImageAsync, resizeImageAsync, getTimeNow} from './shim';
import { pickImage } from './photo';
import { getUrlForImage, newKey } from '../data/fbutil';
import { global_showPhotoPopup } from './photopopup';
import { FixedTouchable, FormInput, ConIcon, getFileExtension, parseDataUrl, getNameOrYou, isWeb, ToggleCheck, getMessagePrivateTo, shouldMessageReplyBePublic } from './basics';
// import { ConvoSelector, ShareBottomPopup, global_showSharePopup } from '../screens/ConvoSelector';
import { Catcher } from './catcher';
import { global_currentUser } from '../data/data';
import { uploadImage } from '../data/servercall';
import { baseColor } from '../data/config';


function getWhoSees({conInfo, user, attendees}) {
  if (user == global_currentUser || user == 'broadcast') {
    return 'everyone'
  } else if (!conInfo) {
    return _.get(attendees, [user, 'name']);
  } else {
    // var people = '';
    const notMeMembers = _.filter(Object.keys(_.get(conInfo, 'members',{})), m => m != global_currentUser);
    const notMeNames = _.map(notMeMembers, m => _.get(attendees,[m,'name']));
    if (notMeNames.length == 0) {
      return 'nobody';
    } else {
      return _.join(notMeNames,', ');
    }
  }
}


function MessageDestInfo({conInfo, user, attendees}) {
  return ( 
    <View style={{marginHorizontal: 12, marginBottom: 8}}>
      <Text numberOfLines={1} style={{fontSize: 12, color: '#666'}}>Will be visible to {getWhoSees({conInfo, user, attendees})}</Text>
    </View>
  )
}

function PhotoPreview({photo, onClearPhoto}) {
  return (
    <View style={{marginHorizontal: 12, marginBottom: 4, flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between'}}>
      <View style={{flexDirection: 'row'}}>
        <FixedTouchable onPress={()=>global_showPhotoPopup({photo})}>
          <Image source={{uri: getUrlForImage(photo)}} 
            style={{width: 150, height: 150, borderRadius: 8}} />
        </FixedTouchable>
      </View>
      <TouchableOpacity onPress={onClearPhoto}>
        <Entypo name='circle-with-cross' size={20} color='#666' />
      </TouchableOpacity>
    </View>
  )
}

class ReplyPreview extends React.Component {
  state = {};
  // onFork() {
  //   const {messageInfo, user, onMove} = this.props;
  //   global_showSharePopup({messageInfo, user, onSelectCon: async ({selectedCon, selectedConInfo}) => 
  //     onMove({selectedCon, selectedConInfo})
  //   });
  // }

  render() {
    const {replyToMessage, replyToMessageInfo, isPublic, selectedCon, onClearReply} = this.props;
    return (
      <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
        <RepliedMessagePreview isPublic={isPublic} replyToMessage={replyToMessage} replyToMessageInfo={replyToMessageInfo} onClearReply={onClearReply} />
        {/* {selectedCon ? null : 
          <FixedTouchable onPress={()=>this.onFork()}>
            <View style={{backgroundColor: baseColor, marginBottom: 4, marginRight: 8,
                  paddingHorizontal: 12, paddingVertical: 4, 
                  flexDirection: 'row', alignItems: 'center', 
                  borderRadius: 8}}>
              <FontAwesome name='code-fork' size={20} color='white' />
              <Text style={{color: 'white', marginLeft: 8, fontSize: 16}}>Move</Text>
            </View>
          </FixedTouchable> */}
        {/* }         */}
    </View>
    )
  }
}

function RepliedMessagePreview({replyToMessage, isPublic, replyToMessageInfo, onClearReply}) {
  if (replyToMessageInfo.photo) {
    return (
      <View style={{flex: 1, marginHorizontal: 12, marginBottom: 4, flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between'}}>
        <View style={{flexDirection: 'row'}}>
          <Text style={{color: '#666'}}>Replying {!isPublic ? 'privately ' : ''}to: </Text> 
          <FixedTouchable onPress={()=>global_showPhotoPopup({photo: replyToMessageInfo.photo})}>
            <Image source={{uri: getUrlForImage(replyToMessageInfo.photo)}} 
              style={{width: 75, height: 75, borderRadius: 4}} />
          </FixedTouchable>
        </View>
        <TouchableOpacity onPress={onClearReply}>
          <Entypo name='circle-with-cross' size={20} color='#666' />
        </TouchableOpacity>
      </View>
    )  

  } else {
    return (
      <View style={{flex: 1, marginHorizontal: 12, marginBottom: 4, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
        <Text numberOfLines={1} style={{flexShrink: 1, flex: 1}}>
          <Text style={{fontWeight: '500'}}>Replying {!isPublic ? 'privately ' : ''}to: </Text> 
          <Text style={{flex: 1, flexShrink: 1, color: '#666'}}>{_.get(replyToMessageInfo,'text','')}</Text>
        </Text>
        <TouchableOpacity onPress={onClearReply}>
          <Entypo name='circle-with-cross' size={20} color='#666' />
        </TouchableOpacity>
      </View>
    )  
  }
}

var global_saveDrafts = {};
export function clearDraftMessages() {
  global_saveDrafts = {};
}

export class MessageEntryBox extends React.Component {
  state = {text: null, textKey: 0, focussed: false, editTime: 0, isPublic: true}

  componentDidMount() {
    const {saveKey} = this.props;
    if (saveKey && global_saveDrafts[saveKey] && global_saveDrafts[saveKey].trim()) {
      this.setState({text: global_saveDrafts[saveKey]});
    }
  }

  handleKeyDown(e) {
    if (Platform.OS == 'web' && e.nativeEvent.key == 'Enter') {
      if (!e.nativeEvent.shiftKey && !e.nativeEvent.ctrlKey && !e.nativeEvent.metaKey){
        this.submitText();    
      }
    }
  }

  async submitText() {
    const {replyToMessage, replyToMessageInfo, onTextEntered, saveKey, user, userInfo} = this.props;
    const {editedMessage, editedMessageInfo, textKey, text, photo, conName, selectedCon, selectedConInfo, newCon} = this.state;
    // const forcePrivate = replyToMessageInfo && !shouldMessageReplyBePublic({messageInfo: replyToMessageInfo, user});
    const forcePrivate = false;
    const isPublic = this.state.isPublic && !forcePrivate; // !(replyToMessageInfo && !replyToMessageInfo.isBoard); // && (userInfo.host || user == global_currentUser);

    // console.log('submitText', text, isPublic);

    global_saveDrafts[saveKey] = '';
    // onCancelReply();
    if (Platform.OS == 'web') {
      this.setState({
          photo: null, text: null, focussed: false, conName: null, newCon: null, isPublic: true,
          selectedCon: null, selectedConInfo: null, editedMessage: null, editedMessageInfo: null})
      this.textinput.clear();
    } else {
      this.setState({textKey:textKey+1, 
          photo: null, text: null, focussed: false, conName: null, newCon: null, isPublic: true, 
          selectedCon: null, selectedConInfo: null, editedMessage: null, editedMessageInfo: null})    
    }
    await onTextEntered({text, isPublic, newCon, conName, selectedCon, selectedConInfo, editedMessage, editedMessageInfo, replyToMessage, replyToMessageInfo, photo}); 
  }

  changeText(newText) {
    const {onStartTyping, onEndTyping, saveKey} = this.props;
    const {text} = this.state;
    if (saveKey) {
      global_saveDrafts[saveKey] = newText;
    }
    if (Platform.OS == 'web' && newText == '\n') {
      this.setState({text: null, editTime: getTimeNow()});
      this.textinput.clear();      
    } else {
      this.setState({text: newText, editTime: getTimeNow()});
      if (!text && newText) {
        onStartTyping();
      } else if (text && !newText) {
        onEndTyping();
      }
    }
  }

  editMessage({message, messageInfo}) {
    this.setState({editedMessage: message, editedMessageInfo: messageInfo, text: messageInfo.text, conName: null, 
      editBoard: messageInfo.isBoard,
      newCon: false, selectedCon: null, replyToMessage: null, replyToMessageInfo: null, isPublic: messageInfo.requestPublic})   
  }

  focus() {
    // console.log('focus');
    this.textinput.focus();
  }

  async uploadPhoto(uri) {
    const {contentType, base64data} = parseDataUrl(uri);    
    // console.log('parsed', contentType, base64data);
    const {uploadCount = 0} = this.state;
    const key = newKey('photo');
    this.setState({uploadCount: uploadCount + 1});
    await uploadImage({base64data, contentType, key, userId: global_currentUser})
    const fileExtension = getFileExtension(contentType);
    const photo = global_currentUser+'/' + key + fileExtension;
    // const imageUrl = getUrlForImage(photo);
    this.setState({photo});
    // await this.sendMessage({photo});
    this.setState({uploadCount: this.state.uploadCount - 1});
  }

  async onPaste(event) {
    // const {onSendPhoto} = this.props;
    const {text} = this.state;
    const bigUrl = await getClipboardImageAsync(event);  
    if (!bigUrl) {
      return;
    }
    // this.setState({text: text || ''});

    const smallUrl = await resizeImageAsync({url: bigUrl, rotate: false, maxSize: 800});
    await this.uploadPhoto(smallUrl);
    // this.setState({photo: smallUrl});
    // this.setState({text: text || ''});

    // onSendPhoto(smallUrl);
  }

  async onMove() {
    const {user} = this.props;
    global_showSharePopup({user, onSelectCon: async ({selectedCon, selectedConInfo}) => 
      this.setState({selectedCon, selectedConInfo})
    });  
  }

  async onSelectPhoto() {
    // const {onSendPhoto} = this.props;
    // console.log('onSelectPhoto');
    const pickedImage = await pickImage();
    // console.log('pickedImage', pickedImage);
    if (!pickedImage || !pickedImage.uri) return;
    // console.log('bigUrl', pickedImage.uri);
    const smallUrl = await resizeImageAsync({url: pickedImage.uri, pickedImage, rotate: true, maxSize: 800});
    // console.log('smallUrl', smallUrl.slice(0,500));
    await this.uploadPhoto(smallUrl);
    // console.log('uploaded');
    // this.setState({photo:smallUrl});
    // await onSendPhoto(smallUrl);
  }

  // async sendGroup({selectedCon, selectedConInfo}) {
  //   const {meeting, user, conInfo} = this.props;
  //   console.log('sendGroup', selectedCon, selectedConInfo)
  //   await shareConversationAsync({meeting, user, conInfo, sharedCon:selectedCon, sharedConName: selectedConInfo.name})
  // }

  render (){
    const {expandTime, attendees, userInfo, style, help, userName, destName, placeholder, meeting, user, conInfo, replyToMessageInfo, replyToMessage, onClearReply, onFocusChange, onAddPhoto} = this.props;
    const {editTime, editBoard, uploadCount, editedMessage, editedMessageInfo, photo, text, focussed, conPick, conName, newCon, selectedCon, selectedConInfo} = this.state;
    // const forcePrivate = replyToMessageInfo && !shouldMessageReplyBePublic({messageInfo: replyToMessageInfo, user});
    const forcePrivate = false;
    const isPublic = this.state.isPublic && !forcePrivate; // !(replyToMessageInfo && !isPublished({messageInfo: replyToMessageInfo, user})); // && (userInfo.host || user == global_currentUser);
    const expanded = text || newCon || expandTime > editTime;
    const viewer = getMessagePrivateTo({user, userInfo, replyToMessageInfo, isPublic: false});

    return (
      <View>
        <View style={style || {backgroundColor: 'white', paddingVertical: 8,
              borderTopColor: '#ddd', borderTopWidth: StyleSheet.hairlineWidth}} >
          {/* {expanded ?
            <Catcher>
              <MessageDestInfo attendees={attendees} user={user} conInfo={conInfo} />
            </Catcher>
          : null} */}
          {/* {selectedCon && (!conInfo || !conInfo.isPublic) && replyToMessageInfo && replyToMessageInfo.from != global_currentUser ? 
            <Text style={{marginLeft: 12, color: '#666'}}>
              Moving reply will require permission from {getNameOrYou({user:replyToMessageInfo.from, attendees})}
            </Text>
          : null } */}
          {/* {((replyToMessageInfo && replyToMessageInfo.isBoard 
              && replyToMessageInfo.from != global_currentUser) || 
              (user != global_currentUser && userInfo.host != global_currentUser && !replyToMessageInfo)) 
               && !_.get(editedMessageInfo, 'isBoard' && !forcePrivate) ? */}
          {!forcePrivate && !(!replyToMessageInfo && user == global_currentUser) && !(replyToMessage && replyToMessageInfo.isBoard && replyToMessageInfo.from == global_currentUser) ?
            <View style={{alignSelf: 'flex-end', marginBottom: 8, marginHorizontal: 12}}>
              <ToggleCheck part='private' value={!isPublic} onValueChange={isPrivate => this.setState({isPublic: !isPrivate})} 
                label={'just to ' + getNameOrYou({user: viewer, attendees})} />
                {/* 'Private' />  */}
                 {/* /={replyToMessageInfo ? 'Ask to Publish' : 'Public'} /> */}
            </View>
          : null}
          {!uploadCount ? null :
            <View style={{flexDirection: 'row', alignItems: 'center', marginBottom: 8}}>
              <Text style={{flex: 1, marginLeft: 12, color: '#666'}}>
                Uploading photo...
              </Text>
            </View>
          }
          {!editedMessage ? null :
            <View style={{flexDirection: 'row', alignItems: 'center', marginBottom: 8}}>
              <Text style={{flex: 1, marginLeft: 12, color: '#666'}}>
                Editing previous message
              </Text>
              <FixedTouchable onPress={() => this.setState({editedMessage: null, text: ''})}>
                <Entypo name='circle-with-cross' size={20} color='#666' style={{marginRight: 12, marginLeft: 8}} />
              </FixedTouchable>
            </View>
          }
          {!newCon ? null :
            <View style={{flexDirection: 'row', alignItems: 'center', marginBottom: 8}}>
              <TextInput part='conName' style={styles.textInput} 
                placeholder={'What is your New Conversation about?'}
                onChangeText={conName=>this.setState({conName})}
              />
              <FixedTouchable onPress={() => this.setState({newCon: false, conName: null})}>
                <Entypo name='circle-with-cross' size={20} color='#666' style={{marginRight: 12, marginLeft: 8}} />
              </FixedTouchable>
            </View>
          }
          {!selectedCon ? null : 
            <View style={{flexDirection: 'row', marginLeft: 12, alignItems: 'center'}}>
              {/* <ConIcon size={30} conInfo={selectedConInfo} style={{marginLeft: 12, marginRight: 8}} /> */}
              <Text part='conName' style={{flex: 1, fontWeight: '300', marginBottom: 4}}>
                <Text style={{fontWeight: '500'}}>Moving to: </Text>
                {selectedConInfo.name}
              </Text>
              <FixedTouchable onPress={() => this.setState({selectedCon: null, conName: null})}>
                <Entypo name='circle-with-cross' size={20} color='#666' style={{marginRight: 12, marginLeft: 8}} />
              </FixedTouchable>
            </View>
          }
          {!replyToMessage ? null : 
            <ReplyPreview user={user} isPublic={isPublic} selectedCon={selectedCon} replyToMessageInfo={replyToMessageInfo} onClearReply={onClearReply} 
                onMove={({selectedCon, selectedConInfo}) => this.setState({selectedConInfo, selectedCon})}/>
          }
          {!photo ? null : <PhotoPreview photo={photo} onClearPhoto={()=>this.setState({photo:null})} />}
          <View style={styles.horizBox}>
            {/* {(expanded || user == global_currentUser) ? null :
              <FixedTouchable part='move' onPress={()=>this.onMove()} title='Move'>
                <FontAwesome name='code-fork' size={30} color='#0084ff' style={{marginLeft: 12}} />
              </FixedTouchable>
            } */}
            {/* {expanded ? null :  */}
              <FixedTouchable part='photo' onPress={() => this.onSelectPhoto()} title='Add Image' >
                <FontAwesome name='photo' size={30} color='#0084ff' style={{marginLeft: 12}} />
              </FixedTouchable>
            {/* } */}
            {/* {(expanded || conInfo) ? null :
              <View>
                <FixedTouchable part='shareCon' onPress={() => this.setState({conPick:!conPick})} title='Invite to a Group Conversation'>              
                  <FontAwesome name='group' size={24} color='#0084ff' style={{marginLeft: 12, marginRight: 4}} />
                </FixedTouchable>
                {conPick ? 
                  <Catcher>
                    <ShareBottomPopup meeting={meeting} user={user} conInfo={conInfo} destName={destName}
                      onClose={()=>this.setState({conPick: false, selectedCon: null})}
                      onSelectCon={({selectedCon,selectedConInfo,conName}) => {
                        this.sendGroup({selectedCon, selectedConInfo});
                        this.setState({conPick: false})
                        // this.setState({selectedCon, selectedConInfo, conName, newCon: false, conPick: false})
                        // onClearReply();
                      }}
                      // onNewCon={()=>{
                      //   this.setState({newCon: true, selectedCon: null, conName: '', conPick: false});
                      //   onClearReply();
                      // }}
                    />
                  </Catcher>
                : null} */}
              {/* </View>
            } */}
            <TextInput part='messageText' key={this.state.textKey}
              ref={r => this.textinput = r}
              autoFocus={(isWeb || expandTime) ? true : false}
              style={[isPublic || _.get(editedMessageInfo,'isBoard') ? styles.publicInput : styles.textInput, 
                    isWeb ? {height: expanded ? 100 : null, flexShrink: 0} : {maxHeight: 100}]}
              underlineColorAndroid='transparent'
              multiline
              blurOnSubmit={false}
              value={text || ''}
              onPaste={e => {this.onPaste(e); return false}}
              onFocus={() => {this.setState({focussed:true}); onFocusChange()}}
              onBlur={() => this.setState({focussed:false})}
              onChangeText={text=>this.changeText(text)}
              placeholder={(isPublic ? 'Write a public ': 'Write a private ') + (replyToMessage ? 'reply' : 'message')}
              placeholderTextColor={isPublic ? '#666' : '#999'}
              // placeholder={(newCon || selectedCon) ? ('Write a message to ' + destName + ' and the board of ' + conName) : placeholder} 
              onKeyPress={e => this.handleKeyDown(e)}
            />
            <TouchableOpacity part='sendMessage' onPress={() => this.submitText()} >
              <Ionicons style={styles.sendIcon} name='md-send' size={30} style={{width: 40, textAlign: 'center'}} 
                color={(newCon && (!conName || !text)) ? 'gray' : '#0084ff'} />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  horizBox: {
    flexDirection: 'row',
    alignItems: 'center',
    // marginVertical: 8
  },
  sendIcon: {
    paddingRight: 8,
    paddingLeft: 8,
  },
  textInput: {
    flex: 1,
    paddingHorizontal: 12,
    paddingVertical: 8,
    // paddingTop: 10,
    borderColor: '#bbb',
    borderWidth: StyleSheet.hairlineWidth,
    borderRadius: 16,
    marginLeft: 8,
    // marginTop: 8,
    // marginBottom: 8,
    marginRight: 0,
    fontSize: 16,
    color: 'white',
    backgroundColor: "#222",
    textAlignVertical: 'center'
  },
  publicInput: {
    flex: 1,
    shadowOpacity: 0.5, shadowRadius: 4, shadowColor: '#555', shadowOffset: {width: 0, height: 2},
    paddingHorizontal: 12,
    paddingVertical: 8,
    // paddingTop: 10,
    borderColor: '#ddd',
    borderWidth: StyleSheet.hairlineWidth,
    borderRadius: 8,
    marginLeft: 8,
    // marginTop: 8,
    // marginBottom: 8,
    marginRight: 0,
    fontSize: 16,
    backgroundColor: 'white',
    textAlignVertical: 'center'
  },

  fakeInput: {
    flex: 1,
    paddingHorizontal: 12,
    paddingVertical: 6,
    borderColor: '#bbb',
    borderWidth: StyleSheet.hairlineWidth,
    borderRadius: 16,
    marginLeft: 8,
    marginTop: 8,
    marginBottom: 8,
    marginRight: 0,
    backgroundColor: "#eee"
  },
  fakeText: {
    fontSize: 16,
    color: 'gray',
  },
  buttonHolder: {
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  doneButton: {
    paddingHorizontal: 12,
    paddingVertical: 6,
    marginLeft: 8,
    backgroundColor: '#eee',
    borderColor: '#bbb',
    borderWidth: StyleSheet.hairlineWidth,
    borderRadius: 16,
    marginRight: 4,
  }
})

export default MessageEntryBox;