import React from 'react';
import {View, Text, Image, StyleSheet} from 'react-native';
import {findDOMNode} from 'react-dom';

import _ from 'lodash';
import { sendOfferAsync, watchOfferAsync, receiveOffer, sendAnswerAsync, receiveAnswer, global_currentUser, sendCandidateAsync, receiveCandidate, sendCallStatus, receiveCallStatus, clearIncomingCallStatus, requestVideoCallWithUserAsync, watchVideoCallRequest, watchMeetingAttendees, clearVideoCallRequestAsync, clearOutboundVideoCallRequestAsync, receiveCallEnd, sendEndCall, leaveActiveConversationForVideoAsync, setCurrentVideoCall } from '../data/data';
import { WideButton, FixedTouchable, secondMillis, UserIcon, objToJSON } from './basics';
import { newKey, internalReleaseWatchers } from '../data/fbutil';
import { FontAwesome, Ionicons, Entypo, MaterialIcons } from '@expo/vector-icons';
import { getTimeNow, playRingSound, stopRingSound } from './shim';
import { logError } from './error';
import { getRtcServers } from '../data/servercall';


// var global_callEnded = {};

var global_videoRingPopup;

export class VideoRingPopup extends React.Component {
  state = {callEnded: {}}

  ringing = false;

  componentDidMount() {
    const {meeting} = this.props;
    // console.log('== Init VideoRingPopup ==');
    global_videoRingPopup = this;
    stopRingSound();
    watchVideoCallRequest(this, {meeting}, requests => this.setState({requests}));
    watchMeetingAttendees(this, meeting, attendees => this.setState({attendees}));
  }
  async componentWillUnmount(){
    await stopRingSound();
    internalReleaseWatchers(this);
  }

  setCallingUser({callingUser}) {
    this.callingUser = callingUser;
    this.setState({callingUser});
    console.log('setCallingUser');
  }

  getLatestRingRequest(requests, callingUser) {
    const {callEnded} = this.state;
    const now = getTimeNow();
    // console.log('getLatestRingRequest', requests);
    if (requests) {
      const senders = Object.keys(requests);
      const latestSender = _.sortBy(senders, s => requests[s].time).reverse()[0];
      const callInfo = requests[latestSender];
      if (callInfo.time < now - (secondMillis * 20)) {
        return null;        
      }
      if (callingUser == latestSender) {
        return null;
      }
      if (callEnded[callInfo.sessionId]) {
        console.log('already ended', callInfo.sessionId);
        return null;
      }
      return latestSender;
    }  
  }

  markCallEnded(sessionId) {
    this.setState(({callEnded}) => ({callEnded: {...callEnded, [sessionId]: true}}));
  }

  async acceptCall({callingUser, sessionId}) {
    const {meeting, onSetVideoCall} = this.props;
    console.log('== accepted ==', sessionId);
    this.markCallEnded(sessionId);
    await stopRingSound();
    onSetVideoCall({callingUser, sessionId, callMode: 'answered'});
    global_videoPanel.acceptVideoCall({meeting, user: callingUser, sessionId});
    await clearVideoCallRequestAsync({meeting, user: callingUser});
    // await leaveActiveConversationForVideoAsync({meeting, user: callingUser});
    await stopRingSound();
    console.log('call accept finished', sessionId);
  }

  async declineCall({callingUser, sessionId}) {
    const {meeting} = this.props;
    console.log('== DECLINED ==', sessionId);
    await stopRingSound();
    this.markCallEnded(sessionId);
    await sendEndCall({meeting, user:callingUser, sessionId});
    await clearVideoCallRequestAsync({meeting, user: callingUser});
    await stopRingSound();
    console.log('call decline finished', sessionId);
  }
  
  render() {
    const {requests, attendees, callingUser} = this.state;
    const latestSender = this.getLatestRingRequest(requests, callingUser);
    // console.log('latestSender', latestSender);
    if (latestSender) {
      playRingSound(requests[latestSender].sessionId);
    } else {
      stopRingSound();
    }

    if (latestSender && attendees) {
      const callInfo = requests[latestSender];
      console.log('ringing', callInfo, callingUser);
      const userInfo = _.get(attendees, latestSender);
      return (
        <View style={{position: 'absolute', justifyContent: 'center', left: 0, top: 0, right: 0, bottom: 0}}>
          <View style={{position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, backgroundColor: 'black', opacity: 0.5}} />
          <View style={{
              backgroundColor: 'white', width: 350, alignSelf: 'center',
              shadowRadius: 4, shadowColor: '#555', shadowOffset: {width: 0, height: 2}
            }}>
            <View style={{padding: 16}}>
              <Text style={{color: '#666'}}>Incoming Video Call from</Text>
              <View style={{alignSelf: 'center', flexDirection: 'row', alignItems: 'center', marginVertical: 16}}>
                <UserIcon userInfo={userInfo} thumb={false} size={100} />
                <Text style={{fontSize: 16, color: '#222', marginLeft: 16}}>
                  {userInfo.name}
                </Text>
              </View>
              <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around', marginTop: 8}}>
                <FixedTouchable part='accept' onPress={async ()=>this.acceptCall({callingUser: latestSender, sessionId: callInfo.sessionId})} >
                  <View style={{backgroundColor: '#6BAB12', paddingHorizontal: 12, paddingVertical: 8, borderRadius: 8}}>
                    <Text style={{color: 'white', fontWeight: 'bold'}}>Accept</Text>
                  </View>
                </FixedTouchable>
                <FixedTouchable part='reject' onPress={async ()=>this.declineCall({callingUser: latestSender, sessionId: callInfo.sessionId})} >
                  <View style={{backgroundColor: '#F64542', paddingHorizontal: 12, paddingVertical: 8, borderRadius: 8, borderColor: '#ddd', borderWidth: StyleSheet.hairlineWidth}}>
                    <Text style={{color: 'white'}}>Decline</Text>
                  </View>
                </FixedTouchable>
              </View>
            </View>
            {callingUser ? 
              <View style={{borderColor: '#ddd', 
                    borderTopWidth: StyleSheet.hairlineWidth, marginTop: 16, padding: 16}}>
                <Text style={{color: '@'}}>Accepting this call with end your call with {_.get(attendees,[callingUser,'name'])}</Text>
              </View>
            : null}
          </View>       
        </View>
      )
    } else {
      return null;
    }
  }
}


export class VideoCallDialog extends React.Component {
  state = {}
  
  componentDidMount() {
    const {meeting} = this.props;
    watchMeetingAttendees(this, meeting, attendees => this.setState({attendees}));
  }
  componentWillUnmount(){
    internalReleaseWatchers(this);
  }
  
  render() {
    const {user, onCancelCall} = this.props;
    const {attendees} = this.state;
    const userInfo = _.get(attendees, user);
    if (!attendees) return null;
    return (
      <View style={{position: 'absolute', justifyContent: 'center', left: 0, top: 0, right: 0, bottom: 0}}>
        <View style={{
            backgroundColor: 'white', width: 350, alignSelf: 'center', padding: 16,
            shadowRadius: 4, shadowColor: '#555', shadowOffset: {width: 0, height: 2}
          }}>
          <Text style={{color: '#666'}}>Calling</Text>
          <View style={{alignSelf: 'center', flexDirection: 'row', alignItems: 'center', marginVertical: 16}}>
            <UserIcon userInfo={userInfo} thumb={false} size={100} />
            <Text style={{fontSize: 16, color: '#222', marginLeft: 16}}>
              {userInfo.name}
            </Text>
          </View>
          <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around', marginTop: 8}}>
            <FixedTouchable part='reject' onPress={onCancelCall} >
              <View style={{backgroundColor: '#F64542', paddingHorizontal: 12, paddingVertical: 8, borderRadius: 8, borderColor: '#ddd', borderWidth: StyleSheet.hairlineWidth}}>
                <Text style={{color: 'white'}}>Cancel</Text>
              </View>
            </FixedTouchable>
          </View>
        </View>          
      </View>
    )
  }
}



export function VideoButton({meeting, user}) {
  return (
    <FixedTouchable onPress={() => global_videoPanel.requestVideoCall({meeting, user})}>
      <FontAwesome name='video-camera' color='#0084ff' size={30} style={{marginRight: 16}} />
    </FixedTouchable>
  )
}

var global_videoPanel = null;

export class VideoPanel extends React.Component {
  state = {}
  
  setSelectedUser(selectedUser) {
    this.setState({selectedUser});
  }

  async componentDidMount() {    
    global_videoPanel = this;
    const pRtcSetup = getRtcServers();
    this.pRtcSetup = pRtcSetup;
    const rtcSetup = await pRtcSetup;
    this.rtcSetup = rtcSetup;
    console.log('rtcSetup', rtcSetup);
  }

  repeatCalling({meeting, user, sessionId, count = 0}){
    console.log('repeatCalling - EH?', meeting, user, sessionId);
    if (this.requestingSessionId == sessionId) {
      requestVideoCallWithUserAsync({meeting, user, sessionId})
      setTimeout(() => {
        console.log('do repeat');
        this.repeatCalling({meeting, user, sessionId, count: count + 1});
      }, 10000);
    } else {
      console.log('call over');
    }
  }
  
  async requestVideoCall({meeting, user}) {
    const {onSetVideoCall} = this.props;
    const sessionId = newKey();
    console.log('requestVideoCall _OH', meeting, user, sessionId);
    this.requestingSessionId = sessionId;
    this.setState({answered: false});
    console.log('requestVideoCall', meeting, user, sessionId);
    await this.repeatCalling({meeting, user, sessionId: sessionId});
    onSetVideoCall({callingUser: user, sessionId});
    await this.startCall({meeting, user, sessionId});
    this.setState({meeting, user, callMode: 'called'});
  }

  async acceptVideoCall({meeting, user, sessionId}) {
    console.log('acceptCall', {meeting, user, sessionId});
    await this.startCall({meeting, user, sessionId});
    await this.setState({meeting, user, callMode: 'answered'});
  }

  async startCall({meeting, user, sessionId}) {
    const {onSetVideoCall} = this.props;
    console.log('startCall', {meeting, user, sessionId, oldSessionId: this.requestingSessionId})
    if (this.oldSessionId) {
      try {
        await this.videoCall.endCall();
        console.log('== ended old call ===', this.oldSessionId);
      } catch (e) {
        logError('failed to shut down old call', e)
      }
    }
    this.oldSessionId = sessionId;
    await this.videoCall.startCall({meeting, user, sessionId, rtcSetup: this.rtcSetup,
      onConnectProgress: connectState => this.setState({connectState}),
      onEndCall: ()=>this.onEndCall({meeting, user}),
      onCallEnded: ()=>this.onCallEnded({meeting, user}),
      onCallStarted: ()=>this.onCallStarted({meeting, user})
    });
    await onSetVideoCall({callingUser: user, sessionId});
    this.setState({meeting, user, callMode: 'called'});
    await setCurrentVideoCall({meeting, user});
  }

  async onCallEnded({user}) {
    const {onSetVideoCall, onSelectUser} = this.props;
    const {meeting} = this.state;
    this.requestingSessionId = null;
    this.oldSessionId = null;
    onSetVideoCall({callingUser: null, sessionId: null});
    onSelectUser({user});
    this.setState({answered: false, user: null, callMode: null});
    await setCurrentVideoCall({meeting, user: null});
  }

  async onCancelCall({meeting, user}) {
    const {onSetVideoCall, onSelectUser} = this.props;
    this.requestingSessionId = null;
    console.log('cancelCall');
    await clearOutboundVideoCallRequestAsync({meeting, user})
    await this.videoCall.hangup();
    await onSetVideoCall({callingUser: null, sessionId: null});
    await onSelectUser({user});
    console.log('call cancelled');
  }

  async onCallStarted({meeting, user}) {
    this.requestingSessionId = null;
    this.setState({answered: true});
    await clearOutboundVideoCallRequestAsync({meeting, user});
    // await leaveActiveConversationForVideoAsync({meeting, user});
  }
  
  render() {
    // const {callMode} = this.props;
    const {selectedUser, answered, meeting, user, callMode} = this.state;

    // if (callingUser) {
    return (
      <View
          style={{
            display: selectedUser == 'video' ? undefined : 'none',
            position: 'absolute', left: 300, right: 0, top: 0, bottom: 0
        }}>
        <VideoCall key='video-call' ref={r => this.videoCall = r} />
        {/* {connectState ? 
          <ConnectState connectState={connectState} />
        : null} */}
        {!answered && callMode=='called' ? 
          <VideoCallDialog meeting={meeting} user={user} onCancelCall={()=>this.onCancelCall({meeting, user})}/>
        : null}
      </View>
    )
    // } else {
    //   return null;
    // }
  }
}


// function ConnectState({connectState}) {
//   if (!connectState) return null;
//   return (
//     <View style={{
//       position: 'absolute', top: 0, buttom: 0, left: 0, right: 0,
//       justifyContent: 'center'
//     }}>
//       <Text style={{justifyContent: 'center', textAlign: 'center'}}>
//         {connectState}
//       </Text>
//     </View>
//   )
// }

function logCan(mode, candidate) {
  console.log(mode, _.get(candidate,'candidate','null').slice(0,40))
}


export class VideoCall extends React.Component {
  state = {}

  async componentDidMount() {
  }

  async startCall({meeting, user, sessionId, rtcSetup, onCallStarted, onCallEnded, onEndCall}) {
    // const {meeting, user, sessionId, onCallStarted, onCallEnded, onEndCall} = this.props;
    console.log('=== Start Video Call', {meeting, user, sessionId, onCallStarted, onCallEnded})
    this.onCallEnded = onCallEnded;

    this.videoSource = new VideoSource();

    // console.log('getUserMedia');
    var stream;
    try {
      stream = await navigator.mediaDevices.getUserMedia({audio: true, video: true});
    } catch (e) {
      logError('Could not access camera. Is it already in use?');
      await onEndCall();
      throw e;
    }
    this.stream = stream;
    console.log('got stream');
    const iceServers = rtcSetup.iceServers;
    await this.videoPreview.initPreview(stream);
    await this.videoSource.createSourceConnection(stream, iceServers,  candidate => {
      // logCan('send out', candidate);
      sendCandidateAsync({meeting, user, sessionId, endpoint: 'receiver', candidate})
    });
    await this.videoReceiver.createReceiverConnection(iceServers, candidate => {
      // logCan('send in', candidate);
      sendCandidateAsync({meeting, user, sessionId, endpoint: 'source', candidate})
    });

    receiveOffer(this, {meeting, user, sessionId}, async offerMsg => {
      console.log('=== Call Answered ===', sessionId)
      // console.log('receive offer');
      const inOffer = new RTCSessionDescription(offerMsg);
      const outAnswer = await this.videoReceiver.getAnswer(inOffer);
      // console.log('send answer');
      await sendAnswerAsync({meeting, user, sessionId, answer: objToJSON(outAnswer)});
      await onCallStarted();
      this.setState({answered: true});

      receiveCandidate(this, {meeting, user, sessionId, endpoint: 'receiver'}, candidate => {
        // logCan('receive input', candidate);
        this.videoReceiver.receiveMessage(candidate)
      });  
    });

    receiveAnswer(this, {meeting, user, sessionId}, async answerMsg => {
      // console.log('receive answer');
      const inAnswer = new RTCSessionDescription(answerMsg);
      await this.videoSource.setAnswer(inAnswer);      

      receiveCandidate(this, {meeting, user, sessionId, endpoint: 'source'}, candidate => {
        // logCan('receive output', candidate);
        this.videoSource.receiveMessage(candidate)
      });  
    });

    receiveCallEnd(this, {meeting, user, sessionId}, async callEndInfo => {
      // console.log('call end received', callEndInfo, this)
      await this.hangup();
      this.onCallEnded();
    });

    const outOffer = await this.videoSource.getOffer();
    // console.log('send offer');
    await sendOfferAsync({meeting, user, sessionId, offer: objToJSON(outOffer)});

    this.setState({meeting, user, sessionId});

    // this.startCall();
  }
  componentWillUnmount(){
    internalReleaseWatchers(this);
  }

  onMouseMove() {
    this.setState({showControls: true, showStarted: getTimeNow()});
    const showStarted = getTimeNow();
    this.showStarted = showStarted;
    setTimeout(() => {
      if (this.showStarted == showStarted) {
        this.setState({showControls: false})
      }
    }, 2000)
  }

  enterFullScreen() {
    const {expanded} = this.state;
    const {onRequestFullScreen} = this.props;
    if (expanded) {
      document.exitFullscreen();
      this.setState({expanded: false});
    } else {
      onRequestFullScreen();
      this.setState({expanded: true});
    }
  }

  async hangup() {
    this.videoReceiver && this.videoReceiver.hangup();
    this.videoSource && this.videoSource.hangup(); 
    this.videoPreview && this.videoPreview.close();
    this.stream && this.stream.getTracks().forEach( track => track.stop());
    try {
      await document.exitFullscreen();
    } catch (e) { 
      console.log('not in full screen');
    }
  }

  async endCall() {
    const {meeting, user, sessionId} = this.state;
    const pSend = sendEndCall({meeting, user, sessionId, reason: 'ended'});
    await this.hangup();
    await this.onCallEnded();
    await pSend;
    internalReleaseWatchers(this);
  }

  render() {
    const {showControls, expanded, answered, meeting, user, sessionId} = this.state;
    return (
      <View ref={ref=>this.mainView = ref} style={{flex: 1}} onMouseMove={()=>this.onMouseMove()}>
        {/* <VideoSource ref={r => this.videoSource = r} /> */}
        <VideoReceiver ref={r => this.videoReceiver = r} style={{flex: 1}} />
        <VideoPreview ref={r => this.videoPreview = r} />
        {showControls && answered ? 
          <View style={{position: 'absolute', left: 0, right: 0, bottom: 16, flexDirection: 'row', justifyContent: 'center'}}>              
            <FixedTouchable onPress={()=>this.endCall()}>
              <View style={{backgroundColor: '#F64542', width: 60, height: 60, borderRadius: 30}}>
                <Entypo name='cross' color='white' size={60} style={{marginTop: -2, marginLeft: 1}} />
              </View>
            </FixedTouchable>
          </View>
        : null}
        {showControls && answered ? 
          <View style={{position: 'absolute', right: 20, top: 16, flexDirection: 'row', justifyContent: 'center'}}>              
            <View style={{zIndex: -1, position: 'absolute', right: expanded ? 3 : -10, top: expanded ? 0 : -8, width: 55, height: 55, borderRadius: 16, backgroundColor: 'black', opacity: 0.5, }} />
            <FixedTouchable onPress={() => this.enterFullScreen()}>
              {expanded ? 
                <MaterialIcons name='fullscreen-exit' color='white' size={60} /> 
              :
                <FontAwesome name='expand' color='white' size={40} />
              } 
            </FixedTouchable>
          </View>
        : null}
      </View>
    )
  }
}

export class VideoPreview extends React.Component {
  async componentDidMount() {
    if (this.stream) {
      this.setVideoStream(this.stream);
    }
  }

  initPreview(stream) {
    this.stream = stream;  
    this.setVideoStream(stream);
  }

  setVideoStream(stream) {
    const localVideo = document.getElementById('video-preview');
    if (localVideo && localVideo.srcObject != stream) {
      localVideo.srcObject = stream;
    }
  }

  close() {
    this.stream && this.stream.getTracks().forEach( track => track.stop() );
  }

  render() {
    return (
      <video muted autoPlay playsInline id='video-preview' 
          style={{
              position: 'absolute', bottom: 0, right: 0,
              objectFit: 'cover', width: 150, height: 100, 
              border: '1px solid #666'}}>   
      </video>
    )    
  }
}

class VideoSource {
  async receiveMessage(candidate) {
    if (candidate) {
      try {
        await this.sourceConnection.addIceCandidate(new RTCIceCandidate(candidate));
      } catch (e) {
        console.error('Bad Source Candidate', candidate);
        console.log(e);
      }
    }
  }

  async createSourceConnection(stream, iceServers, onSendMessage) {
    this.stream = stream;
    const configuration = {iceServers};
    this.sourceConnection = new RTCPeerConnection(configuration);
    this.sourceConnection.addEventListener('icecandidate', e => 
      onSendMessage(e.candidate && e.candidate.toJSON())
    );
  }

  async getOffer() {
    const videoTracks = this.stream.getVideoTracks();
    const audioTracks = this.stream.getAudioTracks();
    if (videoTracks.length > 0) {
      console.log(`Using video source: ${videoTracks[0].label}`);
    }
    if (audioTracks.length > 0) {
      console.log(`Using audio source: ${audioTracks[0].label}`);
    }
    this.stream.getTracks().forEach(track => this.sourceConnection.addTrack(track, this.stream));

    const offerOptions = {
      offerToReceiveAudio: 1,
      offerToReceiveVideo: 1
    };
   
    const offer = await this.sourceConnection.createOffer(offerOptions);
    await this.sourceConnection.setLocalDescription(offer);
    return offer;
  }
  
  async setAnswer(answer) {
    await this.sourceConnection.setRemoteDescription(answer);
  }

  hangup() {
    this.sourceConnection && this.sourceConnection.close();
    this.stream && this.stream.getTracks().forEach( track => track.stop());
  }

  render() {
    return null;
  }
}

export function VideoReceiverPreview({shown}) {
  return (
    <video autoPlay playsInline id='remote-video-preview' 
      style={{width: 292, height: 200, objectFit: 'cover', display: !shown ? 'none' : undefined}}>  
    </video>
  )
}

class VideoReceiver extends React.Component {
  async receiveMessage(candidate) {
    if (candidate) {
      try {
        await this.receiverConnection.addIceCandidate(new RTCIceCandidate(candidate));
      } catch (e) {
        console.error('bad Receiver Candidate', candidate);
        console.log(e);
      }
    }
  }

  async createReceiverConnection(iceServers, onSendMessage) {
    const remoteVideo = document.getElementById('remote-video');
    const remotePreview = document.getElementById('remote-video-preview');

    const configuration = {iceServers};
    this.receiverConnection = new RTCPeerConnection(configuration);

    this.receiverConnection.addEventListener('icecandidate', e => 
      onSendMessage(e.candidate && e.candidate.toJSON())
    );
    this.receiverConnection.addEventListener('track', e => {
      remoteVideo.srcObject = e.streams[0];
      remotePreview.srcObject = e.streams[0];
    });

    remoteVideo.addEventListener('loadedmetadata', function() {
      console.log(`=== Video Started: ${this.videoWidth}px,  videoHeight: ${this.videoHeight}px`);
    });  
  }

  async getAnswer(offer) {
    await this.receiverConnection.setRemoteDescription(offer);
    const answer = await this.receiverConnection.createAnswer();
    await this.receiverConnection.setLocalDescription(answer);
    return answer;  
  }

  hangup() {
    this.receiverConnection && this.receiverConnection.close();
  }

  render() {
    const {style} = this.props;
    return (
      <View style={style}>
        <video autoPlay playsInline id='remote-video' 
          style={{width: '100%', height: '100%', objectFit: 'contain'}}>  
        </video>
      </View>
    )
  }
}

