import React from 'react';
import { ScrollView, StyleSheet, Text, View} from 'react-native';
import { HeaderSpaceView, BackButton, FormInput, WideButton } from '../components/basics';
import { KeyboardSafeView } from '../components/keyboardsafeview.';
import { PopupSelector } from '../components/shim';
import { submitAbuseReportAsync } from '../data/data';

// export function ReportAbuseScreen({meeting, user, navigation}) {
export class ReportAbuseScreen extends React.Component {
  state = {}

  render() {
    const {meeting, user, navigation} = this.props;
    const {authority, reason, details} = this.state;
    return (
      <KeyboardSafeView>
        <HeaderSpaceView style={{backgroundColor: 'white'}}>
          <View style={{flexDirection: 'row', alignItems: 4, padding: 4,
              borderBottomColor: '#ddd', borderBottomWidth: StyleSheet.hairlineWidth
            }}>
            <BackButton navigation={navigation} />
            <Text style={{fontSize: 20, marginLeft: 4}}>Report Abuse</Text>
          </View>
          <ScrollView>
            <View style={{zIndex: 10, flexDirection: 'row', marginHorizontal: 16, alignItems: 'center', padding: 8}}>
              <Text style={{fontSize: 20, marginRight: 4, color: '#666'}}>Report to</Text>
              <PopupSelector width={200} value={authority || 'host'} 
                  onSelect={authority => this.setState({authority})}
                  items={[
                    {label: 'Gathering Host', id:'host'}, 
                    {label: 'Talkbeat', id:'talkbeat'}
                  ]}/>
            </View>
            <View style={{zIndex: 5, flexDirection: 'row', marginHorizontal: 16, alignItems: 'center', padding: 8}}>
              <Text style={{fontSize: 20, marginRight: 4, color: '#666'}}>Report for</Text>
              <PopupSelector width={200} value={reason || 'Harassment'} 
                  onSelect={reason => this.setState({reason})}
                  items={[
                    {label: 'Harassment', id:'Harassment'}, 
                    {label: 'Impersonation', id:'Impersonation'},
                    {label: 'Illegal Activity', id:'Illegal Activity'},
                    {label: 'Obscene Content', id:'Obscene Content'},
                    {label: 'Other Abuse', id:'Other Abuse'},
                  ]}/>
              </View>
            <FormInput part='details' multiline placeholder='Details'
              style={{height: 100, padding: 8, borderColor: '#ddd', borderWidth: StyleSheet.hairlineWidth,
                  borderRadius: 8, margin: 16, fontSize: 16}}
              onChangeText = {details => this.setState({details})}
            />
            <WideButton part='submit' onPress={()=>{
                submitAbuseReportAsync({meeting, user, authority, reason, details});
                navigation.goBack();
              }}>
              Submit Abuse Report
            </WideButton>
          </ScrollView>
        </HeaderSpaceView>
      </KeyboardSafeView>
    )
  }
}

