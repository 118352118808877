import React from 'react';
import { Image, View, StyleSheet, Text, TouchableHighlight} from 'react-native';
import { getTimeNow } from './shim';
import { getUrlForImage } from '../data/fbutil';
import { FixedTouchable } from './basics';
import { Entypo, Ionicons } from '@expo/vector-icons';

export var global_showPhotoPopup = () => {};

export class PhotoPopup extends React.Component {
  state = {lastCancel: 0, photo: null, lastShow: null, showCross: false};

  componentDidMount() {
    global_showPhotoPopup = ({photo}) => {
      console.log('showPhotoPopup', photo);
      this.setState({photo, lastShow: getTimeNow()});
    }
  }

  onMouseMove() {
    const {showCross} = this.state;
    if (!showCross) {
      this.setState({showCross: true});
      setTimeout(() => this.setState({showCross: false}), 1000);
    }
  }

  render() {
    const {lastCancel, photo, lastShow, showCross} = this.state;

    if (photo && lastShow > lastCancel) {
      return (
        <FixedTouchable part='photo-background' onPress={()=>this.setState({lastCancel: getTimeNow()})}>
          <View style={{position: 'absolute', left: 0, top: 0, right: 0, bottom: 0}}>
            <View style={{position: 'absolute', opacity: 0.8, backgroundColor: 'black', left: 0, top: 0, right: 0, bottom: 0}}>
            </View>
            <Image part='photo-image' source={{uri:getUrlForImage(photo)}} 
              onMouseMove={()=>this.onMouseMove()}
              style={{resizeMode: 'contain', position: 'absolute', left: 0, top: 0, right: 0, bottom: 0}} />
            {showCross ?
              <Ionicons name='md-close' size={60} color='white' 
                style={{position: 'absolute', right: 20, top: 0, opacity: 0.5}} />
            : null}
          </View>
        </FixedTouchable>
      )
    } else {
      return null;
    }
  }
}