import React from 'react';
import { StyleSheet, Text, Platform, View, Linking, StatusBar, YellowBox, Vibration} from 'react-native';
import { getUserForId, setCurrentUser, watchVanityName } from './data/data';
import { firebaseApp } from './data/config';
import { onAuthStateChanged } from './data/fbutil';
import { SignInScreen } from './screens/Signin';
import { Meeting } from './screens/Meeting';
import { NewChat } from './screens/NewChat';
import { HomeScreen } from './screens/Home';
import { IntroScreen } from './screens/Intro';
import { AppLoading, Notifications } from 'expo';
import { FontAwesome, Entypo, Ionicons } from '@expo/vector-icons';
import * as Font from 'expo-font';
import { MembersEditScreen } from './screens/MemberList';
import { sendFinalBeacon, webInit, getUrlPath, navigateToGathering, navigateToChat, getIsMobileWeb } from './components/shim';
import { VideoCall } from './components/video';
import { NativeNavigator } from './components/navigator';
import { isWeb } from './components/basics';
import _ from 'lodash';
import { global_setNotif } from './components/notifbanner';


// function ignoreAndroidFirebaseWarning() {
  YellowBox.ignoreWarnings(['Setting a timer']);
// } 

export default class WebApp extends React.Component {
  state = {user: null, gotAuth: false, screen: null};

  async authStateChanged(fbUser) {
    if (fbUser) {
      setCurrentUser(fbUser.uid);
      this.setState({user: fbUser.uid, gotAuth: true})
    } else {
      this.setState({user: null, gotAuth: true});
    }
  }

  navigateToScreen({screen, item, item2}) {
    if (!isWeb && this.navigator) {
      if (screen == 'chat' || screen == 'gather') {
        if (item && item2) {
          return navigateToChat(this.navigator, {meeting: item, user: item2});
        } else {
          return navigateToGathering(this.navigator, {meeting: item});
        }
      }
    }
  }

  urlToNavigation(url) {
    const path = getUrlPath(url) || '';
    console.log('urlToNavigation', url, path);
    const pathParts = path.split('/').filter(x=>x);
    const item = pathParts[1];
    const item2 = pathParts[2];
    return {screen: pathParts[0] || 'root', item, item2};
  }

  navigateToUrl(url) {
    const {screen, item} = this.urlToNavigation(url);
    console.log('navigate to', screen, item);
    this.navigateToScreen({screen, item});
  }

  async navigatorStarted(navigator) {
    this.navigator = navigator;
    if (this.notification) {
      this.navigateToScreen({screen: 'home'});
    }
    const initialUrl = await Linking.getInitialURL();
    // const initialUrl = '/gather/wibble';
    // const initialUrl = '/gather/wibble/iyRRPYhwrcXf6w8h5mgzbNO5lmx2';
    Linking.addEventListener('url', ({url}) => {
      this.navigateToUrl(url);
    })
    this.navigateToUrl(initialUrl);
    Notifications.addListener(n => this.handleNotification(n));
  }

  async handleNotification(notification) {
    if (!this.navigator) {
      console.log('handle notification with broken this');
    }
    console.log('notification', notification);
    if (notification.origin == 'received' && notification.remote) {      
      const navState = this.getNavigatorState();
      if (navState && navState.user == notification.data.fromUser && navState.meeting == notification.data.meeting) {
        console.log('skipping notif - is current screen');
      } else if (notification.data.sentByMe) {
        console.log('skipping notif - sent by me');
      } else {
        global_setNotif({title: notification.data.title || notification.data.fromUserName, 
            body: notification.data.text || notification.data.body,
            meeting: notification.data.meeting
          });
        Vibration.vibrate();

      }
      // } else if (Platform.OS == 'ios') {
      //   Vibration.vibrate();
      //   console.log('received remote notif - sending locally', navState);
      //   const notificationId = Notifications.presentLocalNotificationAsync({
      //     title: notification.data.title || notification.data.fromUserName,
      //     body: notification.data.text || notification.data.body,
      //     // ios: {_displayInForeground: true},
      //     data: {...notification.data, sendByMe: true, _displayInForeground: true}
      //   })
      // }
    } else if (notification.origin == 'selected') {
      console.log('selected: ', notification);
      if (notification.data.meeting) {
        navigateToGathering(this.navigator, {meeting: notification.data.meeting});
      } else {
        navigateToHome(this.navigator);
      }
    } else {
      console.log('not remote or selected. Ignoring');
    }
  }

  getNavigatorState() {
    if (this.navigator && this.navigator.state && this.navigator.state.nav) {
      console.log('navState', this.navigator.state);
      const state = this.navigator.state;
      const topScreen = state.nav.routes[state.nav.index];
      if (topScreen.params) {
        return {screen: topScreen.routeName, user: topScreen.params.user, meeting: topScreen.params.meeting};
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  async componentDidMount(){
    // this.showNavigatorState();
    webInit();
    StatusBar.setBarStyle('dark-content');
    onAuthStateChanged(fbUser => this.authStateChanged(fbUser));
    const url = await Linking.getInitialURL();
    if (isWeb) {
      const {screen, item, item2} = this.urlToNavigation(url);
      this.setState({screen, item, item2}) 
    } else {
      this.setState({isReady: true, })
    }
    this.navigateToUrl(url);

    await this.tryLoadingFontsTwice();
    this.setState({isReady: true});
  }

  async loadAssetsAsync() {
    console.log('loadAssets');
    const fonts = [FontAwesome.font, Entypo.font, Ionicons.font];
    const pFonts = fonts.map(font => Font.loadAsync(font));
    await Promise.all(pFonts);
    console.log('loaded assets');
  }

  async tryLoadingFontsTwice() {
    try {
      await this.loadAssetsAsync();
    } catch (e) {
      console.log('first font load failed', e)
      try {
        await this.loadAssetsAsync();
      } catch (e2) {
        console.log('second font load failed - giving up', e);
        return true;
      }
    }
  }

  render() {
    const {user, gotAuth, screen, item, item2} = this.state;
    console.log('render screen:', screen, user, item, item2, gotAuth);

    if (getIsMobileWeb()) {
      return null;
    }

    if (isWeb && (!this.state.isReady || screen == null)) {
      return (
        <View style={styles.container}>
          <Text>Loading...</Text>
        </View>
      )
    } else if (!gotAuth) {
      console.log('auth');
      return (
        <View style={styles.container}>
          <Text>Authenticating...</Text>
        </View>
      )
    } else if (!user) {
      console.log('signin');
      return (
        <SignInScreen />
      )
    } else if (!isWeb) {
      return <NativeNavigator ref={r => this.navigatorStarted(r)} />
    } else if (screen == 'root' || screen == 'list') {
      console.log('home screen');
      return <HomeScreen />
    } else if (screen == 'new') {
      return <NewChat />
    } else if (screen == 'chat' || screen == 'meet' || screen == 'meeting' || screen == 'gather'){
      return (
        <Meeting meeting={item} />
      )
    } else if (screen == 'editmembers' || screen == 'members') {
      return (
        <MembersEditScreen meeting={item} />
      )
    } else if (screen == 'video') {
      return (
        <VideoCall meeting={item} user={item2} />
      )
    } else {
      return (
        <View style={styles.container}>
          <Text>Unknown screen</Text>
        </View>
      )
    }
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
